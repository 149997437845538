import Box from '@mui/material/Box';

function SkipLinks() {
  return (
    <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
      <a className="skip-link" href="#sidebar">
        Skip to sidebar
      </a>
      <a className="skip-link" href="#page-content">
        Skip to main content
      </a>
    </Box>
  );
}

export default SkipLinks;
