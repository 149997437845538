import type { GlAccountResponse } from 'shared/lib/types';

import { GL_ACCOUNT_GRID_TAG } from './companies';
import constructApi from './constructApi';

const apiEndpointFactory =
  constructApi<GlAccountResponse>('glaccounts').withTags('GL_ACCOUNTS');
export const api = apiEndpointFactory.inject(() => ({
  getGlAccountsByCompany: apiEndpointFactory.getBy('company'),
  getGlAccountsWithFilters: apiEndpointFactory.getWithFilters(),
  deleteGlAccount: apiEndpointFactory.delete([GL_ACCOUNT_GRID_TAG]),
  createGlAccount: apiEndpointFactory.create([GL_ACCOUNT_GRID_TAG]),
  updateGlAccount: apiEndpointFactory.update([GL_ACCOUNT_GRID_TAG]),
}));

export const {
  useCreateGlAccountMutation,
  useGetGlAccountsByCompanyQuery,
  useGetGlAccountsWithFiltersQuery,
  useUpdateGlAccountMutation,
  useDeleteGlAccountMutation,
} = api;
