import type { MouseEvent, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as routes from 'routes';
import {
  selectSidebarExpandedState,
  updateExpandedState,
} from 'shared/state/slices/sidebarSlice';

import SidebarItem, { type SidebarItemProps } from './SidebarItem';
import SidebarSubNavigation from './SidebarSubNavigation';

type SubNav = {
  title: string;
  link?: string;
  disabled?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
};

const mapSectionTitleToRoute: Record<string, string> = {
  'Clinical Expenses': routes.getClinicalExpenses(),
  'Trial Activity': routes.getTrialActivity(),
};

export default function SidebarSection(props: SidebarSectionItem) {
  const { title, subNav, collapsed = false, ...item } = props;

  const location = useLocation();

  const dispatch = useDispatch();
  const expandedState = useSelector(selectSidebarExpandedState);
  const expanded = expandedState[title] ?? true;

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleFlags = useCallback(
    (newExpanded: boolean, newAnchorEl: HTMLDivElement | null) => {
      setAnchorEl(newAnchorEl);
      dispatch(updateExpandedState({ key: title, expanded: newExpanded }));
    },
    [dispatch, title],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: This is intentionally only when the route changes so we can auto-expand
  useEffect(() => {
    // If the current page is a child of this section, expand it
    const section = mapSectionTitleToRoute[title];
    if (!collapsed && section && location.pathname.startsWith(section)) {
      dispatch(updateExpandedState({ key: title, expanded: true }));
    }
  }, [title, location.pathname]);

  // on page load, start the section expanded if one of the child pages are open
  // biome-ignore lint/correctness/useExhaustiveDependencies: This is intentionally only when subNav changes (so we can wait for contracts)
  useEffect(() => {
    if (!collapsed && subNav?.some((link) => link.link === location.pathname)) {
      handleFlags(true, null);
    }
  }, [subNav?.length]);

  // when menu is collapsed, collapse all sections that were
  // open so many menus don't automatically open up
  useEffect(() => {
    if (collapsed) {
      handleFlags(false, null);
    }
  }, [collapsed, handleFlags]);

  const onExpand = (event: MouseEvent<HTMLDivElement>) => {
    if (!subNav) {
      return;
    }

    const newExpanded = !expanded;
    handleFlags(newExpanded, newExpanded ? event.currentTarget : null);
  };

  return (
    <>
      <SidebarItem
        collapsed={collapsed}
        expanded={expanded}
        title={title}
        expandable
        onClick={onExpand}
        {...item}
      />
      <SidebarSubNavigation
        anchorEl={anchorEl}
        collapsed={collapsed}
        expanded={expanded}
        subNav={subNav}
        title={title}
        onClose={() => handleFlags(false, null)}
        {...item}
      />
    </>
  );
}

export type SidebarSectionItem = SidebarItemProps & {
  title: string;
  icon: ReactNode;
  subNav?: SubNav[];
  collapsed?: boolean;
};
