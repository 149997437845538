import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import Button from 'shared/ui/button/Button';

import HasPermission from 'shared/lib/permissions/HasPermission';

import TrialUploadsList from './TrialUploadsList';

function TrialUploadsContent() {
  return (
    <Box width="800px">
      <Box display="flex">
        <Typography flex={1} variant="h6">
          Reports
        </Typography>
        <HasPermission permissions={['canEditTrialInfo']}>
          <Button
            size="small"
            startIcon={<CalendarMonthOutlinedIcon />}
            testId="manage_visits_and_procedures"
            variant="outlined"
          >
            Manage Visits and Procedures
          </Button>
          <Button
            size="small"
            startIcon={<SettingsOutlinedIcon />}
            sx={{ ml: 2 }}
            testId="manage_report_types"
            variant="outlined"
          >
            Manage report types
          </Button>
        </HasPermission>
      </Box>
      <TrialUploadsList />
    </Box>
  );
}

export default TrialUploadsContent;
