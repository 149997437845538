import { useEffect, useMemo, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectPeriodVersion } from 'accruals/state/slices/periodVersionSlice';
import * as routes from 'routes';
import { getJSONFromFile } from 'shared/helpers/helpers';
import type {
  PatientCohortResponse,
  PatientJourneyMenuItemType,
  PeriodBlob,
  TraceId,
} from 'shared/lib/types';
import { PeriodMenuItemType } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';
import { useGetPatientCohortsByTrialQuery } from 'shared/api/rtkq/patientcohorts';
import { useGetPeriodMenuItemQuery } from 'shared/api/rtkq/periodmenuitems';

type ReturnValue = {
  periodSpecificPatientCohortTraceId?: TraceId;
  periodSpecificTabItems?: PatientJourneyMenuItemType[];
};

function useCohortAndPeriodWithVersions(): ReturnValue {
  const { patientCohortId } = useParams();
  const trial = useSelector(selectTrial);
  const currentPeriodVersion = useSelector(selectPeriodVersion);
  const [tabItems, setTabItems] = useState<
    PatientJourneyMenuItemType[] | undefined
  >();
  const isClosedPeriodVersion = useIsClosedPeriodVersion();

  // for open periods
  const { currentData: patientCohorts } = useGetPatientCohortsByTrialQuery(
    isClosedPeriodVersion ? skipToken : trial.trace_id,
  );
  const patientCohortTraceId = patientCohortId?.startsWith('pmi')
    ? undefined
    : patientCohortId;

  // for closed periods
  const { currentData: periodMenuItems } = useGetPeriodMenuItemQuery(
    isClosedPeriodVersion
      ? {
          saved_object_type: PeriodMenuItemType.JOURNEY_COHORT_TABS,
          period_version: currentPeriodVersion.trace_id,
        }
      : skipToken,
  );
  const patientCohortPeriodMenuItemTraceId = patientCohortId?.startsWith('pmi')
    ? patientCohortId
    : undefined;

  useEffect(() => {
    // setTabItems to cohort data for open periods, periodmenuitems for closed periods
    void (async () => {
      let items = [] as PatientJourneyMenuItemType[] | undefined;

      if (!isClosedPeriodVersion) {
        items = patientCohorts?.map((cohort: PatientCohortResponse) => ({
          title: cohort.name,
          link: routes.getPatientJourneyByCohortNavigation(
            'patient-journey',
            cohort.trace_id,
          ),
          key: cohort.trace_id,
          sort_order: cohort.order_index,
        }));
      } else if (periodMenuItems?.length) {
        const promises = periodMenuItems.map(async (periodMenuItem) => {
          let title = '';
          if (periodMenuItem.blob) {
            const blob = await getJSONFromFile<PeriodBlob>(periodMenuItem.blob);
            title = blob?.title ?? '';
          }

          return {
            title,
            link: routes.getPatientJourneyByCohortNavigation(
              'patient-journey',
              periodMenuItem.trace_id,
            ),
            sort_order: periodMenuItem.sort_order,
            key: periodMenuItem.trace_id,
          };
        });
        items = await Promise.all(promises);
      }

      // In case of closed period, sortedItems would be pmis and in open periods they'd be cohorts
      const sortedItems = [...(items ?? [])].sort(
        (obj1, obj2) => (obj1.sort_order ?? 0) - (obj2.sort_order ?? 0),
      );
      setTabItems(sortedItems);
    })();
  }, [isClosedPeriodVersion, patientCohorts, periodMenuItems]);

  return useMemo(
    () => ({
      periodSpecificPatientCohortTraceId: isClosedPeriodVersion
        ? patientCohortPeriodMenuItemTraceId
        : patientCohortTraceId,
      periodSpecificTabItems: tabItems,
    }),
    [
      tabItems,
      isClosedPeriodVersion,
      patientCohortPeriodMenuItemTraceId,
      patientCohortTraceId,
    ],
  );
}

export default useCohortAndPeriodWithVersions;
