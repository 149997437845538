import type { UserPermissions } from 'shared/lib/types';

import useHasPermission from './useHasPermission';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  permissions: Array<keyof UserPermissions>;
  separator?: 'AND' | 'OR';
};

export default function HasPermission(props: Props) {
  const { permissions, children, fallback, separator } = props;
  const hasPermission = useHasPermission(permissions, separator);
  return <>{hasPermission ? children : fallback}</>;
}
