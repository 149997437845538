import { Fragment, type ReactNode, useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';

import Alert from 'shared/ui/alert/Alert';

import type {
  CompanyResponse,
  FileUploadType,
  PeriodVersionResponse,
  UnmappedFileEntityType,
  UnmappedFileResponse,
  VendorResponse,
} from 'shared/lib/types';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useCreateUnmappedFileMutation } from 'shared/api/rtkq/unmappedfiles';

import CsvUploader from './CsvUploader';

type Props = {
  ExtraFrame?: (frameProps: { children: ReactNode }) => ReactNode;
  accept?: string;
  company?: CompanyResponse | null;
  description?: string;
  entityClass?: UnmappedFileEntityType;
  fileTypeWithGrammaticalArticle: string;
  fileUploadType?: FileUploadType;
  header: string;
  periodVersion?: PeriodVersionResponse | null;
  sampleFileLink?: string;
  sampleFileName?: string;
  setSnapshotId?: (snapshotId?: string) => void;
  titleOverride?: string;
  vendor?: VendorResponse;
  onSave: (filesUploaded: UnmappedFileResponse[]) => void;
};

function UploadUnmappedCsvStep(props: Props) {
  const {
    header,
    description,
    fileTypeWithGrammaticalArticle: _fileTypeWithGrammaticalArticle,
    onSave,
    sampleFileLink,
    accept = '.csv',
    sampleFileName = 'file',
    titleOverride = 'Upload File', // TODO: Remove default override and confirm with Product the desired titles
    setSnapshotId,
    entityClass,
    fileUploadType,
    periodVersion,
    company,
    vendor,
    ExtraFrame = Fragment,
  } = props;
  const [files, setFiles] = useState<File[]>([]);

  const [filesUploaded, setFilesUploaded] = useState<UnmappedFileResponse[]>(
    [],
  );
  const [showError, setShowError] = useState(false);
  const [
    uploadFileRequest,
    { data: unmappedFileData, isLoading: uploading, error: uploadError },
  ] = useCreateUnmappedFileMutation();

  // if there is an upload error, look for the data being returned from it instead
  const fileUploadData = uploadError
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any -- this is the old way to do this, where the errors return data too
      (uploadError as any).data
    : unmappedFileData;

  const columnCount = fileUploadData?.columns_count ?? 0;
  const isUploadFailed =
    filesUploaded.some((obj) => obj.error) || !!uploadError;

  useEffect(() => {
    // if the user comes to this step in any way (either as a first time opening the wizard or by going back to this step),
    // ...we should clear the snapshotId to let user create a new snapshot
    setSnapshotId?.(undefined);
  }, []);

  useEffect(() => {
    setShowError(false);
    setFilesUploaded([]);
  }, [files]);

  function onNext() {
    if (filesUploaded.length > 0) {
      onSave(filesUploaded);
    }
  }

  async function onUploadAll() {
    const newFilesUploaded = await Promise.all(
      files.map(async (file) => {
        const data = new FormData();
        data.append('file', file);
        if (entityClass != null) {
          data.append('entity_class', fileUploadType ?? entityClass);
        }
        if (periodVersion != null) {
          data.append('period_version', periodVersion.trace_id);
        } else if (company != null) {
          data.append('company', company.trace_id);
        }
        if (vendor != null) {
          data.append('vendor', vendor.trace_id);
        }

        return uploadFileRequest(data).unwrap();
      }),
    );
    const result = newFilesUploaded as UnmappedFileResponse[];
    setFilesUploaded(result);

    if (result.length < files.length) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }

  return (
    <WizardStep
      description={description}
      header={header}
      readyToContinue={filesUploaded.length > 0}
      titleOverride={titleOverride}
      disableNextButton={
        (filesUploaded.length === 0 && !files.length) || showError
      }
      nextButtonTextOverride={
        filesUploaded.length === 0 || showError ? 'Upload' : 'Next'
      }
      onNext={filesUploaded.length > 0 ? onNext : onUploadAll}
    >
      <ExtraFrame>
        <CsvUploader
          accept={accept}
          columnCount={columnCount}
          corruptedCells={fileUploadData?.corrupted_cells}
          fileUploadData={fileUploadData}
          files={files}
          filesUploaded={filesUploaded}
          isUploadFailed={isUploadFailed}
          isUploading={uploading}
          sampleFileLink={sampleFileLink}
          sampleFileName={sampleFileName}
          setFiles={setFiles}
        />
      </ExtraFrame>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showError}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error">Error uploading files(s)</Alert>
      </Snackbar>
    </WizardStep>
  );
}

export default UploadUnmappedCsvStep;
