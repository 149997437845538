import type { SiteFileResponse } from 'shared/lib/types';

import constructApi from './constructApi';
import { TRIAL_FILES } from './trials';

const PATH = 'sitefiles';

const apiEndpointFactory =
  constructApi<SiteFileResponse>(PATH).withTags('SITE_FILES');
const api = apiEndpointFactory.inject(() => ({
  getSiteFiles: apiEndpointFactory.getBy('site'),
  createSiteFile: apiEndpointFactory.create<FormData>(),
  deleteSiteFile: apiEndpointFactory.delete([TRIAL_FILES]),
}));

export const {
  useGetSiteFilesQuery,
  useCreateSiteFileMutation,
  useDeleteSiteFileMutation,
} = api;
