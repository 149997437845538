import type { ICellRendererParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import Checkbox from 'shared/ui/checkbox/Checkbox';

function AgGridUserManagementCheckboxCellRenderer(
  params: ICellRendererParams & {
    fieldToCheck?: string;
    tooltip?: string;
  },
) {
  const { fieldToCheck, tooltip, data, value } = params;

  let child = <Checkbox checked={value} disableRipple />;

  if (tooltip != null) {
    const trialsToAppend: string | null =
      fieldToCheck != null &&
      data?.[fieldToCheck] &&
      Array.isArray(data?.[fieldToCheck])
        ? data?.[fieldToCheck].join(', ')
        : null;

    const hasTrialsToAppend =
      trialsToAppend != null && trialsToAppend.length > 0;

    // we only want to show a tooltip when the field is disabled (aka, they are assigned as a reviewer or preparer)
    if (hasTrialsToAppend) {
      const finalTooltip = `${tooltip}${trialsToAppend}`;

      // span is required as you cannot add a Tooltip to a disabled element
      child = (
        <Tooltip title={finalTooltip}>
          <span>
            <Checkbox
              checked={value}
              disabled={hasTrialsToAppend}
              disableRipple
            />
          </span>
        </Tooltip>
      );
    }
  }
  const status: string | null = data?.status;

  if (status === 'Inactive') {
    child = (
      <Tooltip title="User is inactive">
        <span>
          <Checkbox checked={value} disableRipple disabled />
        </span>
      </Tooltip>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 33,
      }}
    >
      {child}
    </Box>
  );
}

export default AgGridUserManagementCheckboxCellRenderer;
