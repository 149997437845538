import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/EditOutlined';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { skipToken } from '@reduxjs/toolkit/query';

import Button from 'shared/ui/button/Button';

import useContractAndPeriodWithVersions from 'accruals/pages/clinical-expenses/shared/hooks/useContractAndPeriodWithVersions';
import DirectFeeUploadWizard from 'accruals/wizards/csv-upload-wizards/direct-fee-upload-wizard/DirectFeeUploadWizard';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import type { CroCostCategory } from 'shared/lib/types';
import { BlaResolutionType } from 'shared/lib/types';

import {
  useGetContractReconGridQuery,
  useUpdateContractReconBottomLineAdjustmentResolutionMutation,
} from 'shared/api/rtkq/contracts';
import { useGetDirectFeesSnapshotsByContractQuery } from 'shared/api/rtkq/vendorrepactivitysnapshots';

import CroReconLineMatchingDialog from './CroReconLineMatchingDialog';

type Props = {
  costCategory: CroCostCategory;
};

function CroReconGridHeader(props: Props): ReactElement {
  const { costCategory } = props;

  const [updateBlaResolution] =
    useUpdateContractReconBottomLineAdjustmentResolutionMutation();
  const { contractVersionTraceId, periodTraceId } =
    useContractAndPeriodWithVersions();

  const { currentData: directFeesSnapshots } =
    useGetDirectFeesSnapshotsByContractQuery(
      costCategory === 'DIRECT_FEES' && contractVersionTraceId
        ? { contract: contractVersionTraceId, cost_category: 'DIRECT_FEES' }
        : skipToken,
    );

  const getResolutionType =
    contractVersionTraceId !== undefined &&
    periodTraceId !== undefined &&
    costCategory === 'DIRECT_FEES';
  const { currentData: reconData } = useGetContractReconGridQuery(
    getResolutionType
      ? {
          trace_id: contractVersionTraceId,
          secondParameter: periodTraceId,
          thirdParameter: costCategory,
        }
      : skipToken,
  );

  const [showUploadWizard, setShowUploadWizard] = useState<boolean>(false);
  const [showLineMatchingDialog, setShowLineMatchingDialog] =
    useState<boolean>(false);
  const [useAIP, setUseAIP] = useState<boolean>(false);
  const isNewCroReported = !directFeesSnapshots?.length;

  useEffect(() => {
    const resolutionType = reconData?.bla_resolution_type;
    setUseAIP(resolutionType === BlaResolutionType.AIP);
  }, [reconData]);

  const toggleAIP = () => {
    // this is impossible when this is called as the toggle doesn't render if this happens but TS has no way to know that,
    // so to keep the below logic easier to read, we just ensure they are defined first
    if (contractVersionTraceId === undefined || periodTraceId === undefined) {
      return;
    }

    void updateBlaResolution({
      object: {
        trace_id: contractVersionTraceId,
        bla_resolution_type: useAIP
          ? BlaResolutionType.CURRENT
          : BlaResolutionType.AIP,
      },
      secondParameter: periodTraceId,
    });
    setUseAIP(!useAIP);
  };

  return (
    <HasPermission permissions={['canEditTrialInfo']}>
      <Box
        sx={{
          flexGrow: 1,
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Button
            disabled={contractVersionTraceId === undefined}
            startIcon={isNewCroReported ? <UploadIcon /> : <EditIcon />}
            testId={`${isNewCroReported ? 'new' : 'edit'}_cro_reported`}
            variant="outlined"
            onClick={() => setShowUploadWizard(true)}
          >
            {`${isNewCroReported ? 'New' : 'Edit'} CRO reported`}
          </Button>
          <Button
            startIcon={<SwapHoriz />}
            sx={{ ml: 2 }}
            testId="line_match"
            variant="outlined"
            onClick={() => setShowLineMatchingDialog(true)}
          >
            Line Match
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {costCategory === 'DIRECT_FEES' &&
            periodTraceId !== undefined &&
            contractVersionTraceId !== undefined && (
              <>
                <FormGroup>
                  <FormControlLabel
                    label="Use AIP BLA"
                    control={
                      <Switch
                        checked={useAIP}
                        disabled={reconData === undefined}
                        onChange={toggleAIP}
                      />
                    }
                  />
                </FormGroup>
                <Divider orientation="vertical" sx={{ mr: 2 }} flexItem />
              </>
            )}
        </Box>
      </Box>
      {showUploadWizard && (
        <DirectFeeUploadWizard
          costCategory={costCategory}
          onClose={() => setShowUploadWizard(false)}
        />
      )}
      {showLineMatchingDialog && (
        <CroReconLineMatchingDialog
          costCategory={costCategory}
          onClose={() => setShowLineMatchingDialog(false)}
        />
      )}
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(CroReconGridHeader);
