import { Fragment, type ReactNode, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import theme from 'theme';

import AuthContainer from '../auth-container/AuthContainer';
import ThemeContext from '../theme-context/ThemeContext';
import EmergencyAlerts from './EmergencyAlerts';
import PreloadData from './PreloadData';

type Props = {
  children: ReactNode;
  dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED?: boolean;
};

function AppContainer(props: Props) {
  const { children, dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } =
    props;

  const [themeMode, setThemeMode] = useState<'dark' | 'light'>(
    (localStorage.getItem('theme') as 'dark' | 'light' | null) ?? 'light',
  );

  const ConditionalAuthContainerWrapper = useMemo(
    () =>
      dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
        ? Fragment
        : AuthContainer,
    [dangerouslyIgnoreAuth_DO_NOT_USE_OR_YOU_WILL_BE_FIRED],
  );

  const finalTheme = useMemo(() => theme(themeMode), [themeMode]);

  return (
    <ThemeContext.Provider value={{ themeMode, setThemeMode }}>
      <ThemeProvider theme={finalTheme}>
        <CssBaseline />
        <ConditionalAuthContainerWrapper>
          <PreloadData />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              position: 'relative',
            }}
          >
            <EmergencyAlerts />
            {children}
          </Box>
          {/* ToastContainer is INTENTIONALLY inside AuthContainer as AuthContainer relies on
            error statuses to know what content should be shown, and we don't want toasts if things fail */}
          <ToastContainer theme={themeMode} closeOnClick />
        </ConditionalAuthContainerWrapper>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default AppContainer;
