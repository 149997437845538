import type { ReactElement } from 'react';

import Button, { type ButtonProps } from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';

import HasPermission from '../permissions/HasPermission';
import type { UserPermissions } from '../types';

type Props = ButtonProps & {
  permissions: Array<keyof UserPermissions>;
  separator?: 'AND' | 'OR';
};

function PeriodAwareButton(props: Props): ReactElement {
  const { permissions, separator } = props;

  return (
    <HasPermission permissions={permissions} separator={separator}>
      <Button {...props} />
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(PeriodAwareButton);
