import { useState } from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { formatDistance } from 'date-fns/formatDistance';

import Chip from 'shared/ui/chip/Chip';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type { CommentResponse } from 'shared/lib/types';

import { useDeleteCommentMutation } from 'shared/api/rtkq/comments';

import KabobMenu from '../kabob-menu/KabobMenu';
import Modal from '../modal/Modal';

type Props = Pick<
  CommentResponse,
  'created_at' | 'files' | 'text' | 'trace_id' | 'user'
>;

function basename(path: string, sep: string) {
  return path.substr(path.lastIndexOf(sep) + 1);
}

function CommentListItem(props: Props) {
  const { user, text, files, created_at, trace_id } = props;
  const canDeleteComments = useHasPermission(['canDeleteCompanyLevelInfo']);
  const isSoftDeleteEnabled = useFeatureFlag('soft_delete');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteComment, { isLoading: isDeletingComment }] =
    useDeleteCommentMutation();

  const handleCommentDelete = async () => {
    await deleteComment(trace_id);
    setIsModalOpen(false);
  };

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ width: 30, height: 30 }}>
            {user?.first_name?.[0] ?? '?'}
          </Avatar>
          <Typography
            ml={1}
            variant="subtitle2"
          >{`${user?.first_name} ${user?.last_name}`}</Typography>
          {!!created_at && (
            <Typography color="text.secondary" ml={0.5} mt={-0.8}>
              {formatDistance(new Date(), new Date(created_at))}
            </Typography>
          )}
        </Box>
        {isSoftDeleteEnabled && canDeleteComments && (
          <KabobMenu
            options={[
              {
                label: 'Delete',
                onClick: () => setIsModalOpen(true),
              },
            ]}
          />
        )}
      </Stack>
      <Typography mt={2}>{text}</Typography>
      <Stack
        columnGap={2}
        flexDirection="row"
        flexWrap="wrap"
        mt={1}
        rowGap={1}
      >
        {files?.map((file) => (
          <Chip
            key={file.name}
            icon={<AttachFileIcon />}
            label={basename(file.name, '/')}
            size="small"
          />
        ))}
      </Stack>
      {isModalOpen && (
        <Modal
          handleClose={() => setIsModalOpen(false)}
          title="Are you sure you want to delete this comment?"
          ButtonProps={{
            label: 'Delete Comment',
            testId: 'DeleteCommentModal',
            onClick: () => void handleCommentDelete(),
            sx: { backgroundColor: 'error.main' },
            loading: isDeletingComment,
          }}
          isOpen
        >
          <Typography>
            Are you sure you want to delete this comment? It will be permanently
            deleted from the period.
          </Typography>
        </Modal>
      )}
    </Box>
  );
}
export default CommentListItem;
