import useIsReadOnlyPermission from 'shared/lib/permissions/useIsReadOnlyPermission';

import type { CondorGridOptions } from '../types';

export default function useGridOptions<T>(
  gridOptionsHook: (...rest: any[]) => CondorGridOptions<T> | undefined,
  params?: unknown[],
): CondorGridOptions<T> | undefined {
  const isReadOnlyPermission = useIsReadOnlyPermission();
  return gridOptionsHook.apply(null, [
    !isReadOnlyPermission,
    ...(params ?? []),
  ]);
}
