import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';
import ContractContainerRecordContents from './ContractContainerRecordContents';
import ContractVersionRecordContents from './ContractVersionRecordContents';
import EditContractContainer from './EditContractContainer';
import EditContractVersion from './EditContractVersion';

function ContractRecordSection() {
  const { contractVersionPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();

  return (
    <DashboardCard title="Contract Record">
      <Divider />
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography color="text.secondary" mt={2} variant="body1">
          Contract Info
        </Typography>
        <EditContractContainer />
      </Stack>
      <ContractContainerRecordContents
        parentMenuItem={contractVersionPeriodMenuItemTraceId}
      />
      <Divider sx={{ my: 1 }} />
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography color="text.secondary" mt={2} variant="body1">
          Current Contract
        </Typography>
        <EditContractVersion />
      </Stack>
      <ContractVersionRecordContents
        parentMenuItem={contractVersionPeriodMenuItemTraceId}
      />
    </DashboardCard>
  );
}

export default ContractRecordSection;
