import { useState } from 'react';

import Typography from '@mui/material/Typography';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';

import type {
  ContractContainerResponse,
  VersionHistoryRow,
} from 'shared/lib/types';

import { useDeleteContractMutation } from 'shared/api/rtkq/contracts';

type Props = {
  contractContainer?: ContractContainerResponse;
  data: VersionHistoryRow;
};

function CroContractVersionMenu(props: Props) {
  const { data, contractContainer } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteContractVersion, { isLoading: isDeletingContractVersion }] =
    useDeleteContractMutation();

  const handleContractDelete = async () => {
    const traceId = data.contract_version_trace_id;
    if (traceId) {
      await deleteContractVersion(traceId);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <KabobMenu
        size="small"
        options={[
          {
            label: 'Delete contract',
            onClick: () => setIsModalOpen(true),
          },
        ]}
      />
      <Modal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={`Are you sure you want to delete ${data.version_name?.length ? `"${data.version_name}"` : `this ${data.version}`}${contractContainer ? ` for ${contractContainer.vendor_name}` : ''}?`}
        ButtonProps={{
          label: 'Delete Contract Version',
          testId: 'DeleteCroContractVersionModal',
          onClick: () => void handleContractDelete(),
          sx: { backgroundColor: 'error.main' },
          loading: isDeletingContractVersion,
        }}
      >
        <Typography>
          This will delete the contract record, budget, and files for this
          version. If you are deleting the current contract or
          amendment-in-progress, this will delete any expenses that were being
          used in the trial&apos;s accrual and forecasting calculations. This
          action cannot be undone.
        </Typography>
      </Modal>
    </>
  );
}

export default CroContractVersionMenu;
