import { useState } from 'react';

import Typography from '@mui/material/Typography';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';

import type {
  ContractContainerResponse,
  VersionHistoryRow,
} from 'shared/lib/types';

import { useDeleteContractMutation } from 'shared/api/rtkq/contracts';

type Props = {
  contractContainers?: ContractContainerResponse[];
  data: VersionHistoryRow;
};

function OccContractVersionMenu(props: Props) {
  const { data, contractContainers } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteContractVersion, { isLoading: isDeletingContractVersion }] =
    useDeleteContractMutation();

  const handleContractVersionDelete = async () => {
    const traceId = data.contract_version_trace_id;
    if (traceId) {
      await deleteContractVersion(traceId);
    }
  };

  const contractContainer = contractContainers?.find(
    ({ trace_id }) => trace_id === data.contract_container_trace_id,
  );

  return (
    <>
      <KabobMenu
        size="small"
        options={[
          {
            label: 'Delete version',
            onClick: () => setIsModalOpen(true),
          },
        ]}
      />
      <Modal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={`Are you sure you want to delete ${data.version_name?.length ? `"${data.version_name}"` : `this ${data.version}`}${contractContainer ? ` for ${contractContainer.vendor_name}` : ''}?`}
        ButtonProps={{
          label: 'Delete Contract Version',
          testId: 'DeleteOccContractVersionModal',
          onClick: () => void handleContractVersionDelete(),
          sx: { backgroundColor: 'error.main' },
          loading: isDeletingContractVersion,
        }}
      >
        <Typography>
          This will delete the contract record, budget, and files for this
          version. If you are deleting the current contract, this will delete
          any expenses that were being used in the trial&apos;s accrual and
          forecasting calculations. This action cannot be undone.
        </Typography>
      </Modal>
    </>
  );
}

export default OccContractVersionMenu;
