import { type ReactElement, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import { selectCompany } from 'shared/state/slices/companySlice';

import AddEditGlAccountDialog from './AddEditGlAccountDialog';

function GlAccountsHeader(): ReactElement {
  const currentCompany = useSelector(selectCompany);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <HasPermission permissions={['canEditCompanyLevelInfo']}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mb: 2,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <Button
          startIcon={<AddIcon />}
          testId="add_gl"
          variant="contained"
          onClick={() => setIsOpenDialog(true)}
        >
          Add G/L Account
        </Button>
        {isOpenDialog && (
          <AddEditGlAccountDialog
            companyTraceId={currentCompany.trace_id}
            onClose={() => setIsOpenDialog(false)}
          />
        )}
      </Box>
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(GlAccountsHeader);
