import type {
  AssumptionGroupRequestDisplay,
  RegionListItemType,
  RegionResponse,
} from 'shared/lib/types';
import { CRUDAction, DataType, DateAssumptionType } from 'shared/lib/types';

import EmptyParameterRow from './EmptyParameterRow';
import { getAssumptionGroupLabel, getOverall } from './helpers';
import ParameterRow from './ParameterRow';

type Props = {
  assumptionGroup: AssumptionGroupRequestDisplay;
  readOnly: boolean;
  regionMap: Record<string, RegionResponse>;
  regions: RegionListItemType[];
  setCustomInheritedContract: (region: string) => void;
  onChange: (assumptionGroup: AssumptionGroupRequestDisplay) => void;
};

function AssumptionGroup(props: Props) {
  const {
    regions,
    assumptionGroup,
    readOnly,
    onChange,
    setCustomInheritedContract,
    regionMap,
  } = props;
  const {
    data_type,
    name,
    overallValue,
    showOverall,
    editable,
    required,
    showEmptyRowAfter,
    contract_params,
  } = assumptionGroup;

  const rows = [];
  if (assumptionGroup.showByRegion) {
    for (const regionListItem of regions) {
      const parameter = contract_params.find(
        (param) =>
          param.region != null &&
          (param.region === regionListItem.trace_id ||
            (param.region in regionMap
              ? regionMap[param.region].name
              : param.region) === regionListItem.name),
      );

      rows.push(
        <ParameterRow
          key={regionListItem.name}
          dataType={data_type}
          editable={editable}
          label={`${getAssumptionGroupLabel(name)} ${regionListItem.name}`}
          readOnly={readOnly}
          required={required}
          value={parameter?.value}
          onChange={(value: string | undefined) => {
            const unchangedParams = contract_params.filter(
              (param) =>
                param.region == null ||
                (param.region !== regionListItem.trace_id &&
                  (param.region in regionMap
                    ? regionMap[param.region].name
                    : param.region) !== regionListItem.name),
            );

            const newAssumptionGroup = {
              ...assumptionGroup,
              contract_params: [
                ...unchangedParams,
                {
                  ...parameter,
                  value,
                  region: regionListItem.trace_id,
                  action:
                    parameter === undefined
                      ? CRUDAction.CREATE
                      : CRUDAction.UPDATE,
                },
              ],
            };

            onChange({
              ...newAssumptionGroup,
              overallValue: getOverall(newAssumptionGroup),
            });

            setCustomInheritedContract(regionListItem.name);
          }}
        />,
      );
    }
  } else if (data_type === DataType.DATE) {
    const startParameter = contract_params.find(
      (param) =>
        param.date_assumption_type === DateAssumptionType.START ||
        param.date_param_type === DateAssumptionType.START,
    );
    const endParameter = contract_params.find(
      (param) =>
        param.date_assumption_type === DateAssumptionType.END ||
        param.date_param_type === DateAssumptionType.END,
    );

    rows.push(
      <ParameterRow
        key={`${assumptionGroup.name}_start`}
        dataType={data_type}
        editable={editable}
        label={`${getAssumptionGroupLabel(name)} start`}
        readOnly={readOnly}
        required={required}
        value={startParameter?.value}
        onChange={(value: string | undefined) => {
          const unchangedParams = contract_params.filter(
            (param) => param.date_assumption_type !== DateAssumptionType.START,
          );

          const newAssumptionGroup = {
            ...assumptionGroup,
            contract_params: [
              ...unchangedParams,
              {
                ...startParameter,
                value,
                date_assumption_type: DateAssumptionType.START,
                action:
                  startParameter === undefined
                    ? CRUDAction.CREATE
                    : CRUDAction.UPDATE,
              },
            ],
          };

          onChange({
            ...newAssumptionGroup,
            overallValue: getOverall(newAssumptionGroup),
          });

          setCustomInheritedContract(''); // No region name needed since it's always the Timeline section
        }}
      />,
    );
    rows.push(
      <ParameterRow
        key={`${assumptionGroup.name}_end`}
        dataType={data_type}
        editable={editable}
        label={`${getAssumptionGroupLabel(name)} end`}
        readOnly={readOnly}
        required={required}
        value={endParameter?.value}
        onChange={(value: string | undefined) => {
          const unchangedParams = contract_params.filter(
            (param) => param.date_assumption_type !== DateAssumptionType.END,
          );

          const newAssumptionGroup = {
            ...assumptionGroup,
            contract_params: [
              ...unchangedParams,
              {
                ...startParameter,
                value,
                date_assumption_type: DateAssumptionType.END,
                action:
                  startParameter === undefined
                    ? CRUDAction.CREATE
                    : CRUDAction.UPDATE,
              },
            ],
          };

          onChange({
            ...newAssumptionGroup,
            overallValue: getOverall(newAssumptionGroup),
          });

          setCustomInheritedContract(''); // No region name needed since it's always the Timeline section
        }}
      />,
    );
  } else {
    // this should not happen, but as we can't enforce it in the database,
    // only ever play with the first one in the list
    const parameter = contract_params.length ? contract_params[0] : undefined;

    rows.push(
      <ParameterRow
        key={assumptionGroup.name}
        dataType={data_type}
        editable={editable}
        label={getAssumptionGroupLabel(name)}
        readOnly={readOnly}
        required={required}
        value={parameter?.value}
        onChange={(value: string | undefined) => {
          const newAssumptionGroup = {
            ...assumptionGroup,
            contract_params: [
              {
                ...parameter,
                value,
                region: null, // anything not by region, set region to null NOT undefined (as this will match what Django does)
                action:
                  parameter === undefined
                    ? CRUDAction.CREATE
                    : CRUDAction.UPDATE,
              },
            ],
          };

          onChange({
            ...newAssumptionGroup,
            overallValue: getOverall(newAssumptionGroup),
          });
        }}
      />,
    );
  }

  if (showOverall) {
    const label = getAssumptionGroupLabel(name);
    const overallUnits = data_type === DataType.DATE ? 'months' : 'global';
    const overallLabel = `${label} ${overallUnits} total`;
    rows.push(
      <ParameterRow
        key={`${assumptionGroup.name}_overall`}
        dataType={data_type}
        editable={editable}
        label={overallLabel}
        readOnly={readOnly}
        value={overallValue}
        isOverall
      />,
    );
  }

  if (showEmptyRowAfter) {
    rows.push(<EmptyParameterRow key={`${assumptionGroup.name}_empty`} />);
  }

  return rows;
}

export default AssumptionGroup;
