import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import NavigationTabs from 'shared/components/tabs/NavigationTabs';
import Button from 'shared/ui/button/Button';

import {
  selectForecast,
  selectForecastParameterDrawerOpen,
  useChangeForecastParameterDrawerOpen,
} from 'forecasting/state/slices/forecastSlice';
import {
  getForecastAssumptionsTab,
  getForecastDetailTab,
  getForecastSummaryTab,
} from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

function ForecastingTabs() {
  const changeDrawerOpen = useChangeForecastParameterDrawerOpen();
  const forecast = useSelector(selectForecast);
  const isOpen = useSelector(selectForecastParameterDrawerOpen);
  const isNewParameterFlow = useFeatureFlag('forecast_parameter_drawer');

  const forecastingTabs = [
    { key: getForecastSummaryTab(forecast.trace_id), label: 'Summary' },
    { key: getForecastDetailTab(forecast.trace_id), label: 'Detail' },
    { key: getForecastAssumptionsTab(forecast.trace_id), label: 'Assumptions' },
  ];

  return isNewParameterFlow ? (
    <Box
      sx={{
        bgcolor: 'grey.50',
        borderBottom: '1px solid',
        borderColor: 'grey.300',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <NavigationTabs sx={{ borderBottom: 'none' }} tabs={forecastingTabs} />
      <Box sx={{ m: 1 }}>
        <Button
          startIcon={<EditOutlinedIcon />}
          testId="btn_edit_forecast_parameters"
          variant="outlined"
          onClick={() => changeDrawerOpen(!isOpen)}
        >
          Edit Parameters
        </Button>
      </Box>
    </Box>
  ) : (
    <NavigationTabs tabs={forecastingTabs} />
  );
}

export default ForecastingTabs;
