import { useMemo } from 'react';

import { useLocation, useMatch, useParams } from 'react-router-dom';

import {
  getAnalytics,
  getAnalyticsTab,
  getClinicalExpensesByContractContainer,
  getClinicalExpensesSubTabByContractContainer,
  getClinicalExpensesTabByContractContainer,
  getCompany,
  getCompanyPeriodClosed,
  getFinancialClose,
  getFinancialCloseTab,
  getForecastTab,
  getFxRatesTab,
  getOcc,
  getOccByContractVersion,
  getOccExpenses,
  getOccRecon,
  getSiteLabCostMatrix,
  getSiteLabCostMatrixTab,
  getSpecificForecast,
} from 'routes';

function useActiveLink(link?: string) {
  const { pathname } = useLocation();
  const { contractContainerId, forecastId, activeTab } = useParams();

  const fxRatesTab = useMatch(getFxRatesTab());
  const fxRatesClosedTab = useMatch(getFxRatesTab(getCompanyPeriodClosed()));

  const occExpenses = useMatch(getOccExpenses());
  const siteLab = useMatch(getSiteLabCostMatrixTab());
  const occRecon = useMatch(getOccRecon());
  const clinicalExpensesSubTabByContractContainer = useMatch(
    getClinicalExpensesSubTabByContractContainer(),
  );
  const clinicalExpensesTabByContractContainer = useMatch(
    getClinicalExpensesTabByContractContainer(),
  );
  const forecastTabByForecast = useMatch(getForecastTab());
  const occByContractVersion = useMatch(getOccByContractVersion());
  const financialCloseTab = useMatch(getFinancialCloseTab());
  const analyticsTab = useMatch(getAnalyticsTab());

  return useMemo(() => {
    if (!link) {
      return false;
    }

    if (fxRatesTab) {
      return link === getFxRatesTab(getCompany(), activeTab);
    }

    if (fxRatesClosedTab) {
      return link === getFxRatesTab(getCompanyPeriodClosed(), activeTab);
    }

    if (analyticsTab) {
      return link === getAnalytics();
    }

    if (financialCloseTab) {
      return link === getFinancialClose();
    }

    if (occExpenses || occRecon || occByContractVersion) {
      return link === getOcc();
    }

    if (
      clinicalExpensesSubTabByContractContainer ||
      clinicalExpensesTabByContractContainer
    ) {
      return (
        link === getClinicalExpensesByContractContainer(contractContainerId)
      );
    }

    if (forecastTabByForecast) {
      return link === getSpecificForecast(forecastId);
    }

    if (siteLab) {
      return link === getSiteLabCostMatrix();
    }

    return pathname === link;
  }, [
    activeTab,
    analyticsTab,
    clinicalExpensesSubTabByContractContainer,
    clinicalExpensesTabByContractContainer,
    contractContainerId,
    financialCloseTab,
    forecastId,
    forecastTabByForecast,
    fxRatesTab,
    fxRatesClosedTab,
    link,
    occByContractVersion,
    occExpenses,
    occRecon,
    siteLab,
    pathname,
  ]);
}

export default useActiveLink;
