import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';

import type { UserDisplayOptions } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useReconRowData from 'accruals/pages/clinical-expenses/shared/hooks/useReconRowData';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import { useGetContractReconGridQuery } from 'shared/api/rtkq/contracts';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';
import useCroReconGridColumnDefs from '../hooks/useCroReconGridColumnDefs';
import useCroReconGridOptions from '../hooks/useCroReconGridOptions';
import useRegionsAndGroupsListInGrids from '../hooks/useRegionsAndGroupsListInGrids';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

const EMPTY_STATE = { rows: [], activity_groups: [] };

// This is intentionally split out by cost category, even if 99% redundant
// to ensure that A. we can correctly period-ify them and B. ag-grid will
// load a completely fresh grid as you navigate the site. B eliminates a lot
// of "randomness" to the grids (as ag-grid doesn't honor all props once the grid is loaded)
function CroDirectFeesReconGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx, userDisplayOptions } = props;

  const { contractVersionTraceId, periodTraceId, loadingContractInformation } =
    useContractAndPeriodWithVersions();

  const makeDataRequest =
    contractVersionTraceId !== undefined && periodTraceId !== undefined;
  const costCategory = 'DIRECT_FEES';
  let { currentData: reconData } = useGetContractReconGridQuery(
    makeDataRequest
      ? {
          trace_id: contractVersionTraceId,
          secondParameter: periodTraceId,
          thirdParameter: costCategory,
        }
      : skipToken,
  );

  // if there is no data contract version "yet", we'll never even try to make the call, so don't get stuck loading
  if (!makeDataRequest && !loadingContractInformation) {
    reconData = EMPTY_STATE;
  }

  const isGridLocked = useLockedGridStatus(
    LockGrid.CRO_DIRECT_FEES_RECONCILIATION_GRID,
  );
  const rowData = useReconRowData(reconData);
  const regionsAndRegionGroups = useRegionsAndGroupsListInGrids();
  const columnDefs = useGridColDefs(
    useCroReconGridColumnDefs,
    [
      rowData,
      costCategory,
      contractVersionTraceId,
      periodTraceId,
      regionsAndRegionGroups,
    ],
    isGridLocked,
  );
  const gridOptions = useGridOptions(useCroReconGridOptions, [true]);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  CroDirectFeesReconGrid,
  PeriodGridBlobType.CRO_DIRECT_FEES_RECONCILIATION_GRID,
);
