import { useState } from 'react';

import Add from '@mui/icons-material/Add';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';

import ContractContainerWizard from '../../shared/ContractContainerWizard';

function ClinicalExpenseSummaryGridHeader() {
  const [showNewCro, setShowNewCro] = useState<boolean>(false);
  const [showNewOcc, setShowNewOcc] = useState<boolean>(false);

  return (
    <HasPermission permissions={['canEditTrialInfo']}>
      <Button
        startIcon={<Add />}
        testId="new_cro"
        variant="outlined"
        onClick={() => setShowNewCro(true)}
      >
        New CRO
      </Button>

      <Button
        startIcon={<Add />}
        testId="new_occ"
        variant="outlined"
        onClick={() => setShowNewOcc(true)}
      >
        New OCC
      </Button>
      {showNewCro && (
        <ContractContainerWizard
          vendorType="CRO"
          onClose={() => setShowNewCro(false)}
        />
      )}
      {showNewOcc && (
        <ContractContainerWizard
          vendorType="OCC"
          onClose={() => setShowNewOcc(false)}
        />
      )}
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(
  ClinicalExpenseSummaryGridHeader,
);
