import type { ReactElement } from 'react';

import type { SxProps } from '@mui/material/styles';
import { skipToken } from '@reduxjs/toolkit/query';

import type { UserDisplayOptions } from 'shared/components/ag-grid/CondorAgGrid';
import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import useExpenseRowData from 'accruals/pages/clinical-expenses/shared/hooks/useExpenseRowData';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import { useGetContractExpenseGridQuery } from 'shared/api/rtkq/contracts';

import useContractAndPeriodWithVersions from '../../shared/hooks/useContractAndPeriodWithVersions';
import useCroExpenseGridColumnDefs from '../hooks/useCroExpenseGridColumnDefs';
import useCroExpenseGridOptions from '../hooks/useCroExpenseGridOptions';
import useRegionsAndGroupsListInGrids from '../hooks/useRegionsAndGroupsListInGrids';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
  userDisplayOptions: UserDisplayOptions;
};

const EMPTY_STATE = { rows: [] };

// This is intentionally split out by cost category, even if 99% redundant
// to ensure that A. we can correctly period-ify them and B. ag-grid will
// load a completely fresh grid as you navigate the site. B eliminates a lot
// of "randomness" to the grids (as ag-grid doesn't honor all props post grid render)
function CroPassThroughsExpensesGrid(props: Props): ReactElement {
  const { overlayNoRowsTemplate, sx, userDisplayOptions } = props;

  const { contractVersionTraceId, periodTraceId, loadingContractInformation } =
    useContractAndPeriodWithVersions();

  const makeDataRequest =
    contractVersionTraceId !== undefined && periodTraceId !== undefined;
  let { currentData: expenseData } = useGetContractExpenseGridQuery(
    makeDataRequest
      ? {
          trace_id: contractVersionTraceId,
          secondParameter: periodTraceId,
          thirdParameter: 'PASS_THROUGH',
        }
      : skipToken,
  );

  // if there is no data contract version "yet", we'll never even try to make the call, so don't get stuck loading
  if (!makeDataRequest && !loadingContractInformation) {
    expenseData = EMPTY_STATE;
  }

  const regionsAndRegionGroups = useRegionsAndGroupsListInGrids();

  const isGridLocked = useLockedGridStatus(
    LockGrid.CRO_PASS_THROUGHS_EXPENSE_GRID,
  );
  const columnDefs = useGridColDefs(
    useCroExpenseGridColumnDefs,
    [expenseData, 'PASS_THROUGH', regionsAndRegionGroups],
    isGridLocked,
  );
  const gridOptions = useGridOptions(useCroExpenseGridOptions);
  const rowData = useExpenseRowData(expenseData);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
      userDisplayOptions={userDisplayOptions}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  CroPassThroughsExpensesGrid,
  PeriodGridBlobType.CRO_PASS_THROUGHS_EXPENSE_GRID,
);
