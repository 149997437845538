/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import { type ReactNode, type Ref, forwardRef } from 'react';

import MuiCheckbox from '@mui/material/Checkbox';
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
/* eslint-enable @typescript-eslint/no-restricted-imports */

function Checkbox(props: CheckboxProps, ref: Ref<HTMLButtonElement>) {
  const { label, variant = 'body1', ...checkboxProps } = props;
  switch (typeof label) {
    case 'undefined':
      return <MuiCheckbox ref={ref} {...props} />;
    case 'string':
      return (
        <FormControlLabel
          control={<MuiCheckbox ref={ref} {...checkboxProps} />}
          label={<Typography variant={variant}>{label}</Typography>}
          sx={{ alignItems: 'center' }}
        />
      );
    default:
      return (
        <FormControlLabel
          control={<MuiCheckbox ref={ref} {...checkboxProps} />}
          label={label}
          sx={{ alignItems: 'center' }}
        />
      );
  }
}

export type CheckboxProps = MuiCheckboxProps & {
  label?: ReactNode | string;
  variant?: Variant | undefined;
};

export default forwardRef(Checkbox);
