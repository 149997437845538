import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';

import { valueIsSingleOrMultipleTrialArray } from 'shared/components/ag-grid/helpers/shared';
import type { CustomCellRendererParams } from 'shared/components/ag-grid/types';
function AgGridTrialAccessCellRenderer(params: CustomCellRendererParams) {
  const {
    formatter,
    value,
    placeholder,
    valueFormatted,
    colDef,
    hasPermissionsAndIsOpenPeriod,
    data,
  } = params;

  const isMultipleTrials = formatter === 'multiple_trials';
  let finalValue =
    colDef?.refData?.[value] ??
    ((valueFormatted ?? '').length > 0 ? valueFormatted : value);
  if (isMultipleTrials && valueIsSingleOrMultipleTrialArray(value)) {
    finalValue = value.length > 1 ? `${value.length} Trials` : '1 Trial';
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexFlow: 'row',
        height: '100%',
      }}
    >
      <Box>{finalValue ?? placeholder} </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {hasPermissionsAndIsOpenPeriod &&
        data !== undefined &&
        data.status !== 'Inactive' &&
        data.company_group === false ? (
          finalValue !== undefined ? (
            <EditOutlinedIcon sx={{ fontSize: 'medium' }} />
          ) : null
        ) : null}
      </Box>
    </Box>
  );
}

export default AgGridTrialAccessCellRenderer;
