import type { ReactNode } from 'react';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/system/colorManipulator';
import { Link as RouterLink } from 'react-router-dom';

import IconButton from 'shared/ui/icon-button/IconButton';

import { getCompanyDashboard } from 'routes';

type Props = {
  collapsed?: boolean;
  label?: ReactNode;
};

function BackToCompany(props: Props) {
  const { collapsed, label } = props;

  return (
    <Box
      sx={{
        height: 48,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: collapsed ? 'center' : 'initial',
        borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
        bgcolor: 'grey.50',
      }}
    >
      <Link component={RouterLink} to={getCompanyDashboard()}>
        <IconButton sx={{ width: 48, height: 48 }}>
          <HomeOutlinedIcon
            fontSize="small"
            sx={{ color: ({ palette }) => alpha(palette.common.black, 0.56) }}
          />
        </IconButton>
      </Link>
      {!collapsed && (
        <>
          <Divider orientation="vertical" variant="fullWidth" />
          <Typography
            ml={2}
            sx={{ color: ({ palette }) => alpha(palette.common.black, 0.56) }}
            variant="button"
          >
            {label}
          </Typography>
        </>
      )}
    </Box>
  );
}

export default BackToCompany;
