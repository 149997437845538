import { COMMENTS } from './comments';
import { COMPANY_FILES } from './companies';
import constructApi from './constructApi';
import { PERIOD_FILES } from './periods';

const PATH = 'condorfiles';

const apiEndpointFactory = constructApi(PATH).withTags('CONDOR_FILES');
const api = apiEndpointFactory.inject(() => ({
  deleteCondorFile: apiEndpointFactory.delete([
    PERIOD_FILES,
    COMPANY_FILES,
    COMMENTS,
  ]),
}));

export const { useDeleteCondorFileMutation } = api;
