import { type ReactElement, type ReactNode, useEffect } from 'react';

import Box from '@mui/material/Box';

import AppErrorToolbar from 'shared/components/app-toolbar/AppErrorToolbar';
import Card from 'shared/ui/card/Card';

type Props = { children: ReactNode };

export default function ErrorContainer(props: Props): ReactElement {
  const { children } = props;

  // if this page is shown, ensure there is no roller
  useEffect(() => {
    window.removeRoller();
  }, []);

  return (
    <>
      <AppErrorToolbar />
      <Box
        sx={{
          mt: 10,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Card sx={{ width: 600 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexFlow: 'column',
              p: 6,
            }}
          >
            {children}
          </Box>
        </Card>
      </Box>
    </>
  );
}
