import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { type GenericData, type GlAccountResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetGlAccountGridQuery } from 'shared/api/rtkq/companies';

export function processGlAccounts(glAccounts: GlAccountResponse[] | undefined) {
  return cloneDeep(glAccounts ?? []);
}

export default function useGlAccountRows(): GenericData[] {
  const currentCompany = useSelector(selectCompany);
  const { currentData: glAccounts } = useGetGlAccountGridQuery(
    currentCompany.trace_id,
  );

  return useMemo(() => processGlAccounts(glAccounts), [glAccounts]);
}
