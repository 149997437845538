import { useState } from 'react';

import Add from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';

import currencies from 'currencies';
import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';

import type { CurrencyCode } from '../types';

type Props = {
  disabledCurrencies?: CurrencyCode[];
  handleAddCurrency: (currency: CurrencyCode) => void;
};

function CurrencyDropdown(props: Props) {
  const { disabledCurrencies, handleAddCurrency } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelector = () => {
    setAnchorEl(null);
  };

  const handleClick = (currency: CurrencyCode) => {
    handleAddCurrency(currency);
    handleCloseSelector();
  };

  const isOpen = anchorEl !== null;
  const width = anchorEl?.offsetWidth ?? 0;

  return (
    <>
      <PeriodAwareButton
        permissions={['canEditCompanyLevelInfo']}
        startIcon={<Add sx={{ fontSize: '1rem' }} />}
        testId="AddCurrencyDropdown"
        variant="outlined"
        endIcon={
          <ArrowDropDownIcon
            sx={{
              transition: (theme) => theme.transitions.create('transform'),
              ...(isOpen && { transform: 'rotate(180deg)' }),
            }}
          />
        }
        sx={{
          '& .MuiButton-startIcon': {
            mr: 0.5,
          },
          '& .MuiButton-endIcon': {
            ml: 0.5,
          },
        }}
        onClick={handleOpenSelector}
      >
        Add currency
      </PeriodAwareButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        marginThreshold={0}
        open={isOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseSelector}
      >
        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
            <List data-testid="CurrencyDropdownList" sx={{ width }}>
              {currencies.map((currency) => (
                <ListItemButton
                  key={currency}
                  disabled={disabledCurrencies?.includes(currency)}
                  onClick={() => handleClick(currency)}
                >
                  <ListItemText primary={currency} />
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default CurrencyDropdown;
