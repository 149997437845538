import { useState } from 'react';

import Box from '@mui/material/Box';

import CommentsWidget from 'shared/components/comments/CommentsWidget';
import LockGridButton from 'shared/components/lock-grid/LockGridButton';
import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';

import OccReconGrid from 'accruals/pages/clinical-expenses/occ/grids/OccReconGrid';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { CurrencyViewMode } from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import CurrencyToggleGroup from 'shared/lib/currency-toggle-group/CurrencyToggleGroup';
import { CommentLocationType, LockGrid } from 'shared/lib/types';

import withAccrualPageRequiredData from 'accruals/api/hocs/withAccrualPageRequiredData';

import OccTabs from './OccTabs';

function OccReconTab() {
  const [currencyViewMode, setCurrencyViewMode] =
    useState<CurrencyViewMode>('trial');
  return (
    <PageContentLoadingContainer tabs={<OccTabs />}>
      <FlexGridContainer>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mb={2}
        >
          <CurrencyToggleGroup
            setValue={setCurrencyViewMode}
            testId="OCCReconToggle"
            value={currencyViewMode}
          />
          <LockGridButton grid={LockGrid.OCC_RECONCILIATION_GRID} />
        </Box>
        <OccReconGrid
          sx={{ height: '100%' }}
          userDisplayOptions={{ currencyViewMode }}
        />
        <LockGridReminder grid={LockGrid.OCC_RECONCILIATION_GRID} />
      </FlexGridContainer>
      <CommentsWidget
        location={CommentLocationType.OCC_RECON}
        commentsForPage
      />
    </PageContentLoadingContainer>
  );
}

export default withAccrualPageRequiredData(OccReconTab);
