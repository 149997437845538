import type { ColDef, ColGroupDef } from '@ag-grid-community/core';

import type { PaletteMode } from 'shared/lib/types';

import type { CondorColDef, CondorColGroupDef } from '../types';
import convertColDef from './convertColDef';

/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

If you modify these, please ensure that you never remove a specific value
(even if you think it should renamed). Period closed blobs can still have the old
value and we don't want to break the older period closed blobs

If you have any questions, please ask in #engineering
*/

function convertColDefOrColGroup(
  navigate: (path: string) => void,
  themeMode: PaletteMode,
  colDefs: Array<CondorColDef | CondorColGroupDef>,
): Array<ColDef | ColGroupDef> {
  return colDefs.map((colDefOrGroup) => ({
    ...convertColDef(navigate, themeMode, colDefOrGroup as CondorColDef),
    ...(Object.hasOwn(colDefOrGroup, 'children') && {
      children: convertColDefOrColGroup(
        navigate,
        themeMode,
        (colDefOrGroup as CondorColGroupDef).children,
      ),
    }),
  }));
}

export default (
  navigate: (path: string) => void,
  themeMode: PaletteMode,
  colDefs?: Array<CondorColDef | CondorColGroupDef>,
): Array<ColDef | ColGroupDef> | undefined => {
  if (colDefs === undefined) {
    return undefined;
  }

  return convertColDefOrColGroup(navigate, themeMode, colDefs);
};
