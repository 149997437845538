import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import Empty from 'shared/assets/icons/Empty';
import Button from 'shared/ui/button/Button';

import HasPermission from 'shared/lib/permissions/HasPermission';
import { openCreateForecastModal } from 'shared/state/slices/modalsSlice';

function EmptyForecasts() {
  const dispatch = useDispatch();
  const handleNewForecast = useCallback(
    () => dispatch(openCreateForecastModal()),
    [dispatch],
  );

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        mt: 10,
      }}
    >
      <Box
        sx={{
          width: '500px',
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'center',
        }}
      >
        <Empty sx={{ height: 80, width: 80 }} />
        <Typography color="primary" sx={{ mt: 4 }} variant="h4">
          There are no forecasts for this trial yet.
        </Typography>
        <HasPermission permissions={['canCreateAndEditForecasting']}>
          <Button
            color="primary"
            sx={{ mt: 4 }}
            testId="btn-new-forecast"
            variant="contained"
            onClick={handleNewForecast}
          >
            New Forecast
          </Button>
        </HasPermission>
      </Box>
    </Box>
  );
}

export default EmptyForecasts;
