import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import TrialsList from 'shared/components/trial-selector/TrialsList';

import { getTrialDashboard } from 'routes';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';
import type { TrialResponse } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { useChangeTrial } from 'shared/state/slices/trialSlice';

import withCompanyPageRequiredData from 'shared/api/hocs/withCompanyPageRequiredData';
import { useGetTrialsByCompanyQuery } from 'shared/api/rtkq/trials';

function CompanyOverviewPage() {
  const navigate = useNavigate();
  const changeTrial = useChangeTrial();

  const company = useSelector(selectCompany);
  const { currentData: trials } = useGetTrialsByCompanyQuery(company.trace_id);

  const onClickTrial = (trial: TrialResponse) => {
    changeTrial(trial);
    navigate(getTrialDashboard());
  };

  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        <Box
          display="flex"
          sx={{
            marginX: 'auto',
            width: 512,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {trials && (
            <Paper>
              <Typography color="primary.main" mt={3} mx={4} variant="h6">
                Programs & Trials
              </Typography>
              <Box pb={4} px={4}>
                <TrialsList trials={trials} onClickTrial={onClickTrial} />
              </Box>
            </Paper>
          )}
        </Box>
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withCompanyPageRequiredData(CompanyOverviewPage);
