import { type ReactElement } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import orderBy from 'lodash/orderBy';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logoFullWhite from 'shared/assets/images/logo-full-white.svg';
import logoFull from 'shared/assets/images/logo-full.png';
import DevBar from 'shared/components/dev-bar/DevBar';
import SkipLinks from 'shared/components/skip-links/SkipLinks';

import config from 'config';
import { getCompanyDashboard } from 'routes';
import HelpDropdown from 'shared/lib/help-dropdown/HelpDropdown';
import UserDropdown from 'shared/lib/user-dropdown/UserDropdown';
import {
  missingCompany,
  selectCompany,
} from 'shared/state/slices/companySlice';

import { useGetCompaniesQuery } from 'shared/api/rtkq/companies';

import CompanySelector from './CompanySelector';
import ThemeMode from './ThemeMode';

function AppToolbar(): ReactElement {
  const company = useSelector(selectCompany);
  const {
    currentData: companies,
    isUninitialized,
    isLoading,
    isFetching,
  } = useGetCompaniesQuery();
  const waitForApi = isUninitialized || isLoading || isFetching;
  const paletteMode = useTheme().palette.mode;
  const bannerLogo = paletteMode === 'light' ? logoFull : logoFullWhite;

  return (
    <AppBar
      position="relative"
      sx={{
        background: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.primary.main,
        flex: 0,
        // make sure this is the "most top" AppBar
        zIndex: (theme) => theme.zIndex.appBar + 1,
      }}
    >
      <SkipLinks />
      {config.VITE_APP_IS_DEV_ENVIRONMENT && <DevBar />}
      <Toolbar sx={{ height: 9 }} disableGutters>
        {company !== missingCompany ? (
          <>
            <Link to={getCompanyDashboard()}>
              <Box
                component="img"
                height={32}
                ml={1.5}
                mr={1.5}
                src={bannerLogo}
              />
            </Link>
            <Divider orientation="vertical" sx={{ my: 1.5 }} flexItem />
            <Typography
              color="text.secondary"
              component="div"
              sx={{ ml: 2, flexGrow: 1 }}
              variant="h4"
            >
              {waitForApi ? (
                <Skeleton variant="rectangular" width={200} />
              ) : companies && companies.length > 1 ? (
                <CompanySelector
                  company={company}
                  companies={orderBy(companies, [
                    (co) => co.name.toLowerCase(),
                  ])}
                />
              ) : (
                company.name
              )}
            </Typography>
          </>
        ) : (
          <>
            <Box
              component="img"
              height={32}
              ml={1.5}
              mr={1.5}
              src={bannerLogo}
            />
            <Box sx={{ flexGrow: 1 }} />
          </>
        )}
        <ThemeMode />
        <Divider orientation="vertical" flexItem />
        <HelpDropdown />
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
}
export default AppToolbar;
