/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

The only thing that should can go inside this file is the useEffect that cleans up forecasts.
If anything else goes in here, especially things that depend on forecast data, it will cause an infinite loop.

If you have any questions, please ask in #engineering
*/

import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import useClearForecasting from 'forecasting/state/hooks/useClearForecasting';

function ForecastingCleanLayout() {
  const clearForecast = useClearForecasting();

  // when this is unmounted, clear the current forecast data
  useEffect(() => clearForecast, [clearForecast]);

  return <Outlet />;
}

export default ForecastingCleanLayout;
