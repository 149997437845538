import type { ContractFileApiResult } from 'shared/lib/types';

import constructApi from './constructApi';
import { TRIAL_FILES } from './trials';

const apiEndpointFactory =
  constructApi<ContractFileApiResult>('contractfiles').withTags(
    'CONTRACT_FILES',
  );
const api = apiEndpointFactory.inject(() => ({
  getContractFilesByContract: apiEndpointFactory.getBy('contract_version'),
  getContractFilesByContractContainer: apiEndpointFactory.getBy(
    'contract_container',
    '-created_at',
  ),
  deleteContractFile: apiEndpointFactory.delete([TRIAL_FILES]),
  createContractFile: apiEndpointFactory.create<FormData>(),
  updateContractFile: apiEndpointFactory.update(),
}));

export const {
  useCreateContractFileMutation,
  useUpdateContractFileMutation,
  useDeleteContractFileMutation,
  useGetContractFilesByContractContainerQuery,
  useLazyGetContractFilesByContractContainerQuery,
  useGetContractFilesByContractQuery,
  useLazyGetContractFilesByContractQuery,
} = api;
