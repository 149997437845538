import Paper from '@mui/material/Paper';

import Alert from 'shared/ui/alert/Alert';

import { useGetEmergencyAlertsQuery } from 'shared/api/rtkq/emergencyalerts';

function EmergencyAlerts() {
  const { data: emergencyAlerts } = useGetEmergencyAlertsQuery();

  const numAlerts = emergencyAlerts?.length ?? 0;

  return numAlerts ? (
    <Paper elevation={0} sx={{ p: 1 }}>
      {emergencyAlerts?.map((emergencyAlert) => (
        <Alert
          key={emergencyAlert.trace_id}
          dismissable={emergencyAlert.dismissable}
          severity={emergencyAlert.severity}
          title={emergencyAlert.title}
        >
          {emergencyAlert.body}
        </Alert>
      ))}
    </Paper>
  ) : null;
}

export default EmergencyAlerts;
