import type { ReactNode } from 'react';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';

function withFeatureFlagDisabled<T extends object>(
  Component: (props: T) => ReactNode,
  featureFlag: string,
) {
  return function FeatureFlagDisabledWrapper(props: T): ReactNode {
    const isFeatureFlagEnabled = useFeatureFlag(featureFlag);
    return !isFeatureFlagEnabled && <Component {...props} />;
  };
}

export default withFeatureFlagDisabled;
