import type { SyntheticEvent } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid, { gridClasses } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

import SnackbarError from 'shared/components/snackbar-error/SnackbarError';
import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import Chip from 'shared/ui/chip/Chip';

import { formatFullDate } from 'shared/helpers/helpers';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import type {
  ControlListingTaskResponse,
  DropdownOption,
} from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';

import { useGetControlListTasksQuery } from 'shared/api/rtkq/companies';
import { useGetControlCodesByCompanyQuery } from 'shared/api/rtkq/controlcodes';
import { useUpsertControlCodesMappingMutation } from 'shared/api/rtkq/controllistingtasks';

import ControlCodeMappingOption from './ControlCodeMappingOption';
import { getTaskGroup, getTaskNote, getTaskTitle } from './helpers';

function ControlListingTasksGrid() {
  const canEditCompanySettings = useHasPermission(['canEditCompanySettings']);
  const currentCompany = useSelector(selectCompany);
  const { currentData: controlListingTasks } = useGetControlListTasksQuery(
    currentCompany.trace_id,
  );
  const { currentData: existingControlCodes } =
    useGetControlCodesByCompanyQuery(currentCompany.trace_id);

  const [upsertControlCodesMapping, { error: upsertControlCodesMappingError }] =
    useUpsertControlCodesMappingMutation();
  const mappedCodes =
    controlListingTasks?.flatMap((row) =>
      row.control_codes.map((controlCode) => controlCode.trace_id),
    ) ?? [];

  function descriptionForOption(option: DropdownOption<string>) {
    return existingControlCodes?.find((code) => code.trace_id === option.value)
      ?.description;
  }

  function getLastUpdatedContent(
    lastUpdated: ControlListingTaskResponse['last_updated'],
  ) {
    if (!lastUpdated) {
      return null;
    }

    const avatarText = lastUpdated.updated_by
      .split(' ')
      .map((name) => name[0])
      .join('');

    return (
      <Box display="flex" flexDirection="row" gap={1.5}>
        <Avatar sx={{ width: 32, height: 32, alignSelf: 'center' }}>
          {avatarText}
        </Avatar>
        <Box>
          <Typography variant="body1">{lastUpdated.updated_by}</Typography>
          <Typography color="rgba(0, 0, 0, 0.56)" variant="body1">
            {formatFullDate(lastUpdated.updated_at)}
          </Typography>
        </Box>
      </Box>
    );
  }

  function handleSelectChange(
    _: SyntheticEvent,
    value: Array<DropdownOption<string>>,
    name: string,
  ) {
    void upsertControlCodesMapping({
      company: currentCompany.trace_id,
      name,
      control_codes: value.map((option) => option.value),
    });
  }

  return (
    <>
      <Grid
        overflow="auto"
        sx={{
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          [`& .${gridClasses.item}`]: {
            flex: 1,
            px: 3,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          [`& .${gridClasses.container}:nth-of-type(even)`]: {
            backgroundColor: (theme) => theme.palette.grey[100],
          },
        }}
        container
      >
        <Grid
          flexWrap="nowrap"
          gap={5}
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
          container
        >
          <Grid item>
            <Typography variant="body2">Section</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">Task</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">Control code</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">Last updated</Typography>
          </Grid>
        </Grid>

        {controlListingTasks?.map((row) => (
          <Grid
            key={row.name}
            flexWrap="nowrap"
            gap={5}
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
            container
          >
            <Grid item>
              <Typography variant="body1">{getTaskGroup(row.name)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{getTaskTitle(row.name)}</Typography>
              <Typography color="rgba(0, 0, 0, 0.56)" variant="body1">
                {getTaskNote(row.name)}
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete
                disabled={!existingControlCodes || !canEditCompanySettings}
                getOptionLabel={(option) => option.label}
                label="Select codes"
                size="small"
                sx={{ ml: 2 }}
                isOptionEqualToValue={(
                  option: DropdownOption<string>,
                  value: DropdownOption<string>,
                ) => option.label === value.label}
                options={(existingControlCodes ?? []).map((controlCode) => ({
                  value: controlCode.trace_id,
                  label: controlCode.control_code,
                }))}
                renderOption={(props, option) => (
                  <ControlCodeMappingOption
                    key={option.value}
                    description={descriptionForOption(option)}
                    mappedCodes={mappedCodes}
                    option={option}
                    renderProps={props}
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={key}
                        label={option.label}
                        size="small"
                        {...tagProps}
                      />
                    );
                  })
                }
                value={row.control_codes.map((controlCode) => ({
                  value: controlCode.trace_id,
                  label: controlCode.control_code,
                }))}
                fullWidth
                multiple
                onChange={(
                  _: SyntheticEvent,
                  value: Array<DropdownOption<string>>,
                ) => handleSelectChange(_, value, row.name)}
              />
            </Grid>
            <Grid item>{getLastUpdatedContent(row.last_updated)}</Grid>
          </Grid>
        ))}
      </Grid>
      {upsertControlCodesMappingError && (
        <SnackbarError message="Save Control listing mapping failed" />
      )}
    </>
  );
}

export default ControlListingTasksGrid;
