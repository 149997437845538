import { useState } from 'react';

import Add from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useSelector } from 'react-redux';

import Button from 'shared/ui/button/Button';

import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { selectCompany } from 'shared/state/slices/companySlice';

import EditControlModal from './modals/EditControlModal';

function ControlCodesHeader() {
  const canEditCompanySettings = useHasPermission(['canEditCompanySettings']);
  const currentCompany = useSelector(selectCompany);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <Box alignItems="center" display="flex" mb={3}>
      <Typography flex={1} variant="subtitle1">
        Control codes
      </Typography>
      {canEditCompanySettings && (
        <Button
          startIcon={<Add />}
          testId="add-control"
          variant="outlined"
          onClick={() => setIsOpenDialog(true)}
        >
          Add control
        </Button>
      )}
      {isOpenDialog && (
        <EditControlModal
          companyTraceId={currentCompany.trace_id}
          onClose={() => setIsOpenDialog(false)}
        />
      )}
    </Box>
  );
}

export default ControlCodesHeader;
