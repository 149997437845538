import { useEffect, useRef } from 'react';

import type { CustomCellEditorProps } from '@ag-grid-community/react';
import Box from '@mui/material/Box';

import Checkbox from 'shared/ui/checkbox/Checkbox';

function AgGridCheckboxCellEditor(props: CustomCellEditorProps) {
  const inputRef = useRef<HTMLButtonElement>(null);

  const { value, onValueChange, stopEditing } = props;

  // being this is a checkbox, when we enter edit mode, just auto click the
  // checkbox for them to give the illusion of it's always editable
  useEffect(() => {
    // this should never be null because it's an element ref, but TS doesn't know that
    inputRef.current?.click();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 33,
      }}
    >
      <Checkbox
        ref={inputRef}
        checked={value}
        disableRipple
        onClick={() => {
          onValueChange(!value);
          stopEditing();
        }}
      />
    </Box>
  );
}

export default AgGridCheckboxCellEditor;
