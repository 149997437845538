import { useState } from 'react';

import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'shared/ui/button/Button';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';

import useClosePeriod from 'accruals/components/periods/hooks/useClosePeriod';

import {
  selectPeriod,
  useChangePeriod,
  useForcePeriodVersionReload,
  useReinitializePeriod,
} from 'accruals/state/slices/periodSlice';
import * as routes from 'routes';
import { formatShortMonthYear } from 'shared/helpers/helpers';
import useHasPermission from 'shared/lib/permissions/useHasPermission';

import { useUpdatePeriodMutation } from 'shared/api/rtkq/periods';

type Props = { bannerVariant?: boolean };

function CloseReopenPeriodButton(props: Props) {
  const { bannerVariant } = props;
  const period = useSelector(selectPeriod);

  // if userCanClosePeriod is true, then its expected that the user is the reviewer
  // for this trial. See implementation to understand more about hidden permissions.
  const userCanClosePeriod = useHasPermission(['canClosePeriod']);
  const canReopenPeriod = useHasPermission(['canReopenPeriod']);

  const [showConfirm, setShowConfirm] = useState(false);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const changePeriod = useChangePeriod();
  const [closePeriod] = useClosePeriod();
  const [updatePeriod] = useUpdatePeriodMutation();
  const navigate = useNavigate();
  const reInitPeriod = useReinitializePeriod();
  const forcePeriodVersion = useForcePeriodVersionReload();
  const isClosed = period.is_closed;

  const handleUpdatePeriod = () => {
    void (async () => {
      setShowLoadingButton(true);
      try {
        if (isClosed) {
          const newPeriod = { ...period, is_closed: false };
          await updatePeriod(newPeriod).unwrap();
          changePeriod(newPeriod);
          reInitPeriod();
          forcePeriodVersion(true);

          // some of the deep linked pages switch urls around in closed periods,
          // so safest to send to a "known-good" page. Do this first to not
          // try to inadvertently load incorrect data
          navigate(routes.getTrialDashboard());
        } else {
          await closePeriod();
        }

        setShowConfirm(false);
      } catch {
        // TODO: BETTER Error Handling
        setShowLoadingButton(false);
      }
    })();
  };

  const handleOpenModal = () => {
    setShowConfirm(true);
    setShowLoadingButton(false);
  };

  const handleCloseModal = () => {
    setShowConfirm(false);
  };

  // there will eventually be a can open permission independent of this, but for now, we'll treat this as both
  if (!userCanClosePeriod) {
    return null;
  }

  return (
    <>
      {isClosed &&
        canReopenPeriod &&
        (bannerVariant ? (
          <Button
            testId="reopen_period"
            variant="outlined"
            onClick={handleOpenModal}
          >
            Reopen period
          </Button>
        ) : (
          <Button
            startIcon={<Lock sx={{ fill: 'white' }} />}
            testId="reopen"
            variant="contained"
            onClick={handleOpenModal}
          >
            Reopen
          </Button>
        ))}
      {!isClosed && (
        <Button
          startIcon={<LockOpen />}
          testId="close"
          variant="contained"
          onClick={handleOpenModal}
        >
          Close
        </Button>
      )}

      <Dialog open={showConfirm}>
        <DialogTitle>
          Are you sure you want to {isClosed ? 're-open' : 'close'}{' '}
          {formatShortMonthYear(period.end_date)}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color="text.primary"
            component="div"
            sx={{ whiteSpace: 'pre-line' }}
            variant="body1"
          >
            {isClosed
              ? `Please note: When you re-open a period, it will be updated with the most recent data found for the trial. This includes the PO listing, invoice listing, sites, labs, pricing, and vendor budgets. Any changes you make in the re-opened period will be applied to all other open periods.

                If you are changing the status of a contract, please reopen all subsequent periods to allow the change to be applied.
                
                EDC patient data will not be updated with the current period’s data, nor will any new EDC uploads be applied to any other period.
                
                These actions cannot be undone.`
              : 'Closing the period will prevent editing of any trial information for this period. You can reopen the period at any time.'}
            {!isClosed && (
              <Typography mt={2} variant="body2">
                Please do not refresh or close this page while the period is
                closing.
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={showLoadingButton}
            testId="cancel"
            variant="outlined"
            onClick={handleCloseModal}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            data-testid="closeButton"
            loading={showLoadingButton}
            testId={isClosed ? 'reopen_period' : 'close_period'}
            variant="contained"
            onClick={handleUpdatePeriod}
          >
            {isClosed ? 'Re-open period' : 'Close'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CloseReopenPeriodButton;
