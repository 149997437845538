import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type { TrialFilesGridRow } from 'shared/lib/types';

import { useGetPeriodFilesQuery } from 'shared/api/rtkq/periods';

export function processPeriodFiles(
  trialFilesRecords: { rows: TrialFilesGridRow[] } | undefined,
) {
  return cloneDeep(trialFilesRecords?.rows);
}

export default function usePeriodFilesRows(
  _isOpenPeriod: boolean,
): TrialFilesGridRow[] | undefined {
  const period = useSelector(selectPeriod);
  const { currentData: periodFilesGrid } = useGetPeriodFilesQuery(
    period.trace_id,
  );

  return useMemo(() => processPeriodFiles(periodFilesGrid), [periodFilesGrid]);
}
