import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';

import useTrialFilesGridOptions from 'accruals/pages/trial-files/hooks/useTrialFilesGridOptions';
import useTrialFilesRows from 'accruals/pages/trial-files/hooks/useTrialFilesRows';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import useTrialFilesColumnDefs from '../hooks/useTrialFilesColumnDefs';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function TrialFilesGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useTrialFilesColumnDefs(true);
  const gridOptions = useTrialFilesGridOptions(true);
  const rowData = useTrialFilesRows(true);

  return (
    <FlexGridContainer>
      <CondorAgGrid
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowData={rowData}
        sx={sx}
      />
    </FlexGridContainer>
  );
}

export default withPeriodSpecificGridWrapper(
  TrialFilesGrid,
  PeriodGridBlobType.TRIAL_FILES,
);
