import Grid, { gridClasses } from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { Link } from 'react-router-dom';

import Chip from 'shared/ui/chip/Chip';

import type {
  ControlListingTaskResponse,
  FinancialCloseGroupType,
  UploadStatus,
} from 'shared/lib/types';

import type { SignOffTask } from './SignOff';
import SignOff from './SignOff';
import type { ChecklistComment } from './TaskIssues';
import TaskIssues from './TaskIssues';
import UploadTaskStatusGroup from './tasks-status/UploadTaskStatusGroup';

type ChecklistTask = {
  url: string;
  name: string;
  title: string;
  description?: string;
  controlListingTask: ControlListingTaskResponse | undefined;
};

type Props = { tasks: ChecklistTaskRow[] };

function AccountingChecklistGrid(props: Props) {
  const { tasks: rows } = props;
  return (
    <Grid
      sx={{
        borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        [`& .${gridClasses.item}`]: {
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          px: 4,
          py: 3,
        },
        [`& .${gridClasses.container}:nth-of-type(even)`]: {
          backgroundColor: (theme) => theme.palette.grey[100],
        },
        [`& .${gridClasses.item}:nth-of-type(-n + 3)`]: { width: '28%' },
        [`& .${gridClasses.item}:last-of-type`]: { width: '16%' },
      }}
      container
    >
      <Grid container>
        <Grid item>
          <Typography color="primary.main" variant="body2">
            Task
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="primary.main" variant="body2">
            Upload status
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="primary.main" variant="body2">
            Sign off
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      {rows.map((row, index) => (
        <Grid key={`${row.task.title}${index}`} container>
          <Grid display="flex" flexDirection="column" item>
            <Typography variant="body2">
              <Link to={row.task.url}>{row.task.title}</Link>
            </Typography>
            {!!row.task.description && (
              <Typography variant="caption">{row.task.description}</Typography>
            )}
            <Box
              alignItems="flex-end"
              display="flex"
              flex={1}
              flexWrap="wrap"
              gap={1}
            >
              {row.task.controlListingTask?.control_codes.map((code) => (
                <Tooltip
                  key={code.control_code}
                  placement="right"
                  title={code.description}
                  arrow
                >
                  <span>
                    <Chip
                      color="default"
                      label={code.control_code}
                      size="small"
                    />
                  </span>
                </Tooltip>
              ))}
            </Box>
          </Grid>
          <Grid item>
            {!!row.status && (
              <UploadTaskStatusGroup
                items={row.status.items}
                taskType={row.status.taskType}
                title={row.status.title}
              />
            )}
          </Grid>
          <Grid item>
            <SignOff
              preparer={row.preparer}
              reviewer={row.reviewer}
              task={row.task.name}
            />
          </Grid>
          <Grid item>
            <TaskIssues comments={row.comments} issues={row.issues} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export type ChecklistSection = {
  title: string;
  tasks: ChecklistTaskRow[];
  group: FinancialCloseGroupType;
};

export type ChecklistTaskRow = {
  task: ChecklistTask;
  status?: UploadStatus;
  preparer: SignOffTask;
  reviewer: SignOffTask;
  comments: ChecklistComment;
  issues?: number;
};

export default AccountingChecklistGrid;
