import type { ControlCodeResponse } from 'shared/lib/types';

import constructApi from './constructApi';

const apiEndpointFactory =
  constructApi<ControlCodeResponse>('controlcodes').withTags('CONTROL_CODES');
const api = apiEndpointFactory.inject(() => ({
  getControlCodesByCompany: apiEndpointFactory.getBy('company'),
  deleteControlCode: apiEndpointFactory.delete(),
  createControlCode: apiEndpointFactory.create(),
  updateControlCode: apiEndpointFactory.update(),
}));

export const {
  useCreateControlCodeMutation,
  useGetControlCodesByCompanyQuery,
  useUpdateControlCodeMutation,
  useDeleteControlCodeMutation,
} = api;
