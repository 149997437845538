import { useCallback, useMemo, useState } from 'react';

import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import Tabs from 'shared/ui/tabs/Tabs';

import ClosedPeriodValuesGrid from 'accruals/pages/trial/grids/ClosedPeriodValuesGrid';
import HistoricalValuesGrid from 'accruals/pages/trial/grids/HistoricalValuesGrid';
import DialogTitleWithClose from 'shared/lib/dialog/DialogTitleWithClose';
import FullWidthDialog from 'shared/lib/dialog/FullWidthDialog';
import useHasPermission from 'shared/lib/permissions/useHasPermission';

import { TRIAL_EXPENSE_SUMMARY } from 'shared/api/rtkq/apiTags';
import { api } from 'shared/api/rtkq/periods';

function HistoricValuesDialog({ onClose }: { onClose: () => void }) {
  const dispatch = useDispatch();
  const gridSx = useMemo(() => ({ height: '100%' }), []);
  const [activeTab, setActiveTab] = useState('historical');

  const userCanEditHistoricValues = useHasPermission(['canEditHistoricValues']);
  const userCanEditPeriodClosedValues = useHasPermission([
    'canEditPeriodClosedValues',
  ]);
  const canDoBoth = userCanEditHistoricValues && userCanEditPeriodClosedValues;

  const finalOnClose = useCallback(() => {
    // this can unfortunately be very slow, and doing it every time data was updated in the grids
    // makes the grids POSSIBLY super slow as well, so we'll just invalidate it on close instead
    dispatch(api.util.invalidateTags([TRIAL_EXPENSE_SUMMARY]));
    onClose();
  }, [dispatch, onClose]);

  const historicalValuesComponent = (
    <DialogContent
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexFlow: 'column',
      }}
    >
      <Typography color="primary" sx={{ pb: 2 }} variant="h6">
        Fill in the monthly booked expenses, in the trial currency, for periods
        not calculated in Condor.
      </Typography>
      <HistoricalValuesGrid
        overlayNoRowsTemplate="Historical values will appear when contracts and periods are added."
        sx={gridSx}
      />
    </DialogContent>
  );

  const closedPeriodComponent = (
    <DialogContent
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        flexFlow: 'column',
      }}
    >
      <Typography color="primary" sx={{ pb: 2 }} variant="h6">
        Fill in the LTD expenses for each cost category, in the trial currency,
        for closed periods calculated in Condor.
      </Typography>
      <ClosedPeriodValuesGrid
        overlayNoRowsTemplate="Closed values will appear when contracts and periods are added and closed."
        sx={gridSx}
      />
    </DialogContent>
  );

  return (
    <FullWidthDialog open>
      <DialogTitleWithClose divider={!canDoBoth} onClose={finalOnClose}>
        Add historical expenses
      </DialogTitleWithClose>
      {canDoBoth ? (
        <>
          <Tabs
            value={activeTab}
            onChange={(_event, newValue) => setActiveTab(newValue)}
          >
            <Tab label="Historical Periods" value="historical" />
            <Tab label="Closed Periods" value="closed" />
          </Tabs>
          <Divider sx={{ mb: 2 }} />
          {activeTab === 'historical' ? historicalValuesComponent : undefined}
          {activeTab === 'closed' ? closedPeriodComponent : undefined}
        </>
      ) : undefined}
      {userCanEditHistoricValues && !userCanEditPeriodClosedValues
        ? historicalValuesComponent
        : undefined}
      {userCanEditPeriodClosedValues && !userCanEditHistoricValues
        ? closedPeriodComponent
        : undefined}
    </FullWidthDialog>
  );
}

export default HistoricValuesDialog;
