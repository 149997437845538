import Add from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';

type Props = { onHandleVersionSelection: () => void };

function ContractVersionDetailAddVersion(props: Props) {
  const { activeTab } = useParams();
  const { onHandleVersionSelection } = props;

  return (
    <HasPermission permissions={['canEditTrialInfo']}>
      <Button
        startIcon={<Add />}
        testId="new_contract"
        variant="outlined"
        disableElevation
        onClick={onHandleVersionSelection}
      >
        {activeTab === 'amendment-in-progress'
          ? 'New Amendment-In-Progress'
          : 'New Current Contract'}
      </Button>
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(
  ContractVersionDetailAddVersion,
);
