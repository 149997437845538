import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  TypedUseMutation,
} from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-restricted-paths -- this files knows how to handle this empty correctly
import { missingPeriod, selectPeriod } from 'accruals/state/slices/periodSlice';
import useWizard from 'shared/lib/wizard/useWizard';
import { selectCompany } from 'shared/state/slices/companySlice';
import { missingTrial, selectTrial } from 'shared/state/slices/trialSlice';

import { isFetchBaseQueryError } from 'shared/api/baseApi';
import { useCreateFlatFileMappingColumnMutation } from 'shared/api/rtkq/ffmapcolumns';
import { useCreateFlatFileMappingMutation } from 'shared/api/rtkq/ffmappings';

import type { GenericData, TraceId, UploadedFile } from '../types';
import ColumnMapperDialogContent from './ColumnMapperDialogContent';
import type {
  CsvErrors,
  MappedColumn,
  MappedCsvHeader,
  MappedData,
} from './types';
import {
  filterTrailingEmptyRows,
  removeExtraQuotesAndSpaces,
  trimKeysAndValuesInRow,
} from './utils/csv-row-filters';
import usePapaparse from './utils/usePapaparse';

type MutationDefinitionType = TypedUseMutation<
  Promise<GenericData>,
  Partial<unknown>,
  BaseQueryFn<
    FetchArgs | string,
    unknown,
    FetchBaseQueryError,
    object,
    FetchBaseQueryMeta
  >
>;

type ColumnMapperProps = {
  columns: MappedColumn[];
  extraCreateSnapshotArgs?: GenericData;
  setFfMappingId?: (ffMappingId: TraceId) => void;
  setSnapshotId?: (traceId: TraceId) => void;
  stepTitleText: string;
  uploadedFiles: UploadedFile[];
  useCreateSnapshotEndpoint: MutationDefinitionType;
  usePostRecordsSaveEndpoint?: MutationDefinitionType;
  onSaveMapping?: (currentData: MappedData) => void;
};

function ColumnMapperStep(props: ColumnMapperProps) {
  const {
    stepTitleText,
    useCreateSnapshotEndpoint,
    usePostRecordsSaveEndpoint,
    uploadedFiles,
    columns,
    onSaveMapping,
    extraCreateSnapshotArgs,
    setSnapshotId,
    setFfMappingId,
  } = props;
  const {
    data: csvData,
    fields: csvFields,
    loading,
    requestCsvFile,
  } = usePapaparse();
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [csvErrors, setCsvErrors] = useState<CsvErrors>({});
  const [serverResponseError, setServerResponseError] =
    useState<FetchBaseQueryError | null>(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] =
    useState<boolean>(true);
  const [isFirstRowHeader, setIsFirstRowHeader] = useState<boolean>(true);

  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);
  const company = useSelector(selectCompany);
  const [
    createFlatFileMappingRequest,
    { isLoading: flatFileMappingIsLoading },
  ] = useCreateFlatFileMappingMutation();
  const { goToStep } = useWizard();

  const [createMappingColumn, { isLoading }] =
    useCreateFlatFileMappingColumnMutation();

  const [createSnapshotRequest, { isLoading: createSnapshotIsLoading }] =
    useCreateSnapshotEndpoint();
  const [postRecordsSaveRequest, { isLoading: recordsSaveIsLoading }] =
    usePostRecordsSaveEndpoint?.() ?? [undefined, {}];

  useEffect(() => {
    // when user clicks back button from the validationStep, and the files were uploaded in a previous session
    if (uploadedFiles.length === 0) {
      goToStep(0);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      requestCsvFile(uploadedFiles[currentFileIndex].csvBlobUrl);
    }
  }, [currentFileIndex]);

  useEffect(() => {
    setCsvErrors({});
    setServerResponseError(null);
  }, [isFirstRowHeader]);

  async function saveMappedColumns(
    csvHeaders: MappedCsvHeader[],
    mapping: string,
  ) {
    const mappingColumnRequests = csvHeaders
      .filter(({ ignore, destination }) => !ignore && destination)
      .map(async ({ destination, position }) => {
        const data = {
          mapping,
          field_name: destination!,
          column_position: position!,
        };
        const res = await createMappingColumn(data).unwrap();
        return res.trace_id;
      });

    return Promise.all(mappingColumnRequests);
  }

  async function createSnapshot(mapping: string) {
    if (uploadedFiles.length === 0) {
      return;
    }

    try {
      const res = await createSnapshotRequest({
        mapping,
        unmapped_file: uploadedFiles[currentFileIndex].traceId,
        trial: trial === missingTrial ? undefined : trial.trace_id,
        period: period === missingPeriod ? undefined : period.trace_id,
        company: company.trace_id,
        is_first_row_header: isFirstRowHeader,
        ...extraCreateSnapshotArgs,
      }).unwrap();

      if (res.trace_id != null) {
        setSnapshotId?.(res.trace_id);
        // Do not wait for the postRecordsSaveRequest to finish before enabling the Next button.
        await postRecordsSaveRequest?.({ snapshot: res.trace_id })?.unwrap();
        setIsNextButtonDisabled(false);
      }
    } catch (errors: unknown) {
      setIsNextButtonDisabled(true);

      if (isFetchBaseQueryError(errors)) {
        setCsvErrors((errors.data ?? {}) as CsvErrors);
        setServerResponseError(errors);

        if (typeof errors.status === 'undefined') {
          throw new Error('Unexpected error'); // timeout and CORS
        }

        if (errors.status !== 400) {
          // 400 error is used for CSV data issues. All other cases are not handled.
          throw new Error(`Unexpected error ${errors.status}`);
        }
      }
      throw new Error('Error saving CSV data. Check all columns for errors.');
    }
  }

  async function saveAllData(data: MappedData) {
    const { csvHeaders } = data;
    const result = await createFlatFileMappingRequest({
      mapping_name: `dev-${new Date().toUTCString()}`,
      source_column_count: csvHeaders.length,
    }).unwrap();
    const { trace_id: mapping } = result;
    setFfMappingId?.(mapping);
    await saveMappedColumns(csvHeaders, mapping);
    await createSnapshot(mapping);
  }

  async function onSave(data: MappedData) {
    await saveAllData(data);
    onSaveMapping?.(data);

    if (currentFileIndex < uploadedFiles.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
      throw new Error('Next file'); // not a real error, just a way to stop the wizard from going to the next step
    }
  }

  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Typography sx={{ color: 'grey.700' }} variant="h6">
          Analyzing and preparing attachments
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  const fields = csvFields?.map(removeExtraQuotesAndSpaces) ?? [];
  const data = csvData ?? [];
  const rows = filterTrailingEmptyRows(data)
    .map(trimKeysAndValuesInRow)
    .map((row) => fields.map((field) => String(row[field]))); // This cast from string to string is necessary to protect us against undefined because we haven't enabled noUncheckedIndexedAccess in TypeScript.
  const headerWithRows = [fields, ...rows];
  const headers: MappedCsvHeader[] = fields.map((name) => ({
    name,
    ignore: false,
  }));

  let stepTitle = stepTitleText;
  if (uploadedFiles.length > 1) {
    stepTitle += `: File ${currentFileIndex + 1} of ${uploadedFiles.length}`;
  }

  return (
    <ColumnMapperDialogContent
      csvErrors={csvErrors}
      initialColumns={columns}
      initialCsvHeaders={headers}
      isFirstRowHeader={isFirstRowHeader}
      isNextButtonDisabled={isNextButtonDisabled}
      rows={headerWithRows}
      serverResponseError={serverResponseError}
      setCsvErrors={setCsvErrors}
      setIsFirstRowHeader={setIsFirstRowHeader}
      setIsNextButtonDisabled={setIsNextButtonDisabled}
      stepTitleText={stepTitle}
      isLoading={
        isLoading ||
        flatFileMappingIsLoading ||
        createSnapshotIsLoading ||
        recordsSaveIsLoading
      }
      nextButtonText={
        currentFileIndex < uploadedFiles.length - 1
          ? 'Next file'
          : 'Save & Continue'
      }
      onSaveMapping={onSave}
    />
  );
}

export default ColumnMapperStep;
