import type { MutableRefObject } from 'react';

import type {
  CellClickedEvent,
  GetRowIdParams,
  GridOptions,
  IRowNode,
} from '@ag-grid-community/core';

import type { PaletteMode } from 'shared/lib/types';

import type {
  CondorColDef,
  CondorGetRowId,
  CondorGridOptions,
  CondorRowStyle,
} from '../types';
import convertColDef from './convertColDef';
import { highlightGroup } from './shared';

/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

If you modify these, please ensure that you never remove a specific value
(even if you think it should renamed). Period closed blobs can still have the old
value and we don't want to break the older period closed blobs

If you have any questions, please ask in #engineering
*/

const convertGetRowStyle = (getRowStyle?: CondorRowStyle) => {
  if (getRowStyle == null) {
    return;
  }

  if (
    getRowStyle === 'highlightRow' ||
    getRowStyle === 'highlightRowConfig' ||
    getRowStyle === 'highlightGroup'
  ) {
    return { getRowStyle: highlightGroup };
  }

  return { rowStyle: getRowStyle };
};

const convertAutoGroupColumnDef = (
  navigate: (path: string) => void,
  themeMode: PaletteMode,
  autoGroupColumnDef?: CondorColDef,
) => {
  if (autoGroupColumnDef === undefined) {
    return;
  }

  return {
    autoGroupColumnDef: convertColDef(navigate, themeMode, autoGroupColumnDef),
  };
};

const convertGroupIncludeFooter = (groupIncludeFooter?: boolean) => {
  if (groupIncludeFooter === undefined || !groupIncludeFooter) {
    return;
  }

  return { groupTotalRow: 'bottom' as const };
};

const convertGroupIncludeTotalFooter = (groupIncludeTotalFooter?: boolean) => {
  if (groupIncludeTotalFooter === undefined || !groupIncludeTotalFooter) {
    return;
  }

  return { grandTotalRow: 'bottom' as const };
};

const convertGetRowId = (getRowId?: CondorGetRowId) => {
  if (getRowId === undefined) {
    return;
  }

  return { getRowId: (params: GetRowIdParams) => params.data[getRowId] };
};

const convertCustomGroupFiltering = (
  selectedRows: MutableRefObject<unknown[]>,
  useCustomGroupFiltering?: boolean,
) => {
  if (useCustomGroupFiltering) {
    return {
      rowMultiSelectWithClick: true,
      rowSelection: 'multiple' as const,
      isRowSelectable: ({ data }: IRowNode) => data?.type === 'GROUP',
      isExternalFilterPresent: () => true,
      doesExternalFilterPass: (row: IRowNode) => {
        // if not a child of a "group", assume it should be shown
        if (row.data.type !== 'CHILD') {
          return true;
        }

        // if a child of a "group", check if the group is selected
        const { groupId } = row.data;
        return selectedRows.current.some(
          (selectedGroupId) => selectedGroupId === groupId,
        );
      },
      onCellClicked: ({ api, colDef, node }: CellClickedEvent) => {
        if (colDef.cellRenderer === 'AgGridGroupCellRenderer') {
          selectedRows.current = api
            .getSelectedRows()
            .map((row) => row.groupId);
          api.onFilterChanged();
          // force the row that was selected to be redrawn so the cell contents can update for the "group" row
          api.redrawRows({ rowNodes: [node] });
        }
      },
    };
  }
};

export default (
  navigate: (path: string) => void,
  selectedRows: MutableRefObject<unknown[]>,
  themeMode: PaletteMode,
  gridOptions?: CondorGridOptions,
): GridOptions | undefined => {
  if (gridOptions === undefined) {
    return;
  }
  const {
    autoGroupColumnDef,
    getRowStyle,
    getRowId,
    useCustomGroupFiltering,

    groupIncludeFooter,

    groupIncludeTotalFooter,
    ...rest
  } = gridOptions;

  return {
    ...rest,
    ...convertAutoGroupColumnDef(navigate, themeMode, autoGroupColumnDef),
    ...convertGetRowStyle(getRowStyle),
    ...convertGetRowId(getRowId),
    ...convertCustomGroupFiltering(selectedRows, useCustomGroupFiltering),
    ...convertGroupIncludeFooter(groupIncludeFooter),
    ...convertGroupIncludeTotalFooter(groupIncludeTotalFooter),
  };
};
