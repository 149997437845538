import type {
  AccrualsGridRow,
  ActivityDriverValidation,
  BsFluxRow,
  ChecklistTasksResponse,
  ContractVersionResponse,
  CroExpenseSummaryRow,
  ExpectedVisitCombinedResponse,
  JournalEntryRow,
  ManageExpectedVisitGridResponse,
  OccExpenseSummaryRow,
  OccReconRecordRequest,
  OccReconResponse,
  PatientActivityGridResponse,
  PatientJourneyCombinedGridsResponse,
  PeriodResponse,
  PoCompletenessResponse,
  SiteCostGridResponse,
  TraceId,
  TraceIdField,
  TrialActivityEnrollmentGridResponse,
  TrialActivitySitesResponse,
  TrialExpenseSummaryGridResponse,
  TrialFilesGridRow,
} from 'shared/lib/types';

import { ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG } from './administrativeorprocedurecategories';
import type { apiJSON } from './apiJSON';
import {
  ACCRUALS_GRID,
  ALL_CONTRACTS,
  BS_FLUX_GRID,
  CRO_EXPENSE_SUMMARY,
  EXPECTED_VISITS_GRID,
  JOURNAL_ENTRY_GRID,
  OCC_EXPENSE_SUMMARY,
  PATIENT_ACTIVITY_GRID,
  PATIENT_JOURNEY_GRID,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  TRIAL_ACTIVITY_ENROLLMENT_GRID,
  TRIAL_ACTIVITY_SITES_GRID,
  TRIAL_EXPENSE_SUMMARY,
} from './apiTags';
import constructApi from './constructApi';
import {
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  CONTRACT_RECON,
} from './contracts';
import { EDC_RECORD_TAG } from './edcrecords';
import { EDC_SNAPSHOT_TAG } from './edcsnapshots';
import { FINANCIAL_CLOSE_SIGN_OFF } from './financialclosesignoffs';
import { PERIOD_VERSION_TAG } from './periodversions';
import { PROCEDURES_EDC_RECORDS_TAG } from './proceduresedcrecords';
import { VENDOR_REP_ACTIVITY_SNAPSHOT } from './vendorrepactivitysnapshots';

const PATH = 'periods';
export const SITE_COST_MATRIX = 'SITE_COST_MATRIX';
export const PO_COMPLETENESS_GRID = 'PO_COMPLETENESS_GRID';
export const FINANCIAL_CLOSE_CHECKLIST = 'FINANCIAL_CLOSE_CHECKLIST';
export const PERIOD_FILES = 'PERIOD_FILES';
export const TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT =
  'TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT';
export const PERIODS_TAG = 'PERIODS';
export const PERIODS_OTHER_TAGS = [
  SITE_COST_MATRIX,
  ALL_CONTRACTS,
  PATIENT_ACTIVITY_GRID,
  PATIENT_JOURNEY_GRID,
  EXPECTED_VISITS_GRID,
  PERIOD_VERSION_TAG,
  PERIOD_OCC_EXPENSES,
  EDC_SNAPSHOT_TAG,
  EDC_RECORD_TAG,
  PROCEDURES_EDC_RECORDS_TAG,
  ADMINISTRATIVE_OR_PROCEDURE_CATEGORIES_TAG,
  PERIOD_OCC_RECON,
  ACCRUALS_GRID,
  PO_COMPLETENESS_GRID,
  FINANCIAL_CLOSE_CHECKLIST,
  CRO_EXPENSE_SUMMARY,
  OCC_EXPENSE_SUMMARY,
  TRIAL_EXPENSE_SUMMARY,
  CONTRACT_RECON,
  VENDOR_REP_ACTIVITY_SNAPSHOT,
  CONTRACT_INVESTIGATOR_FEES,
  CONTRACT_INVESTIGATOR_FEES_RECON,
  FINANCIAL_CLOSE_SIGN_OFF,
  BS_FLUX_GRID,
  JOURNAL_ENTRY_GRID,
  TRIAL_ACTIVITY_SITES_GRID,
  TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
  TRIAL_ACTIVITY_ENROLLMENT_GRID,
  PERIOD_FILES,
];

const apiEndpointFactory = constructApi<PeriodResponse>(PATH).withTags(
  PERIODS_TAG,
  PERIODS_OTHER_TAGS,
);
export const api = apiEndpointFactory.inject(() => ({
  getPeriodsByTrial: apiEndpointFactory.getBy('trial', ['end_date']),
  getPeriodsByCompany: apiEndpointFactory.getBy('company', ['end_date']),
  createPeriod: apiEndpointFactory.create([PERIOD_VERSION_TAG]),
  updatePeriod: apiEndpointFactory.update([
    PERIOD_VERSION_TAG,
    CONTRACT_RECON,
    PERIOD_OCC_RECON,
    CRO_EXPENSE_SUMMARY,
    OCC_EXPENSE_SUMMARY,
    ACCRUALS_GRID,
    TRIAL_ACTIVITY_SITES_GRID,
    JOURNAL_ENTRY_GRID,
    BS_FLUX_GRID,
  ]),
  getPatientActivity:
    apiEndpointFactory.getExtraRoute<PatientActivityGridResponse>(
      'patient_activity',
      [PATIENT_ACTIVITY_GRID],
    ),
  getOccExpenseGrid: apiEndpointFactory.getExtraRoute<apiJSON>(
    'occ_expense_grid',
    [PERIOD_OCC_EXPENSES],
  ),
  getPatientJourney:
    apiEndpointFactory.getExtraRoute<PatientJourneyCombinedGridsResponse>(
      'patient_journey',
      [PATIENT_JOURNEY_GRID],
    ),
  getManageExpectedVisitsGrid:
    apiEndpointFactory.getExtraRoute<ManageExpectedVisitGridResponse>(
      'expected_visits',
      [EXPECTED_VISITS_GRID],
    ),
  persistExpectedVisits:
    apiEndpointFactory.update<ExpectedVisitCombinedResponse>(
      [
        PATIENT_ACTIVITY_GRID,
        EXPECTED_VISITS_GRID,
        CONTRACT_INVESTIGATOR_FEES,
        CONTRACT_INVESTIGATOR_FEES_RECON,
      ],
      'persist_expected_visits',
    ),
  getUniqueEdcNames: apiEndpointFactory.getExtraRoute<{
    cohorts: string[];
    visits: string[];
  }>('unique_edc_names', [EDC_SNAPSHOT_TAG, EDC_RECORD_TAG]),
  getUniqueEdcProcedureNames: apiEndpointFactory.getExtraRoute<
    Record<string, string[]>
  >('unique_edc_procedure_names', [PROCEDURES_EDC_RECORDS_TAG]),
  getOccReconGrid: apiEndpointFactory.getExtraRoute<OccReconResponse>(
    'occ_recon_grid',
    [PERIOD_OCC_RECON],
  ),
  upsertOccReconAdjustment: apiEndpointFactory.update<
    OccReconRecordRequest,
    TraceIdField
  >([PERIOD_OCC_RECON], 'occ_recon_record_adjustment', false),
  getAccruals: apiEndpointFactory.getExtraRoute<{ rows: AccrualsGridRow[] }>(
    'accruals',
    [ACCRUALS_GRID],
  ),
  getBsFlux: apiEndpointFactory.getExtraRouteTwoParameters<{
    rows: BsFluxRow[];
  }>('bs_flux', [BS_FLUX_GRID]),
  getJournalEntries: apiEndpointFactory.getExtraRoute<{
    rows: JournalEntryRow[];
  }>('journal_entry', [JOURNAL_ENTRY_GRID]),
  getSiteCosts: apiEndpointFactory.getExtraRoute<SiteCostGridResponse>(
    'site_costs',
    [SITE_COST_MATRIX],
  ),
  getTrialActivitySites:
    apiEndpointFactory.getExtraRoute<TrialActivitySitesResponse>(
      'activity_trend_sites',
      [TRIAL_ACTIVITY_SITES_GRID],
    ),
  getTrialActivityEnrollment:
    apiEndpointFactory.getExtraRoute<TrialActivityEnrollmentGridResponse>(
      'activity_trend_enrollment',
      [TRIAL_ACTIVITY_ENROLLMENT_GRID],
    ),
  getPoCompleteness: apiEndpointFactory.getExtraRoute<PoCompletenessResponse>(
    'po_completeness',
    [PO_COMPLETENESS_GRID],
  ),
  getFinancialCloseChecklist:
    apiEndpointFactory.getExtraRoute<ChecklistTasksResponse>(
      'financial_close_checklist',
      [FINANCIAL_CLOSE_CHECKLIST, FINANCIAL_CLOSE_SIGN_OFF],
    ),
  getPeriodFiles: apiEndpointFactory.getExtraRoute<{
    rows: TrialFilesGridRow[];
  }>('period_files', [PERIOD_FILES]),
  getCroExpenseSummary: apiEndpointFactory.getExtraRouteTwoParameters<
    { rows: CroExpenseSummaryRow[] },
    TraceId | 'ALL'
  >('cro_expense_summary', [CRO_EXPENSE_SUMMARY]),
  getOccExpenseSummary: apiEndpointFactory.getExtraRoute<{
    rows: OccExpenseSummaryRow[];
  }>('occ_expense_summary', [OCC_EXPENSE_SUMMARY]),
  getTrialExpenseSummary:
    apiEndpointFactory.getExtraRoute<TrialExpenseSummaryGridResponse>(
      'trial_expense_summary',
      [TRIAL_EXPENSE_SUMMARY],
      { api: 'app-api' },
    ),
  getOccCompletedActivityDriverCount:
    apiEndpointFactory.getExtraRouteTwoParameters<
      ActivityDriverValidation,
      TraceId
    >('occ_completed_activity_driver_count', [
      TRIAL_OCC_ACTIVITY_DRIVER_COMPLETED_COUNT,
    ]),
  getCurrentContractsByTrial:
    apiEndpointFactory.getExtraRouteTwoParameters('current_contracts'),
  getAipContractsByTrial:
    apiEndpointFactory.getExtraRouteTwoParameters('aip_contracts'),
  getAllContractsByTrial: apiEndpointFactory.getExtraRouteTwoParameters<
    ContractVersionResponse[]
  >('all_contracts', [ALL_CONTRACTS]),
}));

export const {
  useGetPeriodsByTrialQuery,
  useGetPeriodsByCompanyQuery,
  useCreatePeriodMutation,
  useUpdatePeriodMutation,
  useGetPatientActivityQuery,
  useLazyGetPatientActivityQuery,
  useGetPatientJourneyQuery,
  useGetManageExpectedVisitsGridQuery,
  useLazyGetManageExpectedVisitsGridQuery,
  useLazyGetPatientJourneyQuery,
  useGetOccExpenseGridQuery,
  useLazyGetOccExpenseGridQuery,
  useGetAccrualsQuery,
  useLazyGetAccrualsQuery,
  useGetBsFluxQuery,
  useLazyGetBsFluxQuery,
  useGetJournalEntriesQuery,
  useLazyGetJournalEntriesQuery,
  useGetSiteCostsQuery,
  useLazyGetSiteCostsQuery,
  useGetTrialActivitySitesQuery,
  useLazyGetTrialActivitySitesQuery,
  useGetUniqueEdcNamesQuery,
  useGetUniqueEdcProcedureNamesQuery,
  useGetOccReconGridQuery,
  useLazyGetOccReconGridQuery,
  useUpsertOccReconAdjustmentMutation,
  useGetPoCompletenessQuery,
  useLazyGetPoCompletenessQuery,
  useGetFinancialCloseChecklistQuery,
  useLazyGetFinancialCloseChecklistQuery,
  useGetCroExpenseSummaryQuery,
  useLazyGetCroExpenseSummaryQuery,
  useGetOccExpenseSummaryQuery,
  useLazyGetOccExpenseSummaryQuery,
  useGetOccCompletedActivityDriverCountQuery,
  useLazyGetOccCompletedActivityDriverCountQuery,
  useGetAipContractsByTrialQuery,
  useGetCurrentContractsByTrialQuery,
  useGetAllContractsByTrialQuery,
  useLazyGetAllContractsByTrialQuery,
  useGetTrialExpenseSummaryQuery,
  useLazyGetTrialExpenseSummaryQuery,
  useGetTrialActivityEnrollmentQuery,
  useLazyGetTrialActivityEnrollmentQuery,
  usePersistExpectedVisitsMutation,
  useGetPeriodFilesQuery,
  useLazyGetPeriodFilesQuery,
} = api;
