import { useSelector } from 'react-redux';

import useFeatureFlag from 'shared/helpers/useFeatureFlag';
import type { UserPermissions } from 'shared/lib/types';
import { missingTrial, selectTrial } from 'shared/state/slices/trialSlice';
import {
  selectCurrentUserPermissions,
  selectUser,
  selectUserCanEdit,
} from 'shared/state/slices/userSlice';

export default function useHasPermission(
  permissions: Array<keyof UserPermissions>,
  separator: 'AND' | 'OR' = 'AND',
) {
  const usersAndPermissionsFeatureFlag = useFeatureFlag(
    'users_and_permissions',
  );
  const trial = useSelector(selectTrial);
  const currentUserTraceId = useSelector(selectUser);
  const canEditData = useSelector(selectUserCanEdit);
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);

  const permissionsMap = {
    preparer: {
      allowed: new Set<keyof UserPermissions>([
        'canSignoffAsPreparer',
        'canEditTrialInfo',
        'canOpenPeriod',
        'canAddComments',
      ]),
      denied: new Set<keyof UserPermissions>(['canClosePeriod']),
    },
    reviewer: {
      allowed: new Set<keyof UserPermissions>([
        'canSignoffAsReviewer',
        'canClosePeriod',
        'canAddComments',
      ]),
      denied: new Set<keyof UserPermissions>([
        'canEditTrialInfo',
        'canOpenPeriod',
        'canDeleteTrialInfo',
      ]),
    },
  };

  function checkHiddenPermissionsFor(
    permissionType: keyof typeof permissionsMap,
  ) {
    return permissions.every(
      (permission) =>
        permissionsMap[permissionType].allowed.has(permission) &&
        !permissionsMap[permissionType].denied.has(permission),
    );
  }

  function hasPermission(): boolean {
    if (trial !== missingTrial) {
      if (currentUserTraceId === trial.preparer?.trace_id) {
        return checkHiddenPermissionsFor('preparer');
      }
      if (currentUserTraceId === trial.reviewer?.trace_id) {
        return checkHiddenPermissionsFor('reviewer');
      }
    }

    return separator === 'OR'
      ? permissions.some((permission) => currentUserPermissions[permission])
      : permissions.every((permission) => currentUserPermissions[permission]);
  }

  return usersAndPermissionsFeatureFlag
    ? hasPermission()
    : (canEditData ?? false);
}
