import type { SiteRecordResponse } from 'shared/lib/types';

import {
  PATIENT_ACTIVITY_GRID,
  PERIOD_OCC_EXPENSES,
  PERIOD_OCC_RECON,
  TRIAL_ACTIVITY_SITES_GRID,
} from './apiTags';
import constructApi from './constructApi';
import { CONTRACT_EXPENSES, CONTRACT_RECON } from './contracts';
import { SITE_COST_MATRIX } from './periods';

const apiEndpointFactory = constructApi<SiteRecordResponse>('sites').withTags(
  'SITES',
  [
    SITE_COST_MATRIX,
    PATIENT_ACTIVITY_GRID,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    TRIAL_ACTIVITY_SITES_GRID,
  ],
);
const api = apiEndpointFactory.inject(() => ({
  getSitesByTrial: apiEndpointFactory.getBy('trial', 'number'),
  getSite: apiEndpointFactory.get(),
  createSite: apiEndpointFactory.create([TRIAL_ACTIVITY_SITES_GRID]), // when creating a site, we also create a site contract, which clears the tags
  updateSite: apiEndpointFactory.update([
    SITE_COST_MATRIX,
    PATIENT_ACTIVITY_GRID,
    CONTRACT_EXPENSES,
    CONTRACT_RECON,
    PERIOD_OCC_EXPENSES,
    PERIOD_OCC_RECON,
    TRIAL_ACTIVITY_SITES_GRID,
  ]),
}));

export const {
  useCreateSiteMutation,
  useGetSitesByTrialQuery,
  useLazyGetSitesByTrialQuery,
  useUpdateSiteMutation,
  useGetSiteQuery,
} = api;
