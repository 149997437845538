import { useMemo } from 'react';

import type { NewValueParams } from '@ag-grid-community/core/dist/types/src/entities/colDef';
import { useSelector } from 'react-redux';

import {
  getGenericCellConfig,
  getNativeCurrencyMoneyCellConfig,
  getPercentCellConfig,
  getTrialMoneyCellConfig,
} from 'shared/components/ag-grid-cells/config';
import type { CondorColDef } from 'shared/components/ag-grid/types';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import { type AccrualsGridRow, INVOICED } from 'shared/lib/types';

import { useUpsertManualAccrualsDataMutation } from 'shared/api/rtkq/manualaccrualsdata';

export default function useAccrualsColumnDefs(
  isOpenPeriod: boolean,
): Array<CondorColDef<AccrualsGridRow>> {
  const period = useSelector(selectPeriod);
  const canEditTrialInfo = useHasPermission(['canEditTrialInfo']);
  const [upsertManualAccrualsData] = useUpsertManualAccrualsDataMutation();

  return useMemo(() => {
    const handleManualAccrualsDataChange = async (event: NewValueParams) => {
      await upsertManualAccrualsData({
        period: period.trace_id,
        contract_container: event.data.contract_container,
        gl_account: event.data.gl_account,
        long_term_prepaid: event.newValue,
      });
    };

    const baseColumns = [
      { headerName: 'PO #s', field: 'po_numbers', ...getGenericCellConfig() },
      { headerName: 'G/L account', field: 'gl_account_number' },
      { headerName: 'Vendor', field: 'vendor_name', aggFunc: 'totalLabel' },
      { headerName: 'Vendor type', width: 50, field: 'vendor_type' },
      {
        headerName: 'Cost categories',
        width: 350,
        field: 'cost_categories',
        ...getGenericCellConfig({ useEmDashInTotal: false }),
      },
      {
        headerName: 'Contract value (Native)',
        field: 'native_contract_value',
        ...getNativeCurrencyMoneyCellConfig(),
        cellRendererSelector: 'currentContractValueRendererSelector',
      },
      {
        headerName: 'Contract value (Trial)',
        field: 'default_contract_value',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
    ];

    const asOfPeriodEndColumns = [
      { headerName: 'Classification status', field: 'classification_status' },
      {
        headerName: 'Unadjusted Dr/Cr Balance',
        field: 'unadjusted_balance',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Balance in A/P',
        field: 'balance_in_ap',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Balance sheet net-down',
        field: 'balance_sheet_netdown',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Accrued expense',
        field: 'accrued_expense',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Short-term prepaid',
        field: 'short_term_prepaid',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Long-term prepaid',
        field: 'long_term_prepaid',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
        ...(isOpenPeriod && {
          editable: canEditTrialInfo,
          onCellValueChanged: handleManualAccrualsDataChange,
        }),
      },
    ];

    const totalLtdColumns = [
      {
        headerName: 'Invoiced',
        field: INVOICED,
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Paid',
        field: 'paid',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: 'Reconciled expense',
        field: 'reconciled_expense',
        aggFunc: 'sum',
        ...getTrialMoneyCellConfig(),
      },
      {
        headerName: '% recognized',
        field: 'percent_recognized',
        aggFunc: 'percentRecognized',
        ...getPercentCellConfig({
          useEmDash: false,
          useEmDashInGroup: false,
          useEmDashInTotal: false,
        }),
      },
    ];

    return [
      { headerName: '', children: baseColumns },
      { headerName: 'Total LTD', children: totalLtdColumns },
      { headerName: 'As of period end', children: asOfPeriodEndColumns },
    ];
  }, [isOpenPeriod, period, upsertManualAccrualsData, canEditTrialInfo]);
}
