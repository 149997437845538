import Skeleton from '@mui/material/Skeleton';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import AssumptionRow from './assumptions-tab/AssumptionRow';
import useContracts from './assumptions-tab/hooks/useContracts';

function AssumptionsTab() {
  const { loading, contracts } = useContracts();

  return loading ? (
    <DashboardCard sx={{ overflowY: 'auto' }} title="Assumptions">
      <Skeleton height={400} variant="rectangular" width="100%" />
    </DashboardCard>
  ) : (
    contracts
      ?.filter((cv) => cv.vendor_type === 'CRO')
      .map((contractVersion) => (
        <DashboardCard
          key={contractVersion.trace_id}
          sx={{ overflowY: 'auto' }}
          title={`${contractVersion.vendor_type} ${contractVersion.vendor_name} assumptions`}
        >
          <AssumptionRow contractVersion={contractVersion} />
        </DashboardCard>
      ))
  );
}

export default AssumptionsTab;
