import { type MouseEventHandler, type ReactNode, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton, {
  listItemButtonClasses,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import type { Variant } from '@mui/material/styles/createTypography';
import { alpha } from '@mui/system/colorManipulator';
import { Link } from 'react-router-dom';

import IconButton, {
  iconButtonClasses,
} from 'shared/ui/icon-button/IconButton';

import useActiveLink from './useActiveLink';

export type SidebarItemProps = {
  collapsed?: boolean;
  disabled?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  icon?: ReactNode;
  link?: string;
  menu?: Array<{ label: string; action: () => void }>;
  showTitleCollapsed?: boolean;
  title: string;
  variant?: Variant;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export default function SidebarItem(props: SidebarItemProps) {
  const {
    collapsed = false,
    onClick,
    link,
    icon,
    title,
    disabled = false,
    expanded = false,
    variant = 'body2',
    expandable = false,
    showTitleCollapsed = false,
    menu,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const active = useActiveLink(link);

  const listItemButtonProps: Record<string, unknown> = { onClick };
  if (link) {
    listItemButtonProps.component = Link;
    listItemButtonProps.to = link;
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <Tooltip placement="right" title={collapsed ? title : undefined}>
      <ListItem
        component="div"
        sx={{
          ...(!collapsed && { minHeight: 32, maxHeight: 32 }),
          '&:hover': {
            backgroundColor: ({ palette }) => palette.primary.light,
          },
          ...(active && {
            color: (theme) => theme.palette.common.white,
            backgroundColor: (theme) => theme.palette.primary.main,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }),
        }}
        disablePadding
      >
        <ListItemButton
          {...listItemButtonProps}
          data-testid={`link-${title.toLowerCase().replace(/ /g, '_')}`}
          disabled={disabled}
          sx={{
            py: 0.75,
            [`&.${listItemButtonClasses.root}:hover`]: {
              backgroundColor: 'transparent',
            },
            [`&.${listItemButtonClasses.root}:hover .${iconButtonClasses.root}`]:
              { visibility: 'visible' },
            ...(collapsed && { py: 2, justifyContent: 'center', height: 56 }),
            ...(menu && !collapsed && !expandable && { pr: 1 }),
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 16,
              maxWidth: 16,
              color: (theme) => alpha(theme.palette.common.black, 0.87),
              ...(active && {
                color: (theme) => theme.palette.common.white,
              }),
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              variant,
              sx: {
                minWidth: 24,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
            sx={{
              m: 0,
              pl: showTitleCollapsed && collapsed ? 0.5 : 1,
              minWidth: 0,
              ...(!showTitleCollapsed && collapsed && { display: 'none' }),
            }}
          />
          {!expandable && collapsed && (
            <Box sx={{ minWidth: 24, maxWidth: 24 }}>&nbsp;</Box>
          )}
          {menu && !collapsed && (
            <>
              <IconButton
                sx={{ visibility: 'hidden' }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleOpenMenu(event);
                }}
              >
                <MoreVertIcon sx={active ? { color: 'common.white' } : null} />
              </IconButton>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
                marginThreshold={0}
                open={anchorEl !== null}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handleCloseMenu}
              >
                <Box sx={{ display: 'flex' }}>
                  <List disablePadding>
                    {menu.map(({ label, action }) => (
                      <ListItemButton
                        key={label}
                        onClick={(event) => {
                          event.preventDefault();
                          event.stopPropagation();
                          handleCloseMenu();
                          action();
                        }}
                      >
                        {label}
                      </ListItemButton>
                    ))}
                  </List>
                </Box>
              </Popover>
            </>
          )}
          {expandable && (
            <ArrowDropDownIcon
              sx={{
                minWidth: 24,
                maxWidth: 24,
                transition: (theme) => theme.transitions.create('transform'),
                ...(collapsed && { transform: 'rotate(270deg)' }),
                ...(expanded && { transform: 'rotate(180deg)' }),
                ...(collapsed && expanded && { transform: 'rotate(90deg)' }),
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}
