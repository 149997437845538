import Box from '@mui/material/Box';

import TrialUploadsListItem from './TrialUploadsListItem';

function TrialUploadsList() {
  return (
    <Box component="ul" p={0}>
      <TrialUploadsListItem primaryText="Site Listing" />
      <TrialUploadsListItem primaryText="EDC - visits" />
      <TrialUploadsListItem primaryText="EDC - unscheduled visits" />
      <TrialUploadsListItem primaryText="EDC - screen fail" />
      <TrialUploadsListItem primaryText="EDC - procedures" />
    </Box>
  );
}

export default TrialUploadsList;
