import { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PageContentLoadingContainer from 'shared/containers/page-content-loading-container/PageContentLoadingContainer';

import withCompanyPageRequiredData from 'shared/api/hocs/withCompanyPageRequiredData';
import useIsCondorEmployee from 'shared/api/hooks/useIsCondorEmployee';

import AuditLogGrid from './grids/AuditLogGrid';

function AuditLogPage() {
  const [showAllChanges, setShowAllChanges] = useState(false);
  const isCondorEmployee = useIsCondorEmployee();

  return (
    <PageContentLoadingContainer>
      <FlexGridContainer>
        {isCondorEmployee && (
          <Box sx={{ display: 'flex', mb: 0.5, justifyContent: 'flex-end' }}>
            <FormControlLabel
              label="Show all changes"
              control={
                <Switch
                  checked={showAllChanges}
                  onChange={(_, checked) => {
                    setShowAllChanges(checked);
                  }}
                />
              }
            />
          </Box>
        )}
        <AuditLogGrid
          overlayNoRowsTemplate="No Data."
          showAllChanges={showAllChanges}
          sx={useMemo(() => ({ height: '100%' }), [])}
        />
      </FlexGridContainer>
    </PageContentLoadingContainer>
  );
}

export default withCompanyPageRequiredData(AuditLogPage);
