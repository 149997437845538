import Modal from 'shared/components/modal/Modal';

import { useUpdateUserAccessMutation } from 'shared/api/rtkq/users';

type Props = {
  buttonLabel: string;
  dataToUpdate: Record<string, string[] | boolean | string>;
  message: string;
  title: string;
  trace_id: string;
  onClose: () => void;
};

function UserManagementGridPopup(props: Props) {
  const { title, trace_id, onClose, message, buttonLabel, dataToUpdate } =
    props;
  const [updateUserPermissions, { isLoading: updateUserPermissionsLoading }] =
    useUpdateUserAccessMutation();

  function onClick() {
    void (async () => {
      await updateUserPermissions({ trace_id, ...dataToUpdate });
      onClose();
    })();
  }

  return (
    <Modal
      handleClose={onClose}
      title={title}
      ButtonProps={{
        label: buttonLabel,
        testId: 'confirm_show_popup',
        loading: updateUserPermissionsLoading,
        onClick,
      }}
      isOpen
    >
      {message}
    </Modal>
  );
}

export default UserManagementGridPopup;
