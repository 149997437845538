import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { PaletteMode } from 'shared/lib/types';

const ThemeContext = createContext<{
  themeMode: PaletteMode;
  setThemeMode: Dispatch<SetStateAction<PaletteMode>>;
}>({ themeMode: 'light', setThemeMode: () => null });

export default ThemeContext;
