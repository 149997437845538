import { useSelector } from 'react-redux';

import { selectCurrentUserPermissions } from 'shared/state/slices/userSlice';

export default function useIsReadOnlyPermission() {
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);

  // Since canAccessForecasting is a read permission, we need to ignore it in the check.
  const { canAccessForecasting: _, ...remainingPermissions } =
    currentUserPermissions;

  // If any of these values are true, then the user is not read only
  return !Object.values(remainingPermissions).some((permission) => permission);
}
