/*
██████╗░██╗░░░░░███████╗░█████╗░░██████╗███████╗  ██████╗░███████╗░█████╗░██████╗░
██╔══██╗██║░░░░░██╔════╝██╔══██╗██╔════╝██╔════╝  ██╔══██╗██╔════╝██╔══██╗██╔══██╗
██████╔╝██║░░░░░█████╗░░███████║╚█████╗░█████╗░░  ██████╔╝█████╗░░███████║██║░░██║
██╔═══╝░██║░░░░░██╔══╝░░██╔══██║░╚═══██╗██╔══╝░░  ██╔══██╗██╔══╝░░██╔══██║██║░░██║
██║░░░░░███████╗███████╗██║░░██║██████╔╝███████╗  ██║░░██║███████╗██║░░██║██████╔╝
╚═╝░░░░░╚══════╝╚══════╝╚═╝░░╚═╝╚═════╝░╚══════╝  ╚═╝░░╚═╝╚══════╝╚═╝░░╚═╝╚═════╝░

This slice should be used in conjunction with the ForecastRequiredLayout component
that knows how to handle when company is null / undefined, so we can assume
that forecast is always defined inside that element, greatly simplifying the codebase.

If you use this slice outside of the ForecastRequiredLayout context, it will NOT handle 
the "missing forecast" case correctly and is likely a sign you are doing something wrong 
(or need to move where ForecastRequiredLayout is being used in the routes).

If you have any questions, please ask in #engineering
*/

import { useCallback } from 'react';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import type { ForecastResponse, TraceId } from 'shared/lib/types';
import { getForecastSafeKeys } from 'shared/state/slices/utils';
import type { RootState } from 'shared/state/store';

import baseApi from 'shared/api/baseApi';
import { getRegisteredTags } from 'shared/api/rtkq/constructApi';

type State = {
  forecast: ForecastResponse;
  initialized: boolean;
  parameterDrawerIsOpen: boolean;
};

const PARAMETER_DRAWER_COLLAPSED_KEY = 'parameter_drawer_collapsed';

export const missingForecast = null as unknown as ForecastResponse;

const initialState: State = {
  forecast: missingForecast,
  initialized: false,
  parameterDrawerIsOpen: JSON.parse(
    localStorage.getItem(PARAMETER_DRAWER_COLLAPSED_KEY) ?? 'true',
  ) as boolean,
};

const forecastSlice = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    // ideally this would have no side effects, but this is the best place to get access to current state
    changeForecast: (state, action: PayloadAction<ForecastResponse>) => {
      const newForecast = action.payload;

      // only remove the old forecast if we are already initialized and are trying to clear it intentionally.
      // this will allow us to re-initialize the state (such as when trial changes) without losing the current forecast
      if (
        state.initialized &&
        newForecast === missingForecast &&
        state.forecast !== missingForecast
      ) {
        const existingKey = makeForecastKey(state.forecast.trial);
        localStorage.removeItem(existingKey);
      }

      state.forecast = action.payload;
    },
    changeForecastParameterDrawerOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.parameterDrawerIsOpen = action.payload;
    },
    changeInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
  },
});

export const makeForecastKey = (trialTraceId: TraceId) =>
  `trial_${trialTraceId}_forecast`;

export const selectForecast = (state: RootState) => state.forecast.forecast;
export const selectForecastInitialized = (state: RootState) =>
  state.forecast.initialized;
export const selectForecastParameterDrawerOpen = (state: RootState) =>
  state.forecast.parameterDrawerIsOpen;

const { changeForecast, changeInitialized, changeForecastParameterDrawerOpen } =
  forecastSlice.actions;

export const forecastReducer = forecastSlice.reducer;

export function useChangeForecast() {
  const dispatch = useDispatch();

  return useCallback(
    (forecast: ForecastResponse | null, isInit = false) => {
      if (forecast === null) {
        dispatch(changeForecast(missingForecast));
      } else {
        localStorage.setItem(
          makeForecastKey(forecast.trial),
          forecast.trace_id,
        );
        dispatch(changeForecast(forecast));
      }

      // clear all existing RTKQ state to guarantee we're not using stale data, although we
      // don't clear "safe" keys that cannot change, so it reduces loading states
      if (!isInit) {
        const tagsToClear = getRegisteredTags().filter(
          (tag) => !getForecastSafeKeys().includes(tag),
        );
        dispatch(baseApi.util.invalidateTags(tagsToClear));
      }
    },
    [dispatch],
  );
}

export function useChangeForecastParameterDrawerOpen() {
  const dispatch = useDispatch();

  return useCallback(
    (open: boolean) => {
      localStorage.setItem(PARAMETER_DRAWER_COLLAPSED_KEY, open.toString());
      dispatch(changeForecastParameterDrawerOpen(open));
    },
    [dispatch],
  );
}

export function useInitializeForecast() {
  const change = useChangeForecast();
  const dispatch = useDispatch();

  return useCallback(
    (forecasts: ForecastResponse[] | undefined) => {
      dispatch(changeInitialized(true));

      // if we have no returned forecasts, ensure we update state in case what was present was for a company they don't have access to
      let forecast = missingForecast;
      if (forecasts?.length) {
        const previousForecastId =
          localStorage.getItem(makeForecastKey(forecasts[0].trial)) ?? null;
        const previousForecast = forecasts.find(
          (comp) => comp.trace_id === previousForecastId,
        );
        // If we didn't find a relevant selected forecast in local storage, arbitrarily default to the first one in the list.
        forecast = previousForecast ?? forecasts[0];
      }

      change(forecast, true);
    },
    [change, dispatch],
  );
}

export function useReinitializeForecast() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(changeInitialized(false));
    dispatch(changeForecast(missingForecast));
  }, [dispatch]);
}
