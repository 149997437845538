import cloneDeep from 'lodash/cloneDeep';

import { humanizeContractVersion } from 'shared/helpers/helpers';
import type {
  BackendContractStatus,
  ContractContainerPeriodMenuItem,
  ContractContainerResponse,
  ContractVersionResponse,
  VersionHistoryRow,
} from 'shared/lib/types';

const sort = (
  version1: ContractVersionResponse,
  version2: ContractVersionResponse,
  statusOrdering: BackendContractStatus[],
) => {
  if (
    version1.status_for_period &&
    version2.status_for_period &&
    version1.status_for_period !== version2.status_for_period
  ) {
    const version1Index = statusOrdering.indexOf(version1.status_for_period);
    const version2Index = statusOrdering.indexOf(version2.status_for_period);
    return version1Index - version2Index;
  }

  if (version1.effective_date && version2.effective_date) {
    const aDate = new Date(version1.effective_date);
    const bDate = new Date(version2.effective_date);
    if (aDate < bDate) {
      return 1;
    }
    if (aDate > bDate) {
      return -1;
    }
    return 0;
  }
  return 0;
};

const sortContractVersionsPreferringCurrent = (
  version1: ContractVersionResponse,
  version2: ContractVersionResponse,
) =>
  sort(version1, version2, [
    'CURRENT',
    'AIP',
    'SUPERSEDED',
    'VOIDED',
    'FUTURE',
  ]);

const sortContractVersionsPreferringAiP = (
  version1: ContractVersionResponse,
  version2: ContractVersionResponse,
) =>
  sort(version1, version2, [
    'AIP',
    'CURRENT',
    'SUPERSEDED',
    'VOIDED',
    'FUTURE',
  ]);

export function getRowsForVersionHistory(
  contractContainer: ContractContainerResponse | undefined,
  contractVersions: ContractVersionResponse[] | undefined,
  contractContainerPeriodMenuItem?: ContractContainerPeriodMenuItem,
): VersionHistoryRow[] | undefined {
  const clonedContractVersions = cloneDeep(contractVersions ?? []);

  // to compute the change from previous version:
  // 1. remove versions that are VOIDED as they don't count
  // 2. Sort the versions so they are ordered AIP, CURRENT, SUPERCEDED (if there is more than one per type, order by date desc)
  // 3. Take the current contract, look at the "next" contract, and subtract the contract values
  const nonVoidedContractVersions = clonedContractVersions.filter(
    (contractVersion) => contractVersion.status_for_period !== 'VOIDED',
  );

  nonVoidedContractVersions.sort(sortContractVersionsPreferringAiP);
  const contractVersionContractChange = nonVoidedContractVersions.reduce<
    Record<string, number | undefined>
  >((acc, contractVersion, index) => {
    let changeFromPrevious;
    const next_index = index + 1;
    if (next_index < nonVoidedContractVersions.length) {
      changeFromPrevious =
        (contractVersion.gross_contract_value ?? 0) -
        (nonVoidedContractVersions[next_index]?.gross_contract_value ?? 0);
    }
    acc[contractVersion.trace_id] = changeFromPrevious;
    return acc;
  }, {});

  clonedContractVersions.sort(sortContractVersionsPreferringCurrent);
  return clonedContractVersions.map((contractVersion) => {
    // when period closed, switch the id to period menu item INSTEAD OF contract container / version
    // so everything links correctly when clicking the row (as period closed doesn't use
    // the real contract container objects as we can't promise they won't be deleted in the future)
    const contractVersionPeriodMenuItem =
      contractContainerPeriodMenuItem?.contractVersionMenuItems
        ? contractContainerPeriodMenuItem.contractVersionMenuItems[
            contractVersion.trace_id
          ]
        : undefined;

    return {
      change_from_previous:
        contractVersionContractChange[contractVersion.trace_id],
      contract_container_trace_id:
        contractContainerPeriodMenuItem?.menuItem.trace_id ??
        contractContainer?.trace_id,
      contract_version_trace_id:
        contractVersionPeriodMenuItem?.trace_id ?? contractVersion.trace_id,
      execution_date: contractVersion.execution_date,
      effective_date: contractVersion.effective_date,
      aip_effective_date: contractVersion.aip_effective_date,
      voided_date: contractVersion.voided_date,
      last_used_date: contractVersion.last_used_date,
      gross_contract_value: contractVersion.gross_contract_value,
      net_contract_value: contractVersion.net_contract_value,
      po_number: contractVersion.po_number,
      status: contractVersion.status_for_period,
      status_trace_id_for_period: contractVersion.status_trace_id_for_period,
      trace_id: contractVersion.trace_id,
      version: humanizeContractVersion(
        contractVersion.version,
        contractVersion.amendment_number,
      ),
      version_name: contractVersion.version_name,
      vendor_name: contractContainer?.vendor_name,
      vendor_trace_id: contractContainer?.vendor?.trace_id,
      create_new: false,
      nativeCurrency: contractContainer?.currency,
    };
  });
}

export const expenseRowSorter = (
  rowA: { displayRowNum: number },
  rowB: { displayRowNum: number },
) => rowA.displayRowNum - rowB.displayRowNum;
