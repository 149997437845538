/* eslint-disable @typescript-eslint/no-restricted-imports -- Overridden MUI component is only allowed in this file that overrides it */
import ChevronRight from '@mui/icons-material/ChevronRight';
import MuiTab from '@mui/material/Tab';
import MuiTabs, { getTabsUtilityClass } from '@mui/material/Tabs';
import type { TabsProps as MuiTabsProps } from '@mui/material/Tabs/Tabs';
import type { SxProps, Theme } from '@mui/material/styles';
/* eslint-enable @typescript-eslint/no-restricted-imports */

import config from 'config';

function labelAnnotation(devLabel: string) {
  if (config.VITE_APP_IS_DEV_ENVIRONMENT && devLabel.length > 0) {
    return `[${devLabel}] `;
  }
  return '';
}

function Tabs(props: TabsProps) {
  const { tabs, orientation, children, ...otherProps } = props;
  return (
    <MuiTabs {...otherProps} orientation={orientation}>
      {tabs?.map((tab) => (
        <MuiTab
          key={tab.key}
          disabled={tab.disabled}
          icon={orientation === 'vertical' ? <ChevronRight /> : undefined}
          iconPosition={orientation === 'vertical' ? 'end' : undefined}
          label={`${labelAnnotation(tab.devLabel ?? '')}${tab.label}`}
          sx={tab.sx}
          value={tab.key}
        />
      ))}
      {!tabs && children}
    </MuiTabs>
  );
}
type TabsProps = MuiTabsProps & { tabs?: TabProps[] };

export type TabProps = {
  key: string;
  devLabel?: string;
  label: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export { getTabsUtilityClass };

export default Tabs;
