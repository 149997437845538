import type { ControlListingTaskUpsert } from 'shared/lib/types';

import constructApi from './constructApi';

export const CONTROL_LISTING_TASKS = 'CONTROL_LISTING_TASKS';

const apiEndpointFactory = constructApi('controllistingtasks').withTags(
  CONTROL_LISTING_TASKS,
);
const api = apiEndpointFactory.inject(() => ({
  upsertControlCodesMapping:
    apiEndpointFactory.create<ControlListingTaskUpsert>([], 'upsert'),
}));

export const { useUpsertControlCodesMappingMutation } = api;
