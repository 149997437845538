import Add from '@mui/icons-material/Add';
import ViewTimelineIcon from '@mui/icons-material/ViewTimelineOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useAccrualsRegionsAndAssumptionGroups } from 'accruals/components/contract-assumptions/hooks/useAccrualsRegionsAndAssumptionGroups';

import ContractAssumptionsBlobViewer from 'accruals/pages/clinical-expenses/shared/ContractAssumptionsBlobViewer';
import ContractAssumptionsWizard from 'accruals/wizards/contract-params-wizard/ContractAssumptionsWizard';
import AssumptionGroupSection from 'shared/lib/contract-assumptions/assumption-groups/AssumptionGroupSection';
import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';
import withPeriodSpecificCustomWrapper from 'shared/lib/periods/withPeriodSpecificCustomWrapper';
import {
  AssumptionGroupSectionName,
  PeriodRecordBlobType,
} from 'shared/lib/types';

import useContractAndPeriodWithVersions from './hooks/useContractAndPeriodWithVersions';

type Props = {
  openContractAssumptionsWizard: boolean;
  setOpenContractAssumptionsWizard: (open: boolean) => void;
};

function ContractAssumptionsSectionContents(props: Props) {
  const { contractContainer, contractVersionTraceId, contractVersion } =
    useContractAndPeriodWithVersions();
  const { openContractAssumptionsWizard, setOpenContractAssumptionsWizard } =
    props;

  const [regions, assumptionGroups] = useAccrualsRegionsAndAssumptionGroups(
    contractVersionTraceId,
    true,
  );

  return (
    <>
      {assumptionGroups.length === 0 ? (
        <>
          <ViewTimelineIcon sx={{ color: 'grey.700' }} />
          <Typography sx={{ mt: 2.5, mb: 5 }} variant="body1">
            Add your contract assumptions to view this data.
          </Typography>
          <PeriodAwareButton
            disabled={contractVersionTraceId === undefined}
            permissions={['canEditTrialInfo']}
            startIcon={<Add />}
            testId="add_assumptions"
            variant="contained"
            disableElevation
            onClick={() => setOpenContractAssumptionsWizard(true)}
          >
            Add Assumptions
          </PeriodAwareButton>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row',
            width: '100%',
            alignItems: 'top',
            justifyContent: 'space-evenly',
            gap: 2,
          }}
        >
          <Box sx={{ width: '100%' }}>
            <AssumptionGroupSection
              assumptionGroups={assumptionGroups}
              regionMap={{}}
              regions={regions}
              setCustomInheritedContract={(_value) => void 0}
              type={AssumptionGroupSectionName.TIMELINE}
              versionName={contractVersion?.version_name}
              readOnly
            />
            <AssumptionGroupSection
              assumptionGroups={assumptionGroups}
              regionMap={{}}
              regions={regions}
              setCustomInheritedContract={(_value) => void 0}
              sx={{ mt: 4 }}
              type={AssumptionGroupSectionName.SITES}
              versionName={contractVersion?.version_name}
              readOnly
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <AssumptionGroupSection
              assumptionGroups={assumptionGroups}
              regionMap={{}}
              regions={regions}
              setCustomInheritedContract={(_value) => void 0}
              type={AssumptionGroupSectionName.PATIENT_ENROLLMENT}
              versionName={contractVersion?.version_name}
              readOnly
            />
            <AssumptionGroupSection
              assumptionGroups={assumptionGroups}
              regionMap={{}}
              regions={regions}
              setCustomInheritedContract={(_value) => void 0}
              sx={{ mt: 4 }}
              type={AssumptionGroupSectionName.PATIENT_MONTHS}
              versionName={contractVersion?.version_name}
              readOnly
            />
          </Box>
        </Box>
      )}
      {openContractAssumptionsWizard && (
        <ContractAssumptionsWizard
          contractVersionTraceId={contractVersionTraceId}
          vendorType={contractContainer?.vendor_type}
          onClose={() => setOpenContractAssumptionsWizard(false)}
        />
      )}
    </>
  );
}

export default withPeriodSpecificCustomWrapper(
  ContractAssumptionsSectionContents,
  ContractAssumptionsBlobViewer,
  PeriodRecordBlobType.CONTRACT_ASSUMPTIONS,
);
