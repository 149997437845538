// this is a copy of the function from https://github.com/atlassian/pragmatic-drag-and-drop/blob/main/packages/hitbox/src/get-reorder-destination-index.ts
// for some reason importing it from the package was causing an error

import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

export function getReorderDestinationIndex({
  startIndex,
  closestEdgeOfTarget,
  indexOfTarget,
  axis,
}: {
  axis: 'horizontal' | 'vertical';
  closestEdgeOfTarget: Edge | null;
  indexOfTarget: number;
  startIndex: number;
}): number {
  // invalid index's
  if (startIndex === -1 || indexOfTarget === -1) {
    return startIndex;
  }

  // if we are targeting the same index we don't need to do anything
  if (startIndex === indexOfTarget) {
    return startIndex;
  }

  if (closestEdgeOfTarget == null) {
    return indexOfTarget;
  }

  const isGoingAfter: boolean =
    (axis === 'vertical' && closestEdgeOfTarget === 'bottom') ||
    (axis === 'horizontal' && closestEdgeOfTarget === 'right');

  const isMovingForward: boolean = startIndex < indexOfTarget;
  // moving forward
  if (isMovingForward) {
    return isGoingAfter ? indexOfTarget : indexOfTarget - 1;
  }
  // moving backwards
  return isGoingAfter ? indexOfTarget + 1 : indexOfTarget;
}
