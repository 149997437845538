import type { LockGrid } from 'shared/lib/types';
import { LockGridStatus } from 'shared/lib/types';

import { useGetLockGridWithFiltersQuery } from 'shared/api/rtkq/lockgrids';

import useLockGridQueryParams from './useLockGridQueryParams';

function useLockedGridStatus(grid: LockGrid) {
  const params = useLockGridQueryParams(grid);
  const { currentData } = useGetLockGridWithFiltersQuery(params);

  if (!currentData) {
    return false;
  }

  return (
    currentData.find((lockGrid) => lockGrid.grid === grid)?.status ===
    LockGridStatus.LOCK_GRID
  );
}

export default useLockedGridStatus;
