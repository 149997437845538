import type { AccrualsDataRequest, BaseResponseFields } from 'shared/lib/types';

import { ACCRUALS_GRID, BS_FLUX_GRID, JOURNAL_ENTRY_GRID } from './apiTags';
import constructApi from './constructApi';

const PATH = 'manualaccrualsdata';

const apiEndpointFactory = constructApi<
  BaseResponseFields,
  AccrualsDataRequest
>(PATH).withTags('MANUAL_ACCRUALS_DATA', [
  ACCRUALS_GRID,
  BS_FLUX_GRID,
  JOURNAL_ENTRY_GRID,
]);
const api = apiEndpointFactory.inject(() => ({
  upsertManualAccrualsData: apiEndpointFactory.create(
    [ACCRUALS_GRID, BS_FLUX_GRID, JOURNAL_ENTRY_GRID],
    'upsert_manual_accruals_data',
  ),
}));

export const { useUpsertManualAccrualsDataMutation } = api;
