import { useGetAllFeatureFlagsQuery } from 'shared/api/rtkq/featureFlags';

// this component is used to preload all the data that is needed for the app to render without causing numerous re-renders
function PreloadData() {
  useGetAllFeatureFlagsQuery();

  return null;
}

export default PreloadData;
