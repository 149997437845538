import { useEffect } from 'react';

import List from '@mui/material/List';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useDispatch, useSelector } from 'react-redux';

import {
  changeCohortsOrder,
  selectCohorts,
} from 'shared/state/slices/visitSchedulesSlice';

import AddNewCohort from './AddNewCohort';
import DraggableCohortItem from './DraggableCohortItem';
import { getReorderIndex } from './getReorderIndex';

function CohortsList() {
  const cohorts = useSelector(selectCohorts);
  const dispatch = useDispatch();

  useEffect(
    () =>
      monitorForElements({
        canMonitor({ source }) {
          return source.data.type === 'cohort';
        },
        onDrop({ location }) {
          const { destinationIndex, sourceIndex } =
            getReorderIndex(location) ?? {};
          if (
            destinationIndex === undefined ||
            sourceIndex === undefined ||
            destinationIndex === sourceIndex
          ) {
            return;
          }

          dispatch(
            changeCohortsOrder({
              sourceIndex,
              destinationIndex,
            }),
          );
        },
      }),
    [cohorts],
  );

  return (
    <List>
      {cohorts.map((cohort, index) => (
        <DraggableCohortItem
          key={cohort.traceId}
          cohort={cohort}
          index={index}
        />
      ))}
      <AddNewCohort />
    </List>
  );
}

export default CohortsList;
