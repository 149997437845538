import type { SxProps, Theme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';

const draggableSx: SxProps<Theme> = {
  backgroundImage: ({ palette }) =>
    `linear-gradient(45deg, ${alpha(palette.secondary.dark, 0.1)} 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, ${alpha(palette.secondary.dark, 0.1)} 50%, ${alpha(palette.secondary.dark, 0.1)} 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%)`,
  backgroundSize: '28px 28px',
};

export default draggableSx;
