import type { SxProps, Theme } from '@mui/material/styles';

import type { NavigationTabsProps } from 'shared/components/tabs/NavigationTabs';
import NavigationTabs from 'shared/components/tabs/NavigationTabs';
import { getTabsUtilityClass } from 'shared/ui/tabs/Tabs';

function TertiaryNavigationTabs(props: NavigationTabsProps) {
  const { tabs, sx, ...other } = props;

  const tabSx: SxProps<Theme> = {
    border: ({ palette }) => `1px solid ${palette.divider}`,
    borderLeft: 0,
    borderBottom: 0,
    [`&.${getTabsUtilityClass('selected')}`]: {
      backgroundColor: ({ palette }) => palette.background.paper,
    },
    [`&:not(.${getTabsUtilityClass('selected')})`]: {
      backgroundColor: ({ palette }) => palette.grey[100],
      color: ({ palette }) => palette.text.secondary,
    },
    '&:first-of-type': {
      borderLeft: ({ palette }) => `1px solid ${palette.divider}`,
    },
  };

  return (
    <NavigationTabs
      sx={{
        [`& .${getTabsUtilityClass('indicator')}`]: {
          top: 0,
        },
        ...sx,
      }}
      tabs={tabs.map((tab) => ({
        ...tab,
        sx: tabSx,
      }))}
      {...other}
    />
  );
}

export default TertiaryNavigationTabs;
