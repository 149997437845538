import type { ChangeEvent, DragEventHandler } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Button from 'shared/ui/button/Button';

import type {
  BackendContractVersion,
  ContractFile,
  ContractInfo,
  DropdownOption,
  SiteFileRequest,
} from 'shared/lib/types';

import { useDeleteContractFileMutation } from 'shared/api/rtkq/contractfiles';

import DocumentsList from './DocumentsList';
import uploadIcon from './icons/uploadIcon.svg';

import styles from './ContractVersionWizard.module.scss';

type Props = {
  clearFieldErrors: () => void;
  contractInfo: ContractInfo;
  errors?: ContractFile[];
  setContractInfo: (contractInfo: ContractInfo) => void;
};

function DocumentsInfoForm(props: Props) {
  const { contractInfo, setContractInfo, clearFieldErrors, errors } = props;
  const [deleteContractFile] = useDeleteContractFileMutation();

  const handleDrag: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { files } = event.dataTransfer;
    createContractFiles(files);
    event.dataTransfer.clearData();
  };

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      createContractFiles(files);
    }
  };

  const createContractFiles = (files: FileList) => {
    const newDocuments = [
      ...contractInfo.documents,
      ...[...files].map((file) => ({
        tag: '',
        file,
        file_name: file.name,
      })),
    ];
    setContractInfo({ ...contractInfo, documents: newDocuments });
  };

  const getSelectedTagOption = (tag: string) =>
    tagsList.find((option) => option.value === tag);

  const handleTagChange = (newValue: string, index: number) => {
    const newDocuments = [...contractInfo.documents];
    newDocuments[index].tag = newValue;
    setContractInfo({ ...contractInfo, documents: newDocuments });
  };

  const handleRemoveDocument = async (index: number) => {
    const docToRemove = contractInfo.documents[index].traceId;
    if (docToRemove) {
      // call API to remove document from server
      try {
        await deleteContractFile(docToRemove).unwrap();
      } catch {
        // ?: Handle error?
        return;
      }
    }
    const newDocuments = [...contractInfo.documents];
    newDocuments.splice(index, 1);
    setContractInfo({ ...contractInfo, documents: newDocuments });
    clearFieldErrors();
  };

  const allDocuments: ContractFileWithOgIndex[] = contractInfo.documents.map(
    (doc, index) => ({
      ...doc,
      ogIndex: index,
    }),
  );

  const newDocuments = allDocuments.filter((document) => !document.traceId);
  const uploadedDocuments = allDocuments.filter((document) => document.traceId);

  return (
    <div className={styles.uploaderContent}>
      <div
        className={styles.uploader}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <Typography sx={{ marginBottom: 1 }} variant="h5">
          Contract <sup style={{ color: 'red', fontSize: 16 }}>*</sup>
        </Typography>
        <Typography color="grey.700" sx={{ marginBottom: 2 }} variant="body1">
          Accepted file formats: pdf, docx, csv, xlsx
        </Typography>
        <div className={styles.uploaderInput}>
          <Box sx={{ marginTop: 10 }}>
            <p>
              <img alt="File Icon" height="50" src={uploadIcon} />
            </p>
            <Typography color="grey.700" variant="body1">
              Drag and drop file here or select a file from your computer.
            </Typography>
            <Button
              component="label"
              sx={{ marginTop: 2 }}
              testId="select_files"
              variant="outlined"
            >
              Select files{}
              <input
                accept=".csv,.xls,.xlsx,.pdf,.jpeg,.jpg,.png,.doc,.docx"
                data-testid="upInput"
                type="file"
                hidden
                multiple
                onChange={handleFileSelection}
              />
            </Button>
          </Box>
        </div>
      </div>
      {allDocuments.length > 0 && (
        <div className={styles.uploaded}>
          {newDocuments.length > 0 && (
            <div className={styles.newFiles}>
              <Typography className={styles.documentType} color="grey.900">
                New files
              </Typography>
              <DocumentsList
                documents={newDocuments}
                errors={errors}
                getSelectedTagOption={getSelectedTagOption}
                handleTagChange={handleTagChange}
                handleRemoveDocument={(ogIndex: number) =>
                  void handleRemoveDocument(ogIndex)
                }
              />
            </div>
          )}
          {uploadedDocuments.length > 0 && (
            <div className={styles.uploadedFiles}>
              <Typography className={styles.documentType} color="grey.900">
                Uploaded files
              </Typography>
              <DocumentsList
                documents={uploadedDocuments}
                getSelectedTagOption={getSelectedTagOption}
                handleTagChange={handleTagChange}
                handleRemoveDocument={(ogIndex: number) =>
                  void handleRemoveDocument(ogIndex)
                }
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export const tagsList: Array<
  DropdownOption<BackendContractVersion | '' | 'BUDGET' | 'OTHER'>
> = [
  { value: '', label: '' },
  { value: 'LOI', label: 'LOI' },
  { value: 'OG_WORK_ORDER', label: 'Original Work Order' },
  { value: 'AMENDMENT', label: 'Amendment' },
  { value: 'BUDGET', label: 'Budget' },
  { value: 'OTHER', label: 'Other' },
];
export type ContractFileWithOgIndex = ContractFile & { ogIndex: number };
export type SiteFileWithOgIndex = SiteFileRequest & { ogIndex: number };
export default DocumentsInfoForm;
