import Typography from '@mui/material/Typography';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import Box from '@mui/material/Box';

import IconButton from 'shared/ui/icon-button/IconButton';
import { EM_DASH_PLACEHOLDER } from 'shared/components/ag-grid/helpers/shared';

type Props = {
  downloadUrl?: string;
  primaryText: string;
};

function TrialUploadsListItem(props: Props) {
  const { primaryText, downloadUrl } = props;
  return (
    <Box
      component="li"
      sx={{
        alignItems: 'center',
        display: 'flex',
        px: 2,
        py: 1.5,
        borderRadius: 2,
        '&:nth-of-type(even)': {
          backgroundColor: ({ palette }) => palette.secondary.light,
        },
      }}
    >
      <Box flex={1}>
        <Typography variant="subtitle2">{primaryText}</Typography>
        <Typography variant="caption">{EM_DASH_PLACEHOLDER}</Typography>
      </Box>
      <Box display="flex" flex={1}>
        <Box
          alignItems="center"
          bgcolor="warning.light"
          display="flex"
          padding="3px 6px"
        >
          <WarningAmberOutlinedIcon sx={{ fontSize: '12px' }} />
          <Typography ml={1} variant="caption">
            Not uploaded
          </Typography>
        </Box>
      </Box>
      <Box flex={1} />
      <Box display="flex" justifyContent="flex-end">
        <IconButton>
          <UploadIcon />
        </IconButton>
        <IconButton disabled={!downloadUrl}>
          <DownloadOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default TrialUploadsListItem;
