import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

import { getReorderDestinationIndex } from './getReorderDestinationIndex';

export function getReorderIndex(
  location: DragLocationHistory,
): { sourceIndex: number; destinationIndex: number } | undefined {
  const target = location.current.dropTargets[0];
  const source = location.initial.dropTargets[0];

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- we need to check if source and target are defined
  const closestEdge = extractClosestEdge(target?.data);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- we need to check if source and target are defined
  if (!source || !target || !closestEdge) {
    return;
  }

  const sourceIndex = source.data.index as number;
  const destinationIndex = getReorderDestinationIndex({
    startIndex: sourceIndex,
    closestEdgeOfTarget: closestEdge,
    indexOfTarget: target.data.index as number,
    axis: 'vertical',
  });

  return {
    sourceIndex,
    destinationIndex,
  };
}
