import { type ReactElement } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import logoFullWhite from 'shared/assets/images/logo-full-white.svg';
import logoFull from 'shared/assets/images/logo-full.png';
import DevBar from 'shared/components/dev-bar/DevBar';
import SkipLinks from 'shared/components/skip-links/SkipLinks';

import config from 'config';
import HelpDropdown from 'shared/lib/help-dropdown/HelpDropdown';
import UserDropdown from 'shared/lib/user-dropdown/UserDropdown';

import ThemeMode from './ThemeMode';

function AppErrorToolbar(): ReactElement {
  const paletteMode = useTheme().palette.mode;
  const bannerLogo = paletteMode === 'light' ? logoFull : logoFullWhite;

  return (
    <AppBar
      position="relative"
      sx={{
        background: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.primary.main,
        flex: 0,
        // make sure this is the "most top" AppBar
        zIndex: (theme) => theme.zIndex.appBar + 1,
      }}
    >
      <SkipLinks />
      {config.VITE_APP_IS_DEV_ENVIRONMENT && <DevBar />}
      <Toolbar sx={{ height: 9 }} disableGutters>
        <Box component="img" height={32} ml={1.5} mr={1.5} src={bannerLogo} />
        <Box sx={{ flexGrow: 1 }} />
        <ThemeMode />
        <Divider orientation="vertical" flexItem />
        <HelpDropdown />
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
}
export default AppErrorToolbar;
