import { useCallback, useState } from 'react';

import type { ICellRendererParams } from '@ag-grid-community/core';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Typography from '@mui/material/Typography';

import Modal from 'shared/components/modal/Modal';
import IconButton from 'shared/ui/icon-button/IconButton';

import type { TrialFilesGridRow } from 'shared/lib/types';

import { useDeleteCondorFileMutation } from 'shared/api/rtkq/condorfiles';
import { useDeleteContractFileMutation } from 'shared/api/rtkq/contractfiles';
import { useDeleteSiteFileMutation } from 'shared/api/rtkq/sitefiles';
import { useDeleteUnmappedFileMutation } from 'shared/api/rtkq/unmappedfiles';

function AgGridTrialFileDeleteCellRenderer(
  params: ICellRendererParams<TrialFilesGridRow>,
) {
  const { data } = params;
  const { file_name, id, file_type } = data ?? {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteContractFile, { isLoading: isDeletingContractFile }] =
    useDeleteContractFileMutation();
  const [deleteSiteFile, { isLoading: isDeletingSiteFile }] =
    useDeleteSiteFileMutation();
  const [deleteUnmappedFile, { isLoading: isDeletingUnmappedFile }] =
    useDeleteUnmappedFileMutation();
  const [deleteCondorFile, { isLoading: isDeletingCondorFile }] =
    useDeleteCondorFileMutation();

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleFileDelete = useCallback(() => {
    if (!id || !file_type) {
      return;
    }
    switch (file_type) {
      case 'contract':
        void deleteContractFile(id);
        break;
      case 'site':
        void deleteSiteFile(id);
        break;
      case 'unmapped':
        void deleteUnmappedFile(id);
        break;
      case 'condor':
        void deleteCondorFile(id);
        break;
    }
    handleCloseModal();
  }, [
    id,
    file_type,
    deleteContractFile,
    deleteSiteFile,
    deleteUnmappedFile,
    deleteCondorFile,
    handleCloseModal,
  ]);

  return (
    <>
      <IconButton size="small" onClick={handleOpenModal}>
        <DeleteIcon />
      </IconButton>
      {isModalOpen && (
        <Modal
          handleClose={handleCloseModal}
          title={`Are you sure you want to delete ${file_name}?`}
          ButtonProps={{
            label: 'Delete File',
            testId: 'DeleteTrialFilesModal',
            onClick: handleFileDelete,
            color: 'error',
            sx: { backgroundColor: 'error.main' },
            loading:
              isDeletingContractFile ||
              isDeletingSiteFile ||
              isDeletingUnmappedFile ||
              isDeletingCondorFile,
          }}
          isOpen
        >
          <Typography>
            This may affect your trial accruals or forecasts if the file is
            being used for any expense calculations. This action cannot be
            undone.
          </Typography>
        </Modal>
      )}
    </>
  );
}

export default AgGridTrialFileDeleteCellRenderer;
