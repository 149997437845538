import type { Ref } from 'react';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { type IDoesFilterPassParams } from '@ag-grid-community/core';
import type { CustomFilterProps } from '@ag-grid-community/react';
import { useGridFilter } from '@ag-grid-community/react';

const VALUES = {
  'Select all': { min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER },
  'No variance': { min: 0, max: 0 },
  '-100000 and below': { min: Number.MIN_SAFE_INTEGER, max: -100000 },
  '-100000 to -50000': { min: -100000, max: -50000 },
  '-50000 to -10000': { min: -50000, max: -10000 },
  '-10000 to -5000': { min: -10000, max: -5000 },
  '-5000 to -1000': { min: -5000, max: -1000 },
  '-1000 to 0': { min: -1000, max: 0 },
  '0 to 1000': { min: 0, max: 1000 },
  '1000 to 5000': { min: 1000, max: 5000 },
  '5000 to 10000': { min: 5000, max: 10000 },
  '10000 to 50000': { min: 10000, max: 50000 },
  '50000 to 100000': { min: 50000, max: 100000 },
  '100000 and above': { min: 100000, max: Number.MAX_SAFE_INTEGER },
};

type AllowedFilters = keyof typeof VALUES;

const DEFAULT_STATE = Object.keys(VALUES).reduce<
  Record<AllowedFilters, boolean>
>(
  (acc, key) => {
    acc[key as AllowedFilters] = true;
    return acc;
  },
  {} as Record<AllowedFilters, boolean>, // eslint-disable-line @typescript-eslint/prefer-reduce-type-parameter -- without this, TS will think the type is wrong
);

const parseNullableFloat = (num: number | string | null | undefined) => {
  if (num === null || num === undefined) {
    return null;
  }

  const parsedNumber = Number.parseFloat(num.toString());
  return Number.isNaN(parsedNumber) ? null : parsedNumber;
};

const getActiveFilterArray = (filters: Record<AllowedFilters, boolean>) =>
  Object.entries(filters).reduce<AllowedFilters[]>((acc, [key, value]) => {
    if (value) {
      acc.push(key as AllowedFilters);
    }
    return acc;
  }, []);

function AgGridVarianceFilter(
  {
    onModelChange,
    getValue,
  }: CustomFilterProps<unknown, unknown, { value: AllowedFilters[] }>,
  ref: Ref<unknown>,
) {
  // we keep track of the state because all ag-grid gives us is an array of
  // selected filters and treats both "no filter selected" and "all filters selected"
  // as the same thing and while those do end up doing the same filtering, the logic is a
  // lot easier to reason about if we can just see if a filter is true or false
  const [activeFilters, setActiveFilters] = useState<
    Record<AllowedFilters, boolean>
  >({ ...DEFAULT_STATE });

  useGridFilter({
    doesFilterPass: useCallback(
      ({ node }: IDoesFilterPassParams) => {
        // short-circuit the logic if we know the filter is not doing anything
        if (activeFilters['Select all']) {
          return true;
        }

        const numericValue = parseNullableFloat(getValue(node));
        return Object.entries(activeFilters).some(([key, value]) => {
          if (!value) {
            return false;
          }

          const minMax = VALUES[key as AllowedFilters];

          return (
            numericValue != null &&
            numericValue >= minMax.min &&
            numericValue <= minMax.max
          );
        });
      },
      [activeFilters, getValue],
    ),
  });

  // this looks like it is doing nothing, but it's required to setup the connection correctly for ag grid
  useImperativeHandle(ref, () => ({}));

  // this is meant to look like the ag grid built in checkbox filter
  return (
    <div className="ag-set-filter-list">
      {Object.entries(activeFilters).map(([key, checked]) => (
        <div key={key} className="ag-set-filter-item" style={{ height: 24 }}>
          <div className="ag-set-filter-item-checkbox ag-labeled ag-label-align-right ag-checkbox ag-input-field">
            <div className="ag-input-field-label ag-label ag-checkbox-label ag-label-ellipsis">
              {key}
            </div>
            <div
              className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper${checked ? ' ag-checked' : ''}`}
            >
              <input
                className="ag-input-field-input ag-checkbox-input"
                data-testid={key}
                name={key}
                type="checkbox"
                value={key}
                onClick={() => {
                  const newFilters = { ...activeFilters };
                  const filterKeys = Object.keys(
                    newFilters,
                  ) as AllowedFilters[];

                  if (key === 'Select all') {
                    for (const filterKey of filterKeys) {
                      newFilters[filterKey] = !checked;
                    }
                  } else {
                    newFilters[key as AllowedFilters] = !checked;
                    newFilters['Select all'] = filterKeys.every(
                      (value) => value === 'Select all' || newFilters[value],
                    );
                  }

                  setActiveFilters(newFilters);
                  onModelChange({
                    value: getActiveFilterArray(newFilters),
                  });
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default forwardRef(AgGridVarianceFilter);
