import { useState } from 'react';

import CsvViewerModal from 'shared/lib/csv-viewer-modal/CsvViewerModal';

function CsvViewerDemo() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        View CSV
      </button>
      <CsvViewerModal
        description="April EDC report, Trial 101-322. Confirmed with Clin Ops and removed redundant patient visit rows 16+17 on previous upload."
        fileUrl="https://awesomehunt.s3.us-east-2.amazonaws.com/cities.csv"
        isOpen={isOpen}
        title="April 2021 EDC Report"
        uploadedOn={new Date()}
        handleClose={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
}

export default CsvViewerDemo;
