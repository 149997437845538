import { useRef } from 'react';

import type { FileUploaderRef } from 'shared/components/file-uploader/FileUploader';
import FileUploader from 'shared/components/file-uploader/FileUploader';

import type { UnmappedFileResponse } from 'shared/lib/types';

import UploadErrorSection from './UploadErrorSection';
import UploadFileSection from './UploadFileSection';
import UploadSuccessSection from './UploadSuccessSection';
import UploadingSection from './UploadingSection';

type Props = {
  accept: string;
  columnCount: number;
  corruptedCells?: Array<{ row: number; column: number; value: string }>;
  fileUploadData: { message: string; rows_count: number } | null;
  files: File[];
  filesUploaded: UnmappedFileResponse[];
  isUploadFailed: boolean;
  isUploading: boolean;
  sampleFileLink: string | undefined;
  sampleFileName: string;
  setFiles: (files: File[]) => void;
};

function CsvUploader(props: Props) {
  const {
    columnCount,
    fileUploadData,
    files,
    filesUploaded,
    isUploadFailed,
    sampleFileLink,
    sampleFileName,
    isUploading,
    accept,
    setFiles,
    corruptedCells,
  } = props;

  const fileUploaderRef = useRef<FileUploaderRef>(null);

  return (
    <FileUploader
      ref={fileUploaderRef}
      accept={accept}
      hideSelectFile={filesUploaded.length !== 0 || isUploadFailed}
      uploadButtonText="Select file"
      sx={{
        mt: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
        height: '100%',
        maxHeight: '700px',
        padding: 5,
        bgcolor: 'common.white',
        borderRadius: 4,
        border: 1,
        borderColor: 'grey.500',
        borderStyle: 'dashed',
        textAlign: 'center',
      }}
      multiple
      onChangeFiles={(newFiles) => setFiles(newFiles)}
    >
      {isUploadFailed ? (
        <UploadErrorSection
          columnCount={columnCount}
          corruptedCells={corruptedCells}
          fileUploadData={fileUploadData}
          fileUploaderRef={fileUploaderRef}
          files={files}
          sampleFileLink={sampleFileLink}
          sampleFileName={sampleFileName}
        />
      ) : isUploading ? (
        <UploadingSection />
      ) : filesUploaded.length > 0 ? (
        <UploadSuccessSection
          columnCount={columnCount}
          fileUploadData={fileUploadData}
          fileUploaderRef={fileUploaderRef}
          files={files}
          filesUploaded={filesUploaded}
        />
      ) : (
        <UploadFileSection
          sampleFileLink={sampleFileLink}
          sampleFileName={sampleFileName}
        />
      )}
    </FileUploader>
  );
}

export default CsvUploader;
