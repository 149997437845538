import Edit from '@mui/icons-material/Edit';

import { useAccrualsRegionsAndAssumptionGroups } from 'accruals/components/contract-assumptions/hooks/useAccrualsRegionsAndAssumptionGroups';

import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';
import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';

import useContractAndPeriodWithVersions from './hooks/useContractAndPeriodWithVersions';

type Props = {
  setOpenContractAssumptionsWizard: (open: boolean) => void;
};

function EditContractAssumptionsButton({
  setOpenContractAssumptionsWizard,
}: Props) {
  const { contractVersionTraceId } = useContractAndPeriodWithVersions();
  const [, assumptionGroups] = useAccrualsRegionsAndAssumptionGroups(
    contractVersionTraceId,
    true,
  );

  return assumptionGroups.length ? (
    <PeriodAwareButton
      permissions={['canEditTrialInfo']}
      startIcon={<Edit />}
      testId="edit"
      variant="outlined"
      disableElevation
      onClick={() => setOpenContractAssumptionsWizard(true)}
    >
      Edit
    </PeriodAwareButton>
  ) : undefined;
}

export default withPeriodSpecificGenericWrapper(EditContractAssumptionsButton);
