import { useState } from 'react';

import Typography from '@mui/material/Typography';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';

import type { LabGridRow } from 'shared/lib/types';

import { useDeleteLabContractMutation } from 'shared/api/rtkq/labcontracts';

type Props = {
  labGridRow: LabGridRow;
};

function LabMenu(props: Props) {
  const { labGridRow } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteLabContract, { isLoading: isDeletingLabContract }] =
    useDeleteLabContractMutation();

  const handleLabContractDelete = async () => {
    if (labGridRow.lab_contract_trace_id) {
      await deleteLabContract(labGridRow.lab_contract_trace_id);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <KabobMenu
        size="small"
        options={[
          {
            label: 'Delete lab',
            onClick: () => setIsModalOpen(true),
          },
        ]}
      />
      <Modal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={`Are you sure you want to delete this version of ${labGridRow.name}?`}
        ButtonProps={{
          label: 'Delete Version',
          testId: 'DeleteLabContractModal',
          onClick: () => void handleLabContractDelete(),
          sx: { backgroundColor: 'error.main' },
          loading: isDeletingLabContract,
        }}
      >
        <Typography>
          If the lab version was being used in any trial expenses, accrual and
          forecasting calculations may be affected. This action cannot be
          undone.
        </Typography>
      </Modal>
    </>
  );
}

export default LabMenu;
