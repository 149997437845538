import type { GetTokenSilentlyOptions } from '@auth0/auth0-react';

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined,
) => Promise<string>;

const security = {
  getGetAccessTokenSilently: () => getAccessTokenSilently,
  setGetAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  ) => (getAccessTokenSilently = func),
};

export default security;
