import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';

import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { PeriodGridBlobType } from 'shared/lib/types';

import useGlAccountColumnDefs from '../hooks/useGlAccountColumnDefs';
import useGlAccountGridOptions from '../hooks/useGlAccountGridOptions';
import useGlAccountRows from '../hooks/useGlAccountRows';

type Props = {
  overlayNoRowsTemplate?: string;
  sx?: SxProps;
};

function GlAccountsGrid(props: Props) {
  const { overlayNoRowsTemplate, sx } = props;

  const columnDefs = useGridColDefs(useGlAccountColumnDefs);
  const rowData = useGlAccountRows();
  const gridOptions = useGridOptions(useGlAccountGridOptions);

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  GlAccountsGrid,
  PeriodGridBlobType.GL_ACCOUNTS,
);
