import { type ReactElement, useContext } from 'react';

import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import ThemeContext from 'shared/containers/theme-context/ThemeContext';

import useIsCondorEmployee from 'shared/api/hooks/useIsCondorEmployee';

function ThemeMode(): ReactElement | null {
  const { themeMode, setThemeMode } = useContext(ThemeContext);
  const isCondorEmployee = useIsCondorEmployee();

  return isCondorEmployee ? (
    <>
      <Divider orientation="vertical" sx={{ my: 1.5 }} flexItem />
      <FormControlLabel
        label="Dark mode (beta)"
        sx={{ ml: 0 }}
        control={
          <Switch
            checked={themeMode === 'dark'}
            onChange={(_, checked) => {
              const newThemeMode = checked ? 'dark' : 'light';
              setThemeMode(newThemeMode);
              localStorage.setItem('theme', newThemeMode);
            }}
          />
        }
      />
    </>
  ) : null;
}
export default ThemeMode;
