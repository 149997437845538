import { useMemo } from 'react';

import Box from '@mui/material/Box';

import LockGridReminder from 'shared/components/lock-grid/LockGridReminder';
import LockGridButton from 'shared/components/lock-grid/LockGridButton';

import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import { LockGrid } from 'shared/lib/types';

import AccrualsGrid from './grids/AccrualsGrid';

function AccrualsTabContent() {
  return (
    <FlexGridContainer>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="end"
        sx={{ mb: 1 }}
      >
        <LockGridButton grid={LockGrid.ACCRUALS_GRID} />
      </Box>
      <AccrualsGrid
        overlayNoRowsTemplate="No accruals."
        sx={useMemo(() => ({ height: '100%' }), [])}
      />
      <LockGridReminder grid={LockGrid.ACCRUALS_GRID} />
    </FlexGridContainer>
  );
}

export default AccrualsTabContent;
