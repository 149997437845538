import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import Checkbox from 'shared/ui/checkbox/Checkbox';

import { selectPeriod } from 'accruals/state/slices/periodSlice';
import type {
  ContractVersionResponse,
  RegionGroupResponse,
  RegionResponse,
  TraceId,
  VendorType,
} from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';
import WizardStep from 'shared/wizards/steps/WizardStep';

import { useGetAllContractsByTrialQuery } from 'shared/api/rtkq/periods';

type Props = {
  contractVersionTraceId: TraceId;
  regionMap: Record<string, RegionResponse>;
  selectedContractVersions: ContractVersionResponse[];
  setInheritedRegionGroups: (regionGroups: RegionGroupResponse[]) => void;
  setInheritedRegions: (regions: string[]) => void;
  setSelectedCurrentContracts: (
    contractContainers: ContractVersionResponse[],
  ) => void;
};

function ConfigurationStep(props: Props) {
  const {
    contractVersionTraceId,
    regionMap,
    selectedContractVersions,
    setSelectedCurrentContracts,
    setInheritedRegions,
    setInheritedRegionGroups,
  } = props;
  const trial = useSelector(selectTrial);
  const period = useSelector(selectPeriod);
  const { currentData: contractVersions = [] } = useGetAllContractsByTrialQuery(
    {
      trace_id: period.trace_id,
      otherParameter: trial.trace_id,
    },
  );
  const croCurrentContracts = contractVersions.filter(
    (contractVersion) =>
      contractVersion.status_for_period === 'CURRENT' &&
      contractVersion.vendor_type === 'CRO',
  );
  const occCurrentContracts = contractVersions.filter(
    (contractVersion) =>
      contractVersion.status_for_period === 'CURRENT' &&
      contractVersion.vendor_type === 'OCC' &&
      contractVersion.trace_id !== contractVersionTraceId,
  );
  const [croChecked, setCroChecked] = useState<boolean[]>(
    croCurrentContracts.map((currentContract) =>
      selectedContractVersions.includes(currentContract),
    ),
  );
  const [occChecked, setOccChecked] = useState<boolean[]>(
    occCurrentContracts.map((currentContract) =>
      selectedContractVersions.includes(currentContract),
    ),
  );

  useEffect(() => {
    const selectedContracts = [
      ...croCurrentContracts.filter((_, i) => croChecked[i]),
      ...occCurrentContracts.filter((_, i) => occChecked[i]),
    ];
    setSelectedCurrentContracts(selectedContracts);
    const regionIds = selectedContracts.flatMap(
      (contract) => contract.regions!,
    );
    setInheritedRegions([
      ...new Set(regionIds.map((regionId) => regionMap[regionId].name)),
    ]);
    setInheritedRegionGroups(
      uniqBy(
        regionIds.flatMap((regionId) => regionMap[regionId].regiongroups),
        (rg) => rg.trace_id,
      ),
    );
  }, [croChecked, occChecked]);

  function handleCheckboxChange(
    setChecked: Dispatch<SetStateAction<boolean[]>>,
    index: number,
    checked: boolean,
  ) {
    setChecked((prevChecked) => {
      const newChecked = [...prevChecked];
      newChecked[index] = checked;
      return newChecked;
    });
  }

  const sectionDefinitions: Array<
    [
      VendorType,
      ContractVersionResponse[],
      boolean[],
      Dispatch<SetStateAction<boolean[]>>,
    ]
  > = [
    ['CRO', croCurrentContracts, croChecked, setCroChecked],
    ['OCC', occCurrentContracts, occChecked, setOccChecked],
  ];

  return (
    <WizardStep
      description="You will be able to review and edit the regions, timeline, patients and sites in the next steps."
      header="Select which contracts' assumptions you want to import for this Other Clinical Contract, if any."
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        marginX="auto"
        mt={4}
        width="50%"
      >
        {sectionDefinitions.map((definition, _i) => {
          const [vendorType, currentContracts, checkedArray, setCheckedArray] =
            definition;
          return (
            <Box
              key={vendorType as string}
              border={1}
              borderColor="grey.500"
              borderRadius={2}
              display="flex"
              flex={1}
              flexDirection="column"
              mb={4}
              minWidth={400}
              pt={1}
              px={2}
            >
              <Typography variant="subtitle2">{vendorType}s</Typography>
              {currentContracts.length !== 0 ? (
                currentContracts.map((currentContract, i) => (
                  <Checkbox
                    key={currentContract.trace_id}
                    checked={checkedArray[i]}
                    label={
                      <>
                        <Typography color="text.primary">
                          {currentContract.vendor_name} #
                          {currentContract.po_number}
                        </Typography>
                        <Typography color="text.secondary" variant="caption">
                          {currentContract
                            .regions!.filter((region) => region in regionMap)
                            .map((region) => regionMap[region].name)
                            .join(', ')}
                        </Typography>
                      </>
                    }
                    onChange={(_, checked) =>
                      handleCheckboxChange(setCheckedArray, i, checked)
                    }
                  />
                ))
              ) : (
                <Typography variant="body1">
                  There are no {vendorType}s for this trial.
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
    </WizardStep>
  );
}

export default ConfigurationStep;
