import { useState } from 'react';

import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Upload from '@mui/icons-material/Upload';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { skipToken } from '@reduxjs/toolkit/query/react';

import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import CroContractBudgetGrid from 'accruals/pages/clinical-expenses/cro/grids/CroContractBudgetGrid';
import ContractBudgetUploadWizard from 'accruals/wizards/contract-budget-upload-wizard/ContractBudgetUploadWizard';
import FlexGridContainer from 'shared/containers/flex-grid-container/FlexGridContainer';
import PeriodAwareButton from 'shared/lib/periods/PeriodAwareButton';

import { useGetContractAssumptionGroupsByContractVersionQuery } from 'shared/api/rtkq/assumptiongroups';
import { useGetContractCostCategoryInfoByContractVersionQuery } from 'shared/api/rtkq/contractcostcategoryinfo';

import useContractAndPeriodWithVersions from '../shared/hooks/useContractAndPeriodWithVersions';

const GRID_SX = { height: '100%', width: '100%' };

function CroContractBudgetSection() {
  const { contractVersionTraceId, contractVersionPeriodMenuItemTraceId } =
    useContractAndPeriodWithVersions();
  const { currentData: contractCostCategoryInfos } =
    useGetContractCostCategoryInfoByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );
  const { currentData: assumptions } =
    useGetContractAssumptionGroupsByContractVersionQuery(
      contractVersionTraceId ?? skipToken,
    );

  const [contractBudgetWizardOpen, setContractBudgetWizardOpen] =
    useState(false);

  return (
    <DashboardCard
      title="Contract Budget"
      titleAction={
        <PeriodAwareButton
          disabled={!contractVersionTraceId || !assumptions?.length}
          permissions={['canEditTrialInfo']}
          startIcon={<Upload />}
          testId="upload_budget"
          variant="outlined"
          disableElevation
          onClick={() => setContractBudgetWizardOpen(true)}
        >
          Upload Budget
        </PeriodAwareButton>
      }
    >
      {contractBudgetWizardOpen ? (
        <ContractBudgetUploadWizard
          onClose={() => setContractBudgetWizardOpen(false)}
        />
      ) : null}
      <Stack
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="60vh"
        width="100%"
      >
        {contractVersionPeriodMenuItemTraceId ||
        contractCostCategoryInfos?.length ? (
          <FlexGridContainer>
            <CroContractBudgetGrid
              parentMenuItem={contractVersionPeriodMenuItemTraceId}
              sx={GRID_SX}
            />
          </FlexGridContainer>
        ) : (
          <>
            <HistoryOutlinedIcon sx={{ color: 'grey.700' }} />
            <Typography sx={{ mt: 2.5, mb: 5 }} variant="body1">
              Budget is not available for this contract.
            </Typography>
          </>
        )}
      </Stack>
    </DashboardCard>
  );
}

export default CroContractBudgetSection;
