import type { UnmappedFileResponse } from 'shared/lib/types';

import { COMPANY_FILES } from './companies';
import constructApi from './constructApi';
import { PERIOD_FILES } from './periods';
import { TRIAL_FILES } from './trials';

const PATH = 'unmappedfiles';

const apiEndpointFactory =
  constructApi<UnmappedFileResponse>(PATH).withTags('UNMAPPED_FILES');
const api = apiEndpointFactory.inject(() => ({
  deleteUnmappedFile: apiEndpointFactory.delete([
    PERIOD_FILES,
    TRIAL_FILES,
    COMPANY_FILES,
  ]),
  createUnmappedFile: apiEndpointFactory.create<FormData>(),
}));

export const { useDeleteUnmappedFileMutation, useCreateUnmappedFileMutation } =
  api;
