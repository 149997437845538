import type { ReactElement } from 'react';

import Box from '@mui/material/Box';

import LockGridButton from 'shared/components/lock-grid/LockGridButton';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import type { LockGrid } from 'shared/lib/types';

import AddSiteLabDropdown from '../buttons/AddSiteLabDropdown';
import ManageVisitsAndProceduresDropdown from '../buttons/ManageVisitsAndProceduresDropdown';

type Props = {
  lockGrid: LockGrid;
};

function SiteLabHeader(props: Props): ReactElement {
  const { lockGrid } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 1,
        mb: 2,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <ManageVisitsAndProceduresDropdown />
      <AddSiteLabDropdown />
      <LockGridButton grid={lockGrid} />
    </Box>
  );
}

export default withPeriodSpecificGenericWrapper(SiteLabHeader);
