import Edit from '@mui/icons-material/Edit';

import DashboardButton from 'shared/ui/dashboard-button/DashboardButton';
import DashboardCard from 'shared/ui/dashboard-card/DashboardCard';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';

import TrialRecordCardContents from './TrialRecordCardContents';

type Props = { onEditButtonClick?: () => void };

function TitleAction(props: Props) {
  const { onEditButtonClick } = props;

  return (
    <DashboardButton
      startIcon={<Edit />}
      testId="edit_trial"
      onClick={onEditButtonClick}
    >
      Edit
    </DashboardButton>
  );
}

const PeriodAwareTitleAction = withPeriodSpecificGenericWrapper(TitleAction);

export default function TrialRecordCard(props: Props) {
  const { onEditButtonClick } = props;

  return (
    <DashboardCard
      title="Trial Record"
      titleAction={
        <HasPermission permissions={['canEditTrialInfo']}>
          <PeriodAwareTitleAction onEditButtonClick={onEditButtonClick} />
        </HasPermission>
      }
    >
      <TrialRecordCardContents />
    </DashboardCard>
  );
}
