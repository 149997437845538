import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Modal from 'shared/components/modal/Modal';

import {
  makeForecastKey,
  useReinitializeForecast,
} from 'forecasting/state/slices/forecastSlice';
import * as routes from 'routes';
import type { ForecastResponse } from 'shared/lib/types';
import { selectTrial } from 'shared/state/slices/trialSlice';

import { useDeleteForecastMutation } from 'shared/api/rtkq/forecasts';

type Props = {
  forecast: ForecastResponse;
  handleCloseModal: () => void;
};

function DeleteForecastModal({ forecast, handleCloseModal }: Props) {
  const { name, trace_id } = forecast;
  const [deleteForecast, { isLoading: deleteForecastLoading }] =
    useDeleteForecastMutation();
  const reInitForecast = useReinitializeForecast();
  const trial = useSelector(selectTrial);
  const navigate = useNavigate();

  const handleDelete = () => {
    void (async () => {
      const trialForecastKey = makeForecastKey(trial.trace_id);
      if (forecast.trace_id === localStorage.getItem(trialForecastKey)) {
        localStorage.removeItem(trialForecastKey);
      }
      reInitForecast();
      await deleteForecast(trace_id);
      handleCloseModal();
      navigate(routes.getForecast());
    })();
  };

  return (
    <Modal
      handleClose={handleCloseModal}
      title={`Are you sure you want to delete "${name}"`}
      ButtonProps={{
        label: 'Delete',
        testId: 'ForecastModalDelete',
        loading: deleteForecastLoading,
        onClick: handleDelete,
      }}
      isOpen
    >
      <Typography>This action cannot be undone.</Typography>
    </Modal>
  );
}

export default DeleteForecastModal;
