import { useState } from 'react';

import Typography from '@mui/material/Typography';

import KabobMenu from 'shared/components/kabob-menu/KabobMenu';
import Modal from 'shared/components/modal/Modal';

import type { SiteGridRow } from 'shared/lib/types';

import { useDeleteSiteContractMutation } from 'shared/api/rtkq/sitecontracts';

type Props = {
  siteGridRow: SiteGridRow;
};

function SiteMenu(props: Props) {
  const { siteGridRow } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteSiteContract, { isLoading: isDeletingSiteContract }] =
    useDeleteSiteContractMutation();

  const handleSiteContractDelete = async () => {
    if (siteGridRow.site_contract_trace_id) {
      await deleteSiteContract(siteGridRow.site_contract_trace_id);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <KabobMenu
        size="small"
        options={[
          {
            label: 'Delete site',
            onClick: () => setIsModalOpen(true),
          },
        ]}
      />
      <Modal
        handleClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        title={`Are you sure you want to delete this version of Site #${siteGridRow.number}${siteGridRow.name ? ` (${siteGridRow.name})` : ''}?`}
        ButtonProps={{
          label: 'Delete Version',
          testId: 'DeleteSiteContractModal',
          onClick: () => void handleSiteContractDelete(),
          sx: { backgroundColor: 'error.main' },
          loading: isDeletingSiteContract,
        }}
      >
        <Typography>
          If the site version was being used in any trial expenses, accrual and
          forecasting calculations may be affected. This action cannot be
          undone.
        </Typography>
      </Modal>
    </>
  );
}

export default SiteMenu;
