import { type ReactElement, useState } from 'react';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Box from '@mui/material/Box';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';
import InvoiceUploadWizard from 'shared/wizards/invoice-upload-wizard/InvoiceUploadWizard';

function InvoiceListingHeader(): ReactElement {
  const [showDialog, setShowDialog] = useState(false);

  function onUploadInvoice() {
    setShowDialog(false);
  }

  return (
    <HasPermission permissions={['canEditCompanyLevelInfo']}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 1,
          mb: 2,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <Button
          startIcon={<FileUploadOutlinedIcon />}
          sx={{ alignSelf: 'flex-end' }}
          testId="upload_invoice_listing"
          variant="contained"
          onClick={() => setShowDialog(true)}
        >
          Upload invoice listing
        </Button>
        {showDialog && <InvoiceUploadWizard onClose={onUploadInvoice} />}
      </Box>
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(InvoiceListingHeader);
