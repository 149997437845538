import { useCallback } from 'react';

import type { CellValueChangedEvent } from '@ag-grid-community/core';

import type { UserManagementGridPopupData } from 'shared/lib/types';

import { useUpdateUserAccessMutation } from 'shared/api/rtkq/users';

type UserPermissionColumns = string | undefined;

export default function useEditCondorUserStatus(
  setShowPopup: (showPopup: UserManagementGridPopupData) => void,
): [(params: CellValueChangedEvent) => void] {
  const [updateUserAccess] = useUpdateUserAccessMutation();

  const onCellValueChanged = useCallback(
    (event: CellValueChangedEvent) => {
      const { data } = event;
      const columnBeingUpdated: UserPermissionColumns = event.colDef.field;
      void (async () => {
        if (columnBeingUpdated === undefined) {
          return;
        }

        if (columnBeingUpdated === 'status' && data.status === 'Inactive') {
          setShowPopup({
            trace_id: data.trace_id,
            buttonLabel: 'Deactivate',
            message:
              'The user will be removed from all trials. If the user is a preparer or reviewer on a trial, you will have to reassign the role on that trial.',
            title: `Are you sure you want to deactivate the user ${String(data.email)}?`,
            dataToUpdate: { status: 'Inactive' },
            state: true,
          });
          return;
        }

        if (
          columnBeingUpdated === 'can_edit_company_users_and_permissions' &&
          data[columnBeingUpdated] === true
        ) {
          setShowPopup({
            trace_id: data.trace_id,
            buttonLabel: 'Confirm',
            message:
              'Users that are given the permission to manage other users & permissions will be granted access to all current and future trials.',
            title:
              'User will be given access to all current and future trials.',
            dataToUpdate: {
              can_edit_company_users_and_permissions: data[columnBeingUpdated],
              trials: [],
            },
            state: true,
          });
          return;
        }

        await updateUserAccess({
          trace_id: data.trace_id,
          [columnBeingUpdated]: data[columnBeingUpdated],
        });
      })();
    },
    [updateUserAccess, setShowPopup],
  );

  return [onCellValueChanged];
}
