import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';

import SidebarItem from './SidebarItem';
import type { SidebarSectionItem } from './SidebarSection';
import SidebarSubNavigationItem from './SidebarSubNavigationItem';

type Props = SidebarSectionItem & {
  expanded?: boolean;
  onClose: (ele: HTMLDivElement | null) => void;
  anchorEl: Element | null;
};

function SidebarSubNavigation(props: Props) {
  const {
    anchorEl,
    title,
    icon,
    subNav,
    collapsed,
    expanded = false,
    onClose,
  } = props;

  if (!subNav) {
    return null;
  }

  const handleSubNavClick = (onClick?: () => void) => {
    onClick?.();
    onClose(null);
  };

  return (
    <>
      {!collapsed && (
        <Collapse in={expanded}>
          <List disablePadding>
            {subNav.map((subNavItem) => (
              <SidebarItem
                key={subNavItem.link ?? subNavItem.title}
                collapsed={!!collapsed}
                variant="caption"
                {...subNavItem}
              />
            ))}
          </List>
        </Collapse>
      )}
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={!!collapsed && anchorEl !== null}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => onClose(null)}
      >
        <List sx={{ py: 1, width: 300 }} disablePadding>
          <SidebarSubNavigationItem icon={icon} title={title} />
          {subNav.map(({ onClick, ...subNavItem }) => (
            <SidebarSubNavigationItem
              key={subNavItem.link ?? subNavItem.title}
              variant="caption"
              onClick={() => handleSubNavClick(onClick)}
              {...subNavItem}
            />
          ))}
        </List>
      </Popover>
    </>
  );
}

export default SidebarSubNavigation;
