import { createSlice } from '@reduxjs/toolkit';

import type { TraceId, UserPermissions } from 'shared/lib/types';
import type { RootState } from 'shared/state/store';

import { api as userApi } from 'shared/api/rtkq/users';

type State = {
  traceId: TraceId;
  permissions: UserPermissions;
  is_superuser: boolean;
};

const initialState: State = {
  traceId: '',
  permissions: {
    // User Permissions
    // NOTE: users/me API uses the convention can_<action> to determine permissions
    // so please make sure any new permission always starts with `can_`
    // Please update the file models/users.py for any changes below
    // 1. Company Permissions
    canEditCompanyUsersAndPermissions: false,
    canEditCompanySettings: false,
    canEditCompanyLevelInfo: false,
    canDeleteCompanyLevelInfo: false,
    canSignoffAsPreparer: false,
    canSignoffAsReviewer: false,

    // # 2. Trial Permissions
    canManageTrialRoles: false,

    canEditTrialInfo: false,
    canDeleteTrialInfo: false,

    // # 2.a. Period level
    canOpenPeriod: false,
    canClosePeriod: false,
    canReopenPeriod: false,

    // # 2.b. Comments, forecasting, and historical values
    canAddComments: false,
    canEditHistoricValues: false,
    canAccessForecasting: false,
    canCreateAndEditForecasting: false,

    // # 3. Other miscellaneous permissions
    canLockGrids: false,
    canEditPeriodClosedValues: false,

    // Permissions to be deprecated
    canEditData: false,
  },
  is_superuser: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getCurrentUser.matchFulfilled,
      (state, { payload }) => {
        const {
          can_edit_company_users_and_permissions,
          can_edit_company_settings,
          can_edit_company_level_info,
          can_delete_company_level_info,
          can_signoff_as_preparer,
          can_signoff_as_reviewer,
          can_manage_trial_roles,
          can_edit_trial_info,
          can_delete_trial_info,
          can_open_period,
          can_close_period,
          can_reopen_period,
          can_add_comments,
          can_edit_historic_values,
          can_access_forecasting,
          can_create_and_edit_forecasting,
          can_edit_data,
          can_edit_period_closed_values,
          can_lock_grids,
        } = payload.permissions ?? {};

        state.traceId = payload.trace_id;
        state.is_superuser = payload.is_superuser;
        state.permissions = {
          canEditCompanyUsersAndPermissions:
            can_edit_company_users_and_permissions === true,
          canEditCompanySettings: can_edit_company_settings === true,
          canEditCompanyLevelInfo: can_edit_company_level_info === true,
          canDeleteCompanyLevelInfo: can_delete_company_level_info === true,
          canSignoffAsPreparer: can_signoff_as_preparer === true,
          canSignoffAsReviewer: can_signoff_as_reviewer === true,

          canManageTrialRoles: can_manage_trial_roles === true,
          canEditTrialInfo: can_edit_trial_info === true,
          canDeleteTrialInfo: can_delete_trial_info === true,

          canOpenPeriod: can_open_period === true,
          canClosePeriod: can_close_period === true,
          canReopenPeriod: can_reopen_period === true,

          canAddComments: can_add_comments === true,
          canEditHistoricValues: can_edit_historic_values === true,
          canAccessForecasting: can_access_forecasting === true,
          canCreateAndEditForecasting: can_create_and_edit_forecasting === true,

          canEditData: can_edit_data === true,
          canEditPeriodClosedValues: can_edit_period_closed_values === true,

          canLockGrids: can_lock_grids === true,
        };
      },
    );
  },
});

export const selectCurrentUserPermissions = (state: RootState) =>
  state.user.permissions;

export const selectUser = (state: RootState) => state.user.traceId;
/* @deprecated use selectCurrentUserPermissions instead */
export const selectUserCanEdit = (state: RootState) =>
  state.user.permissions.canEditData;

export function userHasAnyPermission(state: RootState) {
  const {
    canEditData,
    canClosePeriod,
    canSignoffAsReviewer,
    canSignoffAsPreparer,
    canEditHistoricValues,
    canEditPeriodClosedValues,
  } = state.user.permissions;
  return (
    (canEditData ?? false) ||
    canClosePeriod ||
    canSignoffAsReviewer ||
    canSignoffAsPreparer ||
    canEditHistoricValues ||
    canEditPeriodClosedValues
  );
}

export const userReducer = userSlice.reducer;
