import type { SetStateAction } from 'react';
import { useState } from 'react';

import Edit from '@mui/icons-material/Edit';
import KeyboardArrowDownOutlined from '@mui/icons-material/KeyboardArrowDownOutlined';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Button from 'shared/ui/button/Button';

import ProceduresWizard from 'accruals/wizards/procedures-wizard/ProceduresWizard';
import VisitSchedulesWizard from 'accruals/wizards/visit-schedules/VisitSchedulesWizard';
import HasPermission from 'shared/lib/permissions/HasPermission';

function ManageVisitsAndProceduresDropdown() {
  const [showVisitSchedulesWizard, setShowVisitSchedulesWizard] =
    useState<boolean>(false);
  const [showProceduresWizard, setShowProceduresWizard] =
    useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  function DropdownMenuItem(props: {
    label: string;
    setShowWizard: (value: SetStateAction<boolean>) => void;
  }) {
    const { label, setShowWizard } = props;
    return (
      <MenuItem
        disableGutters
        disableRipple
        onClick={() => {
          setShowWizard(true);
          setAnchorEl(null);
        }}
      >
        {label}
      </MenuItem>
    );
  }

  return (
    <HasPermission permissions={['canEditTrialInfo']}>
      <Button
        endIcon={<KeyboardArrowDownOutlined />}
        startIcon={<Edit />}
        testId="manage_visits_and_procedures"
        variant="outlined"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        Manage Visits and Procedures
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ mx: 2, my: 1 }}>
          <DropdownMenuItem
            label="Visit Schedules"
            setShowWizard={setShowVisitSchedulesWizard}
          />
          <DropdownMenuItem
            label="Manage Procedure Categories"
            setShowWizard={setShowProceduresWizard}
          />
        </Box>
      </Menu>
      {showVisitSchedulesWizard && (
        <VisitSchedulesWizard
          onClose={() => setShowVisitSchedulesWizard(false)}
        />
      )}
      {showProceduresWizard && (
        <ProceduresWizard onClose={() => setShowProceduresWizard(false)} />
      )}
    </HasPermission>
  );
}

export default ManageVisitsAndProceduresDropdown;
