import { useCallback } from 'react';

import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import Tab, { tabClasses } from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
import { useNavigate } from 'react-router-dom';

import Tabs from 'shared/ui/tabs/Tabs';

import * as routes from 'routes';
import useFeatureFlag from 'shared/helpers/useFeatureFlag';

import useHasPermission from '../permissions/useHasPermission';

type SIDEBAR_TAB = 'accruals' | 'forecasting';
type Props = {
  activeTab: SIDEBAR_TAB;
  collapsed?: boolean;
};

const TAB_SX: SxProps<Theme> = {
  textTransform: 'none',
  [`:not(.${tabClasses.selected})`]: {
    bgcolor: 'grey.50',
    border: ({ palette }) => `solid 1px ${palette.divider}`,
    color: ({ palette }) => alpha(palette.common.black, 0.56),
    borderTop: 'none',
    '&:first-of-type': {
      borderLeft: 'none',
    },
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
};

function SidebarTabs(props: Props) {
  const { activeTab, collapsed = false } = props;
  const userCanAccessForecasting = useHasPermission(
    ['canAccessForecasting', 'canCreateAndEditForecasting'],
    'OR',
  );
  const forecastingEnabled = useFeatureFlag('forecasting');
  const isForecastingEnabled = forecastingEnabled && userCanAccessForecasting;

  const navigate = useNavigate();
  const onTabClick = useCallback(
    (tab: SIDEBAR_TAB) => {
      switch (tab) {
        case 'accruals':
          navigate(routes.getHomePage());
          return;
        case 'forecasting':
          navigate(routes.getForecast());
      }
    },
    [navigate],
  );

  if (!isForecastingEnabled) {
    return null;
  }

  return (
    <Tabs
      scrollButtons={false}
      value={activeTab}
      variant="fullWidth"
      TabIndicatorProps={{
        sx: {
          top: 0,
        },
      }}
      sx={{
        ...(collapsed && {
          [`& .${tabClasses.root}`]: {
            padding: 0,
          },
        }),
      }}
      onChange={(_, tabValue) => onTabClick(tabValue)}
    >
      <Tab
        sx={TAB_SX}
        value="accruals"
        icon={
          collapsed ? <CalculateOutlinedIcon fontSize="small" /> : undefined
        }
        label={
          collapsed ? undefined : (
            <Typography variant="subtitle2">Accruals</Typography>
          )
        }
      />
      <Tab
        sx={TAB_SX}
        value="forecasting"
        icon={
          collapsed ? <SignalCellularAltIcon fontSize="small" /> : undefined
        }
        label={
          collapsed ? undefined : (
            <Typography variant="subtitle2">Forecasting</Typography>
          )
        }
      />
    </Tabs>
  );
}

export default SidebarTabs;
