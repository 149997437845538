import { useState } from 'react';

import EditIcon from '@mui/icons-material/EditOutlined';

import Button from 'shared/ui/button/Button';

import withPeriodSpecificGenericWrapper from 'shared/lib/periods/withPeriodSpecificGenericWrapper';
import HasPermission from 'shared/lib/permissions/HasPermission';

import ContractContainerWizard from '../ContractContainerWizard';
import useContractAndPeriodWithVersions from '../hooks/useContractAndPeriodWithVersions';

function EditContractContainer() {
  const [editContractContainerOpen, setEditContractContainerOpen] =
    useState(false);
  const { contractContainer } = useContractAndPeriodWithVersions();

  return (
    <HasPermission permissions={['canEditTrialInfo']}>
      {editContractContainerOpen && contractContainer?.vendor_type && (
        <ContractContainerWizard
          contractContainer={contractContainer}
          vendorType={contractContainer.vendor_type}
          onClose={() => setEditContractContainerOpen(false)}
        />
      )}
      <Button
        disabled={!contractContainer}
        size="small"
        startIcon={<EditIcon />}
        testId="edit_contract_container"
        variant="outlined"
        onClick={() => setEditContractContainerOpen(true)}
      >
        Edit
      </Button>
    </HasPermission>
  );
}

export default withPeriodSpecificGenericWrapper(EditContractContainer);
