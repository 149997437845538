import type { SxProps } from '@mui/material/styles';

import CondorAgGrid from 'shared/components/ag-grid/CondorAgGrid';
import useGridColDefs from 'shared/components/ag-grid/hooks/useGridColDefs';
import useGridOptions from 'shared/components/ag-grid/hooks/useGridOptions';
import useLockedGridStatus from 'shared/components/lock-grid/useLockedGridStatus';

import useAccrualsGridOptions from 'accruals/pages/analytics/hooks/useAccrualsGridOptions';
import useAccrualsRows from 'accruals/pages/analytics/hooks/useAccrualsRows';
import withPeriodSpecificGridWrapper from 'shared/lib/periods/withPeriodSpecificGridWrapper';
import { LockGrid, PeriodGridBlobType } from 'shared/lib/types';

import useAccrualsColumnDefs from '../hooks/useAccrualsColumnDefs';

type Props = { overlayNoRowsTemplate?: string; sx?: SxProps };

function AccrualsGrid(props: Props) {
  const { sx, overlayNoRowsTemplate } = props;
  const isGridLocked = useLockedGridStatus(LockGrid.ACCRUALS_GRID);
  const columnDefs = useGridColDefs(useAccrualsColumnDefs, [], isGridLocked);
  const gridOptions = useGridOptions(useAccrualsGridOptions);
  const rowData = useAccrualsRows();

  return (
    <CondorAgGrid
      columnDefs={columnDefs}
      gridOptions={gridOptions}
      overlayNoRowsTemplate={overlayNoRowsTemplate}
      rowData={rowData}
      sx={sx}
    />
  );
}

export default withPeriodSpecificGridWrapper(
  AccrualsGrid,
  PeriodGridBlobType.ACCRUALS,
);
