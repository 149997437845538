import type { ReactNode } from 'react';

import Box from '@mui/material/Box';

type Props = { children: ReactNode };

function TabularDataRow(props: Props) {
  const { children } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        flexFlow: 'row',
        height: 48,
        justifyContent: 'space-between',
        borderRadius: 1,
        px: 1.5,
        '&:nth-of-type(even)': {
          backgroundColor: ({ palette }) => palette.background.default,
        },
      }}
    >
      {children}
    </Box>
  );
}

export default TabularDataRow;
