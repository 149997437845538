import type { HTMLAttributes, SyntheticEvent } from 'react';
import { memo, useEffect, useState } from 'react';

import Cancel from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent, {
  dialogContentClasses,
} from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Autocomplete from 'shared/ui/autocomplete/Autocomplete';
import IconButton from 'shared/ui/icon-button/IconButton';
import LoadingButton from 'shared/ui/loading-button/LoadingButton';
import UserCard from 'shared/ui/user-card/UserCard';

import { getUsersAndPermissions } from 'routes';
import useHasPermission from 'shared/lib/permissions/useHasPermission';
import useInputErrors from 'shared/lib/sidebar/hooks/useInputErrors';
import type { DropdownOption, UserOption } from 'shared/lib/types';
import { selectCompany } from 'shared/state/slices/companySlice';
import { selectTrial } from 'shared/state/slices/trialSlice';

import useIsClosedPeriodVersion from 'shared/api/hooks/useIsClosedPeriodVersion';
import { useGetUsersByCompanyQuery } from 'shared/api/rtkq/companies';
import {
  useLazyGetTrialQuery,
  useUpdatePreparerAndReviewerMutation,
} from 'shared/api/rtkq/trials';

import TrialTeamMappingOption from './TrialTeamMappingOption';

type Props = {
  setShowTrialTeam: (showTrialTeam: boolean) => void;
  showTrialTeam: boolean;
};

function TrialTeamModal(props: Props) {
  const { showTrialTeam, setShowTrialTeam } = props;

  const currentCompany = useSelector(selectCompany);
  const currentTrial = useSelector(selectTrial);

  const [getCurrentTrial] = useLazyGetTrialQuery();
  const { currentData: users } = useGetUsersByCompanyQuery(
    currentCompany.trace_id,
  );
  const [updatePreparerAndReviewer] = useUpdatePreparerAndReviewerMutation();

  const isPeriodClosed = useIsClosedPeriodVersion();
  const [preparer, setPreparer] = useState<UserOption | null>(null);
  const [reviewer, setReviewer] = useState<UserOption | null>(null);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const { inputErrors, markInputError } = useInputErrors();
  const canManageTrialRoles = useHasPermission(['canManageTrialRoles']);

  // Because we want to show only the users
  // who accepted the invite and are not blocked
  const preparers = users?.filter(
    (user) =>
      !user.is_superuser &&
      user.invite_accepted &&
      !user.is_blocked &&
      user.can_signoff_as_preparer,
  );
  const reviewers = users?.filter(
    (user) =>
      !user.is_superuser &&
      user.invite_accepted &&
      !user.is_blocked &&
      user.can_signoff_as_reviewer,
  );

  useEffect(() => {
    void (async () => {
      const trial = await getCurrentTrial(currentTrial.trace_id).unwrap();
      setPreparer({
        value: trial.preparer?.trace_id ?? undefined,
        label: trial.preparer?.name ?? '',
      });
      setReviewer({
        value: trial.reviewer?.trace_id ?? undefined,
        label: trial.reviewer?.name ?? '',
      });
    })();
  }, [currentTrial, getCurrentTrial]);

  const handleUpdateTrialTeam = () => {
    setShowLoadingButton(true);
    if (preparer?.value === reviewer?.value && preparer?.value !== undefined) {
      markInputError(
        'preparer',
        'Preparer and reviewer cannot be the same person.',
      );
      markInputError(
        'reviewer',
        'Preparer and reviewer cannot be the same person.',
      );
      setShowLoadingButton(false);
      return;
    }
    void (async () => {
      await updatePreparerAndReviewer({
        trace_id: currentTrial.trace_id,
        preparer: preparer?.value,
        reviewer: reviewer?.value,
      });
      setShowTrialTeam(false);
    })();
  };

  const renderOption = (
    renderProps: HTMLAttributes<HTMLLIElement>,
    option: DropdownOption<string | undefined>,
  ) => (
    <TrialTeamMappingOption
      key={option.value}
      option={option}
      renderProps={renderProps}
    />
  );

  const handleSelectChange = (
    _: SyntheticEvent,
    value: UserOption | null,
    role: 'preparer' | 'reviewer',
  ) => {
    if (role === 'preparer') {
      setPreparer(value);
    } else {
      setReviewer(value);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      open={showTrialTeam}
      fullWidth
      onClose={() => setShowTrialTeam(false)}
    >
      <DialogTitle
        sx={{
          mb: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography color="text.primary" component="div" variant="subtitle1">
          Trial Team
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            component={Link}
            to={getUsersAndPermissions()}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              textDecoration: 'none',
            }}
          >
            <Typography color="textPrimary" variant="overline">
              Manage User Permissions
            </Typography>
            <IconButton size="small" sx={{ mr: 1 }}>
              <LaunchIcon />
            </IconButton>
          </Box>
          <IconButton size="small" onClick={() => setShowTrialTeam(false)}>
            <Cancel />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{ p: 4, [`&.${dialogContentClasses.root}`]: { pt: 2 } }}
      >
        <Typography variant="body1">
          Assigned users will be allowed to sign off their respective tasks on
          the financial close checklist.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography component="div" sx={{ mr: 0.5 }} variant="body2">
                Preparer
              </Typography>
              <Tooltip
                placement="right"
                title="For this trial, user can edit trial data, open periods, and comment, but cannot close periods."
                arrow
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Box>
            <Autocomplete
              disabled={isPeriodClosed || !canManageTrialRoles}
              errorMsg={inputErrors.preparer}
              getOptionLabel={(option) => option.label}
              label="Preparer"
              renderOption={renderOption}
              size="medium"
              sx={{ width: '300px' }}
              value={preparer ?? null}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              options={
                preparers?.map((user) => ({
                  value: user.trace_id,
                  label: user.name,
                  ...user,
                })) ?? []
              }
              onChange={(_, value) => handleSelectChange(_, value, 'preparer')}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography component="div" sx={{ mr: 0.5 }} variant="body2">
                Reviewer
              </Typography>
              <Tooltip
                placement="right"
                title="For this trial, user can close periods and comment, but cannot open periods, or edit or delete trial data."
                arrow
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Box>
            <Autocomplete
              disabled={isPeriodClosed || !canManageTrialRoles}
              errorMsg={inputErrors.reviewer}
              getOptionLabel={(option) => option.label}
              label="Reviewer"
              renderOption={renderOption}
              size="medium"
              sx={{ width: '300px' }}
              value={reviewer ?? null}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              options={
                reviewers?.map((user) => ({
                  value: user.trace_id,
                  label: user.name,
                  ...user,
                })) ?? []
              }
              onChange={(_, value) => handleSelectChange(_, value, 'reviewer')}
            />
          </Box>
        </Box>
        {!canManageTrialRoles && !isPeriodClosed && (
          <Typography
            justifyItems="center"
            sx={{
              background: (theme) => theme.palette.grey[200],
              display: 'flex',
              border: '1px',
              borderRadius: '10px',
              flexDirection: 'row',
              height: '60px',
              justifyContent: 'space-between',
              alignItems: 'center',
              pl: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
                gap: '22px',
              }}
            >
              <Box>
                <InfoOutlinedIcon />
              </Box>
              <Box
                sx={{
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  You don&apos;t have permissions to change trial assignments.
                </Typography>
                <Typography>
                  Contact a company admin to make changes.
                </Typography>
              </Box>
            </Box>
          </Typography>
        )}
        <Divider />
        <Typography sx={{ mt: 2 }} variant="h6">
          User Access
        </Typography>
        <Box
          sx={{
            bgcolor: 'background.paper',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'auto',
            height: 300,
          }}
        >
          <UserCard
            allUsers={
              users?.filter(
                (user) =>
                  !user.is_superuser &&
                  ((user.trial_access.length === 1 &&
                    user.trial_access[0] === 'All Trials') ||
                    user.trial_access.includes(currentTrial.trace_id)),
              ) ?? []
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {!isPeriodClosed && canManageTrialRoles && (
          <LoadingButton
            loading={showLoadingButton}
            testId="trialTeamModalUpdate"
            variant="contained"
            onClick={handleUpdateTrialTeam}
          >
            Update
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default memo(TrialTeamModal);
