import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';

import type { UserRow } from 'shared/lib/types';

type Props = {
  allUsers: UserRow[];
  sx?: SxProps<Theme>;
};

function UserCard({ allUsers, sx }: Props) {
  return (
    <List component="div" disablePadding>
      {allUsers.map((user) => (
        <ListItem
          key={user.email}
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
            px: 0,
            ...sx,
          }}
        >
          <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
            <ListItemAvatar>
              <Avatar alt={user.name} src={String(user.picture)} />
            </ListItemAvatar>
            <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle2">{user.name}</Typography>
              <Typography variant="body1">
                {user.company_name}
                {user.title ? ` - ${user.title}` : undefined}
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="caption">{user.email}</Typography>
        </ListItem>
      ))}
    </List>
  );
}

export default UserCard;
